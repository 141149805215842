import { Col, Row } from "react-bootstrap";
import "./DetailsTable.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { GenericTable } from "./GenericTable";
//month names import
import { monthsMap } from "./KPIcards/MonthNames";
//user data
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { NoData } from "../../Details/GenericComponents/NoData";

//Table data interface
interface Props {
  cardId: string;
}

//Data table keys
//voluntary table keys
const monthlyVoluntaryTableColumns = [
  "year",
  "month",
  "avgActiveCount",
  "leftCount",
  "voluntaryCount",
  "involuntaryCount",
  "volTurnoverRate",
];
const quarterlyVoluntaryTableColumns = [
  "year",
  "quarter",
  "avgActiveCount",
  "leftCount",
  "voluntaryCount",
  "involuntaryCount",
  "volTurnoverRate",
];
const yearlyVoluntaryTableColumns = [
  "year",
  "avgActiveCount",
  "leftCount",
  "voluntaryCount",
  "involuntaryCount",
  "volTurnoverRate",
];
//gross profit table keys
const monthlyGrossProfitTableColumns = [
  "year",
  "month",
  "revenue",
  "gpAmount",
  "gpPer",
];
const quarterlyGrossProfitTableColumns = [
  "year",
  "revenue",
  "gpAmount",
  "gpPer",
];
const yearlyGrossProfitTableColumns = ["year", "revenue", "gpAmount", "gpPer"];
//net expense table keys
const monthlyNetExpenseTableColumns = [
  "year",
  "month",
  "gpAmount",
  "neAmount",
  "nePer",
];
const quarterlyNetExpenseTableColumns = [
  "year",
  "gpAmount",
  "neAmount",
  "nePer",
];
const yearlyNetExpenseTableColumns = ["year", "gpAmount", "neAmount", "nePer"];
//net Income table keys
const monthlyNetIncomeTableColumns = [
  "year",
  "month",
  "revenue",
  "niAmount",
  "niPer",
];
const quarterlyNetIncomeTableColumns = ["year", "revenue", "niAmount", "niPer"];
const yearlyNetIncomeTableColumns = ["year", "revenue", "niAmount", "niPer"];
//TRIr table keys
const monthlyTRIRTableColumns = [
  "year",
  "month",
  "recordableCount",
  "totalHour",
  "trirRate",
];
const quarterlyTRIRTableColumns = [
  "year",
  "quarter",
  "recordableCount",
  "totalHour",
  "trirRate",
];
const yearlyTRIRTableColumns = [
  "year",
  "recordableCount",
  "totalHour",
  "trirRate",
];
//Aggregate table keys
const aggregateTableColumns = [
  "year",
  "month",
  "qaGroup",
  "scoreCategory",
  "surveyOverallScore",
];
//Attrition table keys
const monthlyAttritionTableColumns = [
  "year",
  "month",
  "attritionRate",
  "retiredCount",
  "voluntaryCount",
  "terminatedCount",
  "involuntaryCount",
  "activeHeadCount",
  "avrActiveHeadCount",
];
const quarterlyAttritionTableColumns = [
  "year",
  "quarter",
  "attritionRate",
  "retiredCount",
  "voluntaryCount",
  "terminatedCount",
  "involuntaryCount",
  "activeHeadCount",
  "avrActiveHeadCount",
];
const yearlyAttritionTableColumns = [
  "year",
  "attritionRate",
  "retiredCount",
  "voluntaryCount",
  "terminatedCount",
  "involuntaryCount",
  "activeHeadCount",
  "avrActiveHeadCount",
];
const totalBookedBacklogTableColumns = [
  "year",
  "month",
  "revenue",
  "gpAmount",
  "gpPer",
];
//table headings from keys
const voluntaryColumnKeyToHeading: Record<string, string> = {
  year: "Year",
  month: "Month",
  quarter: "Quarterly",
  avgActiveCount: "Average Active Count",
  leftCount: "Left Count",
  voluntaryCount: "Voluntary Count",
  involuntaryCount: "Involuntary Count",
  volTurnoverRate: "Voluntary Turnover Rate",
};
const grossProfitColumnKeyToHeading: Record<string, string> = {
  year: "Year",
  month: "Month",
  quarter: "Quarterly",
  revenue: "Revenue",
  gpAmount: "Gross Profit",
  neAmount: "Net Expense",
  nePer: "Net Expense(%)",
  gpPer: "Gross Profit(%)",
};

const netExpenseColumnKeyToHeading: Record<string, string> = {
  year: "Year",
  month: "Month",
  quarter: "Quarterly",
  neAmount: "Net Expense",
  gpAmount: "Gross Profit",
  nePer: "Net Expense(%)",
};
const netIncomeColumnKeyToHeading: Record<string, string> = {
  year: "Year",
  month: "Month",
  quarter: "Quarterly",
  revenue: "Revenue",
  niAmount: "Net Income",
  niPer: "Net Income(%)",
};
const trirColumnKeyToHeading: Record<string, string> = {
  year: "Year",
  quarter: "Quarterly",
  month: "Month",
  recordableCount: "Recordable Count",
  totalHour: "Total Hours",
  trirRate: "TRIR Rate",
};
const aggregateColumnsKeyToHeading: Record<string, string> = {
  year: "Year",
  month: "Month",
  qaGroup: "QA Group",
  scoreCategory: "Score Category",
  surveyOverallScore: "Survey Overall Score",
};
const attritionColumnKeyToHeading: Record<string, string> = {
  month: "Month",
  year: "Year",
  quarter: "Quarterly",
  attritionRate: "Attrition Rate",
  retiredCount: "Retired Count",
  voluntaryCount: "Voluntary Count",
  terminatedCount: "Terminated Count",
  involuntaryCount: "Involuntary Count",
  activeHeadCount: "Active Head Count",
  avrActiveHeadCount: "Average Active Head Count",
};

export const DetailsTable: React.FC<Props> = ({ cardId }) => {
  //imports from reduxjs
  const token: any = useSelector((state: RootState) => state.auth.token);
  const bussUnit = useSelector((state: RootState) => state.user.bussUnit);
  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const selectedMonthYear = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );
  const dateType = useSelector(
    (state: RootState) => state.user.selectedDateType
  );
  const buType = useSelector((state: RootState) => state.user.buType);

  //store API call response data
  const [volTableData, setVolTableData] = useState<any>([]);
  const [grossProfitTableData, setGrossProfitTableData] = useState<any>(null);
  const [netExpenseTableData, setNetExpenseTableData] = useState<any>(null);
  const [netIncomeTableData, setNetIncomeTableData] = useState<any>(null);
  const [trirTableData, setTRIRTableData] = useState<any>(null);
  const [bookedBacklogTableData, setBookedBacklogTableData] =
    useState<any>(null);

  //store filters based on monthly, quarterly and yearly selection
  const [selectedPillItem, setSelectedPillItem] = useState("Y");
  //store column headings based on selection
  const [voluntaryTableColumns, setVoluntaryTableColumns] = useState<any>(
    monthlyVoluntaryTableColumns
  );
  const [grossProfitTableColumns, setGrossProfitTableColumns] = useState<any>(
    monthlyGrossProfitTableColumns
  );
  const [netExpenseTableColumns, setNetExpenseTableColumns] = useState<any>(
    monthlyNetExpenseTableColumns
  );
  const [netIncomeTableColumns, setNetIncomeTableColumns] = useState<any>(
    monthlyNetIncomeTableColumns
  );

  const [trirTableColumns, setTRIRTableColumns] = useState<any>(
    yearlyTRIRTableColumns
  );
  const [attritionTableColumns, setAttritionTableColumns] = useState<any>(
    monthlyAttritionTableColumns
  );
  //make API call on date type pill change
  const handleMenuItemClick = (date: string) => {
    sendVolRequest(date);
    sendgrossProfitRequest(date);
    fetchTRIR(date);
    setSelectedPillItem(date);
    switch (date) {
      case "Y":
        setVoluntaryTableColumns(yearlyVoluntaryTableColumns);
        setGrossProfitTableColumns(yearlyGrossProfitTableColumns);
        setNetExpenseTableColumns(yearlyNetExpenseTableColumns);
        setNetIncomeTableColumns(yearlyNetIncomeTableColumns);
        setTRIRTableColumns(yearlyTRIRTableColumns);
        setAttritionTableColumns(yearlyAttritionTableColumns);

        break;
      case "M":
        setVoluntaryTableColumns(monthlyVoluntaryTableColumns);
        setGrossProfitTableColumns(monthlyGrossProfitTableColumns);
        setNetExpenseTableColumns(monthlyNetExpenseTableColumns);
        setNetIncomeTableColumns(monthlyNetIncomeTableColumns);
        setTRIRTableColumns(monthlyTRIRTableColumns);
        setAttritionTableColumns(monthlyAttritionTableColumns);

        break;
      case "Q":
        setVoluntaryTableColumns(quarterlyVoluntaryTableColumns);
        setGrossProfitTableColumns(yearlyGrossProfitTableColumns);
        setNetExpenseTableColumns(yearlyNetExpenseTableColumns);
        setNetIncomeTableColumns(yearlyNetIncomeTableColumns);
        setTRIRTableColumns(quarterlyTRIRTableColumns);
        setAttritionTableColumns(quarterlyAttritionTableColumns);

        break;

      default:
        setVoluntaryTableColumns(yearlyVoluntaryTableColumns);
        setGrossProfitTableColumns(yearlyGrossProfitTableColumns);
        setNetExpenseTableColumns(yearlyNetExpenseTableColumns);
        setNetIncomeTableColumns(yearlyNetIncomeTableColumns);
        setTRIRTableColumns(yearlyTRIRTableColumns);
        setAttritionTableColumns(yearlyAttritionTableColumns);
    }
  };
  //set table columns and API calls
  useEffect(() => {
    const dashboardDateType = dateType ? dateType : "Y";
    switch (dateType) {
      case "M":
        setVoluntaryTableColumns(monthlyVoluntaryTableColumns);
        setGrossProfitTableColumns(monthlyGrossProfitTableColumns);
        setNetExpenseTableColumns(monthlyNetExpenseTableColumns);
        setNetIncomeTableColumns(monthlyNetIncomeTableColumns);
        setTRIRTableColumns(monthlyTRIRTableColumns);
        setAttritionTableColumns(monthlyAttritionTableColumns);

        break;
      case "Q":
        setVoluntaryTableColumns(quarterlyVoluntaryTableColumns);
        setGrossProfitTableColumns(quarterlyGrossProfitTableColumns);
        setNetExpenseTableColumns(quarterlyNetExpenseTableColumns);
        setNetIncomeTableColumns(quarterlyNetIncomeTableColumns);
        setTRIRTableColumns(quarterlyTRIRTableColumns);
        setAttritionTableColumns(quarterlyAttritionTableColumns);
        break;
      case "Y":
        setVoluntaryTableColumns(yearlyVoluntaryTableColumns);
        setGrossProfitTableColumns(yearlyGrossProfitTableColumns);
        setNetExpenseTableColumns(yearlyNetExpenseTableColumns);
        setNetIncomeTableColumns(yearlyNetIncomeTableColumns);
        setTRIRTableColumns(yearlyTRIRTableColumns);
        setAttritionTableColumns(yearlyAttritionTableColumns);
        break;
      default:
        setVoluntaryTableColumns(yearlyVoluntaryTableColumns);
        setGrossProfitTableColumns(yearlyGrossProfitTableColumns);
        setNetExpenseTableColumns(yearlyNetExpenseTableColumns);
        setNetIncomeTableColumns(yearlyNetIncomeTableColumns);
        setTRIRTableColumns(yearlyTRIRTableColumns);
        setAttritionTableColumns(yearlyAttritionTableColumns);
    }
    setSelectedPillItem(dashboardDateType);
    //make API call
    if (cardId === "voluntary") {
      sendVolRequest();
    } else if (cardId === "trir") {
      fetchTRIR();
    } else if (cardId === "bookedBacklog") {
      fetchBookedBacklog();
    } else {
      sendgrossProfitRequest();
    }
  }, [cardId, dateType, selectedMonthYear, buType, subBUFirstId, subBUId]);
  //Voluntary API request
  const sendVolRequest = async (tableFilterDateType?: any) => {
    const selectedDateType = tableFilterDateType
      ? tableFilterDateType
      : dateType;
    const month = selectedMonthYear.month;
    const currentMonth = month + 1;
    const year = selectedMonthYear.year;

    const voluntaryResponse = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/voluntary`,
        {
          dateFilter: selectedDateType || "Y",
          type: buType || "BU",
          unitId: buType === "SU" ? subBUId : bussUnit,
          month: currentMonth,
          year: year,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setVolTableData(res?.data?.data?.Table);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  };
  //Grossprofit, net expense and net income API request
  const sendgrossProfitRequest = async (tableFilterDateType?: any) => {
    const selectedDateType = tableFilterDateType
      ? tableFilterDateType
      : dateType;
    let dateTypeYandM = selectedDateType === "Q" ? "Y" : selectedDateType;
    const month = selectedMonthYear.month;
    const currentMonth = month + 1;
    const year = selectedMonthYear.year;
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/grossProfitAndNetExpense`,
        {
          dateFilter: dateTypeYandM || "Y",

          type: buType || "BU",
          unitId: buType === "SU" ? subBUId : bussUnit,
          month: currentMonth,
          year: year,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const responseData = res?.data?.data?.Table;
        const sortedObjectsWithMonth = responseData?.sort(
          (a: any, b: any) => monthsMap[a.month] - monthsMap[b.month]
        );
        setGrossProfitTableData(sortedObjectsWithMonth);
        setNetExpenseTableData(sortedObjectsWithMonth);
        setNetIncomeTableData(sortedObjectsWithMonth);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  };
  //TRIR API request
  const fetchTRIR = async (tableFilterDateType?: any) => {
    //UserData();
    const selectedDateType = tableFilterDateType
      ? tableFilterDateType
      : dateType;

    const month = selectedMonthYear.month;
    const currentMonth = month + 1;
    const year = selectedMonthYear.year;
    const trirResponse = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/trir`,
        {
          dateFilter: selectedDateType || "Y",
          type: buType || "BU",
          unitId: buType === "SU" ? subBUId : bussUnit,
          month: currentMonth,
          year: year,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const trirTableData = res?.data?.data?.Table;
        const sortedObjectsWithMonth = trirTableData?.sort(
          selectedDateType === "M"
            ? (a: any, b: any) => monthsMap[a.month] - monthsMap[b.month]
            : (a: any, b: any) => a.year - b.year
        );
        setTRIRTableData(sortedObjectsWithMonth);
      });
  };
  //Total booked backlog API request
  const fetchBookedBacklog = async (tableFilterDateType?: any) => {
    const selectedDateType = tableFilterDateType
      ? tableFilterDateType
      : dateType;

    const month = selectedMonthYear.month;
    const currentMonth = month + 1;
    const year = selectedMonthYear.year;
    const bookedBacklogResponse = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/bookedBacklog`,
        {
          dateFilter: "M",
          type: buType || "BU",
          unitId: buType === "SU" ? subBUId : bussUnit,
          month: currentMonth,
          year: year,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const bookedBacklogTableData = res?.data?.data?.Table;
        setBookedBacklogTableData(bookedBacklogTableData);
      });
  };
  //fetch selected card ID
  useEffect(() => {
    getSelectedCardID();
  }, [cardId]);

  //Get selected card name to display details heading
  const [detailsHeading, setDetailsHeading] = useState("grossProfit");
  const getSelectedCardID = () => {
    switch (cardId) {
      // case "attrition":
      //   setDetailsHeading("Attrition Rate");
      //   break;
      // case "aggregate":
      //   setDetailsHeading("Aggregate Client Satisfaction");
      //   break;
      case "voluntary":
        setDetailsHeading("Voluntary Turnover");
        break;
      case "grossProfit":
        setDetailsHeading("Gross Profit Margin");
        break;
      case "netExpense":
        setDetailsHeading("Net Expense % of GP");
        break;
      case "netIncome":
        setDetailsHeading("Net Income Margin %");
        break;
      case "trir":
        setDetailsHeading("TRIR");
        break;
      case "bookedBacklog":
        setDetailsHeading("Total Booked Backlog");
        break;
      default:
        setDetailsHeading("Gross Profit Margin");
    }
  };

  return (
    <>
      <Row className="d-flex align-items-center">
        <Col xs={12} md={4} lg={4} xl={3} className="mb-3">
          <small className="mb-0 semi-bold details-heading">
            {detailsHeading} Details
          </small>
        </Col>
        <div className="data-table-filters col-xl-9 col-md-8 col-lg-8 col-12 d-md-flex align-itesms-center justify-content-end">
          <div className="mb-3 mt-lg-1 message">
            <span className="body-text lightgray">
              {cardId === "voluntary" || cardId === "trir"
                ? ""
                : "All amount values are in thousands"}
            </span>
          </div>
          {/* Yearly, Quarterly and Monthly filter */}
          <div className="mb-3 pills">
            <div className="px-2 py-1 nav-fill border-1 nav nav-pills">
              <div className="nav-item">
                <a
                  onClick={() => handleMenuItemClick("Y")}
                  className={`nav-link ${
                    selectedPillItem === "Y" ? "active" : ""
                  }`}
                >
                  Yearly
                </a>
              </div>
              <div className="nav-item">
                <a
                  onClick={() => handleMenuItemClick("M")}
                  role="button"
                  className={`nav-link ${
                    selectedPillItem === "M" ? "active" : ""
                  }`}
                >
                  Monthly
                </a>
              </div>
              <div className="nav-item">
                <a
                  onClick={() => handleMenuItemClick("Q")}
                  role="button"
                  className={`nav-link ${
                    selectedPillItem === "Q" ? "active" : ""
                  }`}
                >
                  Quarterly
                </a>
              </div>
            </div>
          </div>
        </div>
      </Row>
      {/* Display active card table */}
      {cardId === "voluntary" && (
        <GenericTable
          tableData={volTableData}
          tableColumns={voluntaryTableColumns}
          tableHeadings={voluntaryColumnKeyToHeading}
          itemsPerPage={5}
          initialPage={1}
        />
      )}
      {cardId === "grossProfit" && (
        <GenericTable
          tableData={grossProfitTableData}
          tableColumns={grossProfitTableColumns}
          tableHeadings={grossProfitColumnKeyToHeading}
          itemsPerPage={5}
          initialPage={1}
        />
      )}
      {cardId === "netExpense" && (
        <GenericTable
          tableData={netExpenseTableData}
          tableColumns={netExpenseTableColumns}
          tableHeadings={netExpenseColumnKeyToHeading}
          itemsPerPage={5}
          initialPage={1}
        />
      )}
      {cardId === "netIncome" && (
        <GenericTable
          tableData={netIncomeTableData}
          tableColumns={netIncomeTableColumns}
          tableHeadings={netIncomeColumnKeyToHeading}
          itemsPerPage={5}
          initialPage={1}
        />
      )}
      {cardId === "trir" && (
        <GenericTable
          tableData={trirTableData}
          tableColumns={trirTableColumns}
          tableHeadings={trirColumnKeyToHeading}
          itemsPerPage={5}
          initialPage={1}
        />
      )}
      {cardId === "bookedBacklog" && selectedPillItem === "M" && (
        <GenericTable
          tableData={bookedBacklogTableData}
          tableColumns={totalBookedBacklogTableColumns}
          tableHeadings={grossProfitColumnKeyToHeading}
          itemsPerPage={6}
          initialPage={1}
        />
      )}

      {cardId === "bookedBacklog" && selectedPillItem !== "M" && <NoData />}

      {/* {cardId === "attrition" && (
        <GenericTable
          tableData={attrTableData}
          tableColumns={attritionTableColumns}
          tableHeadings={attritionColumnKeyToHeading}
          itemsPerPage={5}
          initialPage={1}
        />
      )} */}
    </>
  );
};
