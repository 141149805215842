import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { formatNumberWithCommasDecimals } from "../../../utils/numberFormatter";

interface CardProps {
  card: any;
  index: number;
}

export const KeyHighlightsTRIR: React.FC<CardProps> = ({ card, index }) => {
  return (
    <Col xs={12} xl={4}>
      <div className="employee-count parent-box mb-3">
        <Row className="gx-2">
          <Col xs={6} md={3}>
            <h6 className="mb-0 box-heading semi-bold">{card?.heading}</h6>
            <div className="asp-count-box mb-2 mb-sm-0">
              <p className="box-text">Aspirational</p>
              <p className="box-value semi-bold">
                {card?.aspirationalVal !== 0
                  ? `< ${card?.aspirationalVal}`
                  : card?.aspirationalVal}
              </p>
            </div>
          </Col>

          <Col xs={6} md={3}>
            <div
              className={`count-box ${
                card?.rate <= card?.aspirationalVal ? "green" : "red"
              } mb-2 mb-sm-0`}
            >
              <p className="box-text">
                Rate&nbsp;
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      ( Recordable Count / Total Hrs ) * 2,00,000
                    </Tooltip>
                  }
                  placement="bottom"
                >
                  <span className="info-icon"></span>
                </OverlayTrigger>
              </p>
              <p className="box-value semi-bold">
                {card?.rate ? card?.rate?.toFixed(2) : 0}
              </p>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <div className="count-box light-gray mb-2 mb-sm-0">
              <p className="box-text">{index === 0 ? "Incident" : "Counts"}</p>

              <p className="box-value semi-bold">
                {card?.count ? card?.count : 0}
              </p>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <OverlayTrigger
              overlay={
                <Tooltip>{formatNumberWithCommasDecimals(card?.hours)}</Tooltip>
              }
              placement="bottom"
            >
              <div className="count-box light-gray mb-2 mb-sm-0">
                <p className="box-text">Hours</p>

                <p className="box-value semi-bold">
                  {card?.hours ? (card?.hours / 1000000)?.toFixed(2) : 0}M
                </p>
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
