import { useEffect, useState } from "react";
import axios from "axios";
import { Badge, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
//redux imports
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
//util functions
import { isValidDataset } from "../../../../utils/isValidData";
import { BarChart } from "../../../Details/GenericComponents/Charts/BarChart";
import { graphColors } from "../../../../utils/graphColors";

import { ModalComponent } from "../../../Details/GenericComponents/ModalComponent";
interface CardProps {
  id: string;
  isActive: boolean;
  handleClick: (id: string) => void;
}

export const TotalBookedBacklog: React.FC<CardProps> = ({
  id,
  isActive,
  handleClick,
}) => {
  //redux toolkit
  const dispatch = useDispatch();
  const token: any = useSelector((state: RootState) => state.auth.token);
  const bussUnit = useSelector((state: RootState) => state.user.bussUnit);
  const buType = useSelector((state: RootState) => state.user.buType);
  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const selectedMonthYear = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );

  //store data
  const [bookedBacklogChartData, setBookedBacklogChartData] =
    useState<any>(null);
  //useEffect will fetch the data when any argument value change
  useEffect(() => {
    sendbookedBacklogRequest();
    setLoading(true);
  }, [selectedMonthYear, buType, subBUFirstId, subBUId]);

  const sendbookedBacklogRequest = async () => {
    //UserData();
    const month = selectedMonthYear.month;
    const currentMonth = month + 1;
    const year = selectedMonthYear.year;
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/bookedBacklog`,
        {
          dateFilter: "M",
          type: buType || "BU",
          unitId: buType === "SU" ? subBUId : bussUnit,
          month: currentMonth,
          year: year,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        //update loading state to false
        setLoading(false);

        //format chart data
        const chartData = res.data?.data?.Table;

        let chartFilteredLabels: any = [];
        const chartFilteredDataGpPer: any = [];
        const chartFilteredDataGpAmount: any = [];

        chartData?.forEach((item: any) => {
          let label: any;

          label = item.month + " " + item.year?.slice(2);

          if (!chartFilteredLabels?.includes(label)) {
            chartFilteredLabels?.push(label);
          }
          chartFilteredDataGpPer.push(item.gpPer?.toFixed(2));
          chartFilteredDataGpAmount.push(item.gpAmount?.toFixed(0));
        });

        const formattedChartData = {
          labels: chartFilteredLabels,
          datasets: [
            {
              type: "line" as const,
              label: "GP %",
              data: chartFilteredDataGpPer,
              backgroundColor: graphColors.gray,
              borderColor: graphColors.gray,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
              yAxisID: "y1",
            },
            {
              type: "bar" as const,
              label: "GP Amount",
              data: chartFilteredDataGpAmount,
              backgroundColor: graphColors.blue75O,
              borderColor: "#fff",
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
              yAxisID: "y",
            },
          ],
        };
        setBookedBacklogChartData(formattedChartData);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching total booked backlog data", error);
      });
  };
  const [loading, setLoading] = useState(true);
  //Flip card
  const [isFlipped, setIsFlipped] = useState(false);

  // const handleFlipClick = () => {
  //setIsFlipped(!isFlipped);
  // };
  //modal funcionality
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [componentType, setComponentType] = useState("");
  const [modalSize, setModalSize] = useState<string>();
  //show modal
  const handleShowModal = (
    content: React.ReactNode,
    title: string,
    type: string,
    modalSize?: string
  ) => {
    setModalContent(content);
    setModalTitle(title);
    setComponentType(type);
    setShowModal(true);
    setModalSize(modalSize);
  };

  //close modal
  const handleCloseModal = () => setShowModal(false);
  return (
    <Col xs={12} sm={6} md={6} lg={6} xl={3} className="kpi-card-column">
      <div
        className={`card ${isActive ? "active" : ""} ${
          isFlipped ? "flipped" : ""
        }`}
        onClick={() => handleClick("bookedBacklog")}
      >
        {loading ? (
          <div className="global-loading-card d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="danger" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="front tbb">
              <Row className="g-0">
                <Col xs={11}>
                  <h4 className="heading semi-bold">
                    <Link
                      to={`/dashboard/total-booked-backlog-details`}
                      className="heading-anchor"
                    >
                      Total Booked Backlog
                    </Link>
                  </h4>
                </Col>
                <Col xs={1}>
                  {/* <span
                    className="bulb-icon float-end"
                    onClick={handleModal}
                  ></span> */}
                  <span
                    className="expand-icon"
                    onClick={() =>
                      handleShowModal(
                        <BarChart
                          chartData={bookedBacklogChartData}
                          chartOptions="multiTypeChartOptiosMultiAxisTBB"
                          axisText1="GP Amount"
                          axisText2="GP %"
                          legendRequired={false}
                          axisTextRequired={false}
                        />,
                        "Total Booked Backlog",
                        "chart",
                        "small"
                      )
                    }
                  ></span>
                </Col>
              </Row>
              {isValidDataset(bookedBacklogChartData?.datasets) ? (
                <Row className="g-0">
                  <Col xs={12}>
                    <div className="chart-container tbb">
                      {bookedBacklogChartData ? (
                        <BarChart
                          chartData={bookedBacklogChartData}
                          chartOptions="multiTypeChartOptiosMultiAxisTBB"
                          axisText1="GP Amount"
                          axisText2="GP %"
                          legendRequired={false}
                          axisTextRequired={false}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              ) : (
                <p className="body-text">No Data Available</p>
              )}
            </div>
            
          </>
        )}
      </div>
      {/* Generic Modal Component will send the data onClick */}
      <ModalComponent
        show={showModal}
        onHide={handleCloseModal}
        modalTitle={modalTitle}
        bodyContent={modalContent}
        contentType={componentType}
        modalSize={modalSize}
      />
    </Col>
  );
};
