export const summaryTableDescriptions: { [key: string]: number } = {
    Revenue: 1,
    "Gross Profit Jobs": 2,
    "Total Gross Profit": 3,
    "Gross Expenses": 4,
    "Gross Recoveries": 5,
    "Net Expenses": 6,
    
    "Other Income/Expenses": 7,  
    "Operating Income": 8,  
    "Net Income": 9,
    "Job GP% of Revenue": 10,
    "Total GP% of Revenue": 11,
    "Gross Expenses % of Rev.": 12,
    
    "Net Exp% of Revenue":13,
    "Net Inc% of Revenue":14,
    "Net Exp% of GP":15,
    "Net Income% of GP":16,
    "Gross Rec. % of Gross Expenses": 17,
    "Net Exp% of Net Inc":18,

  };
  export const expensesTableDescriptions: { [key: string]: number } = {
    Payroll: 1,
    "Taxes & Fringes": 2,
    "Expense Challange":3,
    Travel: 4,
    Occupancy: 5,
    "Employee Welfare": 6,
    "Professional Services": 7,
    "Software Maint. & Technology": 8,
    Proposal: 9,
    "Dues & Supplies": 10,
    Communications: 11,
    "Training & Education": 12,
    "Recruiting & Relocation": 13,
    "Advertising/Promotions": 14,
    Depreciation: 15,
    "All Other Expenses": 16,
    "Gross Expenses": 17,
    "Gross Recoveries": 18,
    "Net Expenses": 19,
    "Gross Expenses % of Rev.": 20,
    "Gross Rec. % of Gross Expenses": 21,
  };