import { Pagination } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";

//table props
interface TableProps {
  tableData: any[];
  tableColumns: string[];
  tableHeadings: Record<string, string>;
  itemsPerPage: number;
  initialPage: number;
}
export const GenericTable: React.FC<TableProps> = ({
  tableData,
  tableColumns,
  tableHeadings,
  itemsPerPage,
  initialPage,
}) => {
  //Pagination & sorting
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  //const [itemsPerPage, setItemsPerPage] = useState(10);

  //set current card data
  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage, tableData, itemsPerPage]);
  //sorting logic
  const handleSort = (columnName: string) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };
  //function to compare two values based on sorting direction
  const compareValues = (value1: any, value2: any) => {
    if (value1 < value2) {
      return sortDirection === "asc" ? -1 : 1;
    } else if (value1 > value2) {
      return sortDirection === "asc" ? 1 : -1;
    } else {
      return 0;
    }
  };
  //sorting logic
  const data = tableData ? tableData : [];
  let sortedData = [...data];
  if (sortColumn) {
    //console.log("sortColumn", sortColumn);
    sortedData = sortedData.sort((a: any, b: any) =>
      compareValues(a[sortColumn], b[sortColumn])
    );
  }

  //pagination logic
  const totalItems = sortedData?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const tableCurrentItems = sortedData?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  //pagination text
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const paginationText = `${Math.min(
    indexOfFirstItem + 1,
    totalItems
  )}-${Math.min(indexOfLastItem, totalItems)} of ${totalItems}`;
  //format thousand seperator
  const formatNumberWithCommas = (value: any): string => {
    if (typeof value !== "number" || isNaN(value)) {
      return value;
    }
    return new Intl.NumberFormat("en-US").format(value);
  };
  return (
    <>
      <div className="table-container mb-2">
        <Table striped hover responsive className="generic-table">
          <thead>
            <tr>
              {tableColumns?.map((column: any, index: any) => (
                <th key={index} onClick={() => handleSort(column)}>
                  {tableHeadings[column]}
                  {sortColumn !== column && (
                    <span className="material-symbols-outlined default-filter-icon">
                      expand_all
                    </span>
                  )}
                  {sortColumn === column && (
                    <span>
                      {sortDirection === "asc" ? (
                        <span className="material-symbols-outlined arrow-up">
                          expand_more
                        </span>
                      ) : (
                        <span className="material-symbols-outlined arrow-down">
                          expand_less
                        </span>
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableCurrentItems?.length > 0 ? (
              tableCurrentItems?.map((item: any, index: any) => (
                <tr key={index}>
                  {tableColumns.map((column: any, columnIndex: any) => (
                    <td key={columnIndex}>
                      {typeof item[column] === "number" &&
                      item[column] % 1 !== 0
                        ? item[column] < 0
                          ? `(${formatNumberWithCommas(item[column] * -1)})` //when percentage is less than 0, display it within paranthesis ()
                          : formatNumberWithCommas(item[column]) //when percentage is more than 999 add thousand comma seperator
                        : column === "quarter"
                        ? `Q${item[column]}` //add Q for quarter numbers
                        : item[column] < 0
                        ? `(${formatNumberWithCommas(item[column] * -1)})` //when value is less than 0, display it within paranthesis ()
                        : item[column] > 999 && column !== "year"
                        ? formatNumberWithCommas(item[column]) //when value is more than 999 add thousand comma seperator
                        : item[column]}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={tableColumns.length}>No Data Available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="row mb-3">
        <div className="col-6"></div>
        <div className="col-6">
          <div className="row">
            <div className="col-6"></div>
            <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
              {/* Pagination text and arrows */}
              <p className="pagination-text body-text me-3">{paginationText}</p>
              <Pagination className="mb-0">
                <Pagination.Prev
                  onClick={() => paginate(Math.max(1, currentPage - 1))}
                  disabled={currentPage === 1}
                />
                <span>&nbsp;</span>
                <Pagination.Next
                  onClick={() =>
                    paginate(Math.min(totalPages, currentPage + 1))
                  }
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
