import { useEffect, useState } from "react";
import axios from "axios";
import { formatNumberWithCommasDecimals } from "../../../../utils/numberFormatter";
import {
  Badge,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";

//user details import
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { LineChart } from "../../../Details/GenericComponents/Charts/LineChart";

import { ModalComponent } from "../../../Details/GenericComponents/ModalComponent";
import { monthsMap } from "./MonthNames";
interface CardProps {
  id: string;
  isActive: boolean;
  handleClick: (id: string) => void;
}

export const NetExpense: React.FC<CardProps> = ({
  id,
  isActive,
  handleClick,
}) => {
  const token: any = useSelector((state: RootState) => state.auth.token);
  const bussUnit = useSelector((state: RootState) => state.user.bussUnit);
  const buType = useSelector((state: RootState) => state.user.buType);
  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const selectedMonthYear = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );
  let dateType = useSelector((state: RootState) => state.user.selectedDateType);

  //store details data
  const [netExpenseCardData, setNetExpenseCardData] = useState<any>(null);
  const [topPerformer, setTopPerformer] = useState<any>(null);
  const [netExpenseChartData, setNetExpenseChartData] = useState<any>(null);
  useEffect(() => {
    sendnetExpenseRequest();
    setLoading(true);
  }, [dateType, selectedMonthYear, buType, subBUFirstId, subBUId]);

  const sendnetExpenseRequest = async () => {
    //UserData();
    const month = selectedMonthYear.month;
    const currentMonth = month + 1;
    const year = selectedMonthYear.year;
    let dateTypeYandM = dateType === "Q" ? "Y" : dateType;
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/grossProfitAndNetExpense`,
        {
          dateFilter: dateTypeYandM || "Y",
          type: buType || "BU",
          unitId: buType === "SU" ? subBUId : bussUnit,
          month: currentMonth,
          year: year,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);

        setNetExpenseCardData(res.data?.data?.Card);
        setTopPerformer(res.data?.data?.TopPerformer);
        //format chart data
        const chartData = res.data?.data?.Table;
        //get last object for card data
        const getLastObject =
          chartData?.length > 0 ? chartData[chartData?.length - 1] : chartData;
        setNetExpenseCardData(getLastObject);
        const sortedObjectsWithMonth = chartData?.sort(
          dateType === "M"
            ? (a: any, b: any) => monthsMap[a.month] - monthsMap[b.month]
            : (a: any, b: any) => a.year - b.year
        );
        let chartFilteredLabels: any = [];
        const chartFilteredData: any = [];

        sortedObjectsWithMonth?.forEach((item: any) => {
          let label: any;
          if (item.month !== "") {
            label = item.month;
          } else {
            label = item.year;
          }
          if (!chartFilteredLabels?.includes(label)) {
            chartFilteredLabels?.push(label);
          }

          chartFilteredData.push(item.nePer?.toFixed(2));
        });

        const formattedChartData = {
          labels: chartFilteredLabels,
          datasets: [
            {
              data: chartFilteredData,
              backgroundColor: "#24282b",
              borderColor: "#59656e",
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
            },
          ],
        };
        setNetExpenseChartData(formattedChartData);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data", error);
      });
  };
  const [loading, setLoading] = useState(true);
  //Flip card
  const [isFlipped, setIsFlipped] = useState(false);
  const handleFlipClick = () => {
    //setIsFlipped(!isFlipped);
  };
  //check the chart data is valid
  const isValidDataset = (data: any[]): boolean => {
    const allValues = data?.flatMap((dataset) => dataset.data);
    return allValues?.some((item) => item !== undefined && item !== null);
  };
  //modal funcionality
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [componentType, setComponentType] = useState("");
  const [modalSize, setModalSize] = useState<string>();
  //show modal
  const handleShowModal = (
    content: React.ReactNode,
    title: string,
    type: string,
    modalSize?: string
  ) => {
    setModalContent(content);
    setModalTitle(title);
    setComponentType(type);
    setShowModal(true);
    setModalSize(modalSize);
  };

  //close modal
  const handleCloseModal = () => setShowModal(false);
  return (
    <Col xs={12} sm={6} md={6} lg={6} xl={3} className="kpi-card-column">
      <div
        className={`card ${isActive ? "active" : ""} ${
          isFlipped ? "flipped" : ""
        }`}
        onClick={() => handleClick("netExpense")}
      >
        {loading ? (
          <div className="global-loading-card d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="danger" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="front">
              <Row className="g-0">
                <Col xs={11}>
                  <h4 className="heading semi-bold">
                    <Link
                      to={`/dashboard/net-expense-details`}
                      className="heading-anchor"
                    >
                      Net Expense % of GP
                    </Link>
                  </h4>
                </Col>
                <Col xs={1}>
                  {/* <span
                    className="bulb-icon float-end"
                    onClick={handleFlipClick}
                  ></span> */}
                  <span
                    className="expand-icon"
                    onClick={() =>
                      handleShowModal(
                        <LineChart
                          chartData={netExpenseChartData}
                          chartOptions="dashboardOptions"
                          axisText1="Net Exp %"
                        />,
                        "Net Income Margin %",
                        "chart",
                        "small"
                      )
                    }
                  ></span>
                </Col>
              </Row>
              {isValidDataset(netExpenseChartData?.datasets) ? (
                <Row className="g-0">
                  <Col
                    className={`${
                      topPerformer?.topPerformerValue ? "col-6" : "col-3"
                    }`}
                  >
                    <h3 className="revenue">
                      {netExpenseCardData?.neAmount
                        ? formatNumberWithCommasDecimals(
                            netExpenseCardData?.neAmount,
                            0
                          )
                        : 0}
                    </h3>
                    <Row className="g-0">
                      <Col
                        className={`${
                          topPerformer?.topPerformerValue ? "col-6" : "col-12"
                        }`}
                      >
                        {/* Current badge */}
                        <p className="current">Current</p>
                        <Badge
                          bg="danger"
                          className={`${
                            netExpenseCardData?.nePer < 38
                              ? "badge-green"
                              : "badge-red"
                          } semi-bold`}
                        >
                          <span className="material-symbols-outlined">
                            {netExpenseCardData?.nePer < 38
                              ? "arrow_downward"
                              : "arrow_upward"}
                          </span>
                          {netExpenseCardData?.nePer
                            ? formatNumberWithCommasDecimals(
                                netExpenseCardData?.nePer,
                                2,
                                true
                              )
                            : 0}
                        </Badge>
                        <p className="aspirational">Aspirational</p>
                        <p className="subtitle-percentage">&lt; 38%</p>
                      </Col>
                      <Col
                        className={`${
                          topPerformer?.topPerformerValue ? "col-6" : ""
                        }`}
                      >
                        {topPerformer ? (
                          <>
                            <p className="top-performer">Top Recovery</p>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {topPerformer?.topPerformerDesc}
                                  <br />
                                  {formatNumberWithCommasDecimals(
                                    topPerformer?.topPerformerValue,
                                    0
                                  )}
                                </Tooltip>
                              }
                              placement="bottom"
                            >
                              <Badge
                                bg="danger"
                                className="badge-blue semi-bold"
                              >
                                {formatNumberWithCommasDecimals(
                                  topPerformer?.topPerformerValue,
                                  0
                                )}
                              </Badge>
                            </OverlayTrigger>
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className={`${
                      topPerformer?.topPerformerValue ? "col-6" : "col-9"
                    }`}
                  >
                    <div className="chart-container">
                      {netExpenseChartData ? (
                        <LineChart
                          chartData={netExpenseChartData}
                          chartOptions="dashboardOptions"
                          axisText1="Net Exp %"
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              ) : (
                <p className="body-text">No Data Available</p>
              )}
            </div>
            
          </>
        )}
      </div>
      {/* Generic Modal Component will send the data onClick */}
      <ModalComponent
        show={showModal}
        onHide={handleCloseModal}
        modalTitle={modalTitle}
        bodyContent={modalContent}
        contentType={componentType}
        modalSize={modalSize}
      />
    </Col>
  );
};
