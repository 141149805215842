//format the number with commas(thousand seperator)
export const formatNumberWithCommasDecimals = (
    value: any,
    decimals?: number,
    percentage?:boolean,
  ): string => {
    if (typeof value !== "number" || isNaN(value)) {
      return "0";
    }
    let formattedValue: string;
    if (decimals !== undefined) {
      formattedValue = value.toFixed(decimals);
    } else {
      formattedValue = value.toString();
    }
    formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //if value is less than 0, remove - and add paranthesis()
    if (value < 0){
        formattedValue = `(${formattedValue.replace('-','')}${percentage ? "%" :""})`
    }else{
        formattedValue =  `${formattedValue}${percentage ? "%" :""}`
    }
    return formattedValue;
  };
