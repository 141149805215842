import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Col, Row, Spinner } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { formatNumberWithCommasDecimals } from "../../../../utils/numberFormatter";
//user data
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setProgress,
  setDisplayProgress,
} from "../../../../redux/progressSlice";

import { ModalComponent } from "../../../Details/GenericComponents/ModalComponent";
//Options for doughnut chart
const doughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom" as const,
      labels: {
        boxWidth: 11,
        padding: 5,

        font: {
          size: 11,
        },
      },
    },
  },
};

interface CardProps {
  id: string;
  isActive: boolean;
  handleClick: (id: string) => void;
}
export const VoluntaryTurnover: React.FC<CardProps> = ({
  id,
  isActive,
  handleClick,
}) => {
  const dispatch = useDispatch();
  const token: any = useSelector((state: RootState) => state.auth.token);
  const bussUnit = useSelector((state: RootState) => state.user.bussUnit);
  const buType = useSelector((state: RootState) => state.user.buType);
  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const selectedMonthYear = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );
  const dateType = useSelector(
    (state: RootState) => state.user.selectedDateType
  );
  //set loading state
  const [loading, setLoading] = useState(true);

  //State to store voluntary card and table data
  const [volCardData, setVolCardData] = useState<any>(null);
  //state to store voluntary chart data
  const [volChartData, setVolChartData] = useState<any>({});

  const sendVolRequest = async () => {
    dispatch(setProgress(0));
    const month = selectedMonthYear.month;
    const currentMonth = month + 1;
    const year = selectedMonthYear.year;

    const voluntaryResponse = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/voluntary`,
        {
          dateFilter: dateType || "Y",
          type: buType || "BU",
          unitId: buType === "SU" ? subBUId : bussUnit,
          month: currentMonth,
          year: year,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);

        //Filtered data
        const chartData = res.data?.data?.Table;
        //get last object for card data
        const getLastObject =
          chartData?.length > 0 ? chartData[chartData?.length - 1] : chartData;
        setVolCardData(getLastObject);
        const voluntaryCount = chartData?.reduce(
          (partialSum: any, value: any) => partialSum + value.voluntaryCount,
          0
        );
        const involuntaryCount = chartData?.reduce(
          (partialSum: any, value: any) => partialSum + value.involuntaryCount,
          0
        );

        //const voluntaryCount = chartData.forEach
        const formattedData = {
          labels: ["Voluntary", "Involuntary"],
          datasets: [
            {
              data: [voluntaryCount, involuntaryCount],
              backgroundColor: ["#ffd578", "#bdc5c9"],
              borderColor: "#fff",
              fill: false,
            },
          ],
        };
        setVolChartData(formattedData);
        dispatch(setProgress(100));
        dispatch(setDisplayProgress(true));
      })
      .catch((error) => {
        console.error("Error fetching data", error);
        setLoading(false);
        dispatch(setDisplayProgress(true));
      });
  };

  useEffect(() => {
    sendVolRequest();
    setLoading(true);
  }, [dateType, selectedMonthYear, buType, subBUFirstId, subBUId]);
  //hanlde flip
  const [isFlipped, setIsFlipped] = useState(false);
  const handleFlipClick = () => {
    //setIsFlipped(!isFlipped);
  };

  const isValidDataset = (data: any[]): boolean => {
    const allValues = data?.flatMap((dataset) => dataset.data);
    return allValues?.some((item) => item !== 0 && item !== null);
  };
  //modal funcionality
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [componentType, setComponentType] = useState("");
  const [modalSize, setModalSize] = useState<string>();
  //show modal
  const handleShowModal = (
    content: React.ReactNode,
    title: string,
    type: string,
    modalSize?: string
  ) => {
    setModalContent(content);
    setModalTitle(title);
    setComponentType(type);
    setShowModal(true);
    setModalSize(modalSize);
  };
  //close modal
  const handleCloseModal = () => setShowModal(false);
  return (
    <Col xs={12} sm={6} md={6} lg={6} xl={3} className="kpi-card-column">
      <div
          className={`card ${isActive ? "active" : ""} ${
            isFlipped ? "flipped" : ""
          }`}
          onClick={() => handleClick("voluntary")}
        >
          {loading ? (
            <div className="global-loading-card d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="danger" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="front">
                <Row className="g-0">
                  <Col xs={11}>
                    <h4 className="heading semi-bold">
                      <Link
                        to={`/dashboard/voluntary-details`}
                        className="heading-anchor"
                      >
                        Voluntary Turnover
                      </Link>
                    </h4>
                  </Col>
                  <Col xs={1}>
                    {/* <span
                      className="bulb-icon float-end"
                      onClick={handleFlipClick}
                    ></span> */}
                    <span
                      className="expand-icon"
                      onClick={() =>
                        handleShowModal(
                          <Doughnut
                            data={volChartData}
                            options={doughnutChartOptions}
                          />,
                          "Voluntary Turnover",
                          "chart",
                          "small"
                        )
                      }
                    ></span>
                  </Col>
                </Row>
                {isValidDataset(volChartData?.datasets) ? (
                  <Row className="g-0">
                    <Col xs={4}>
                      <h3 className="revenue">{volCardData?.voluntaryCount}</h3>
                      <Row className="g-0">
                        <Col xs={8}>
                          <p className="current">Current</p>
                          <Badge
                            bg="danger"
                            className={`${
                              volCardData?.volTurnoverRate < 10
                                ? "badge-green"
                                : "badge-red"
                            } semi-bold`}
                          >
                            <span className="material-symbols-outlined">
                              {volCardData?.volTurnoverRate < 10
                                ? "arrow_downward"
                                : "arrow_upward"}
                            </span>
                            {formatNumberWithCommasDecimals(
                              volCardData?.volTurnoverRate,
                              1,
                              true
                            )}
                          </Badge>
                        </Col>
                      </Row>
                      <p className="aspirational">Goal</p>
                      <p className="subtitle-percentage">&lt; 10%</p>
                    </Col>
                    <Col xs={8}>
                      <div className="chart-container">
                        {volChartData ? (
                          <Doughnut
                            data={volChartData}
                            options={doughnutChartOptions}
                          />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <p className="body-text">No Data Available</p>
                )}
              </div>
              
            </>
          )}
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalTitle={modalTitle}
            bodyContent={modalContent}
            contentType={componentType}
            modalSize={modalSize}
          />
        </div>
    </Col>
  );
};
