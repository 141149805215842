import { useState, useEffect, createContext, PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "./redux/store";
import { setDarkTheme } from "./redux/cardSlice";
export type ThemeContextType = {
  theme: string;
  setTheme: (t: string) => void;
  changeTheme: () => void;
};

const ThemeContext = createContext<ThemeContextType>({
  theme: "light",
  setTheme: () => {},
  changeTheme: () => {},
});

//get selected theme from session storage
const getTheme = () => {
  const theme = localStorage.getItem("theme");
  if (!theme) {
    localStorage.setItem("theme", "light");
    return "light";
  } else {
    return theme;
  }
};

const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [theme, setTheme] = useState(getTheme);
  const dispatch: AppDispatch = useDispatch();
  dispatch(setDarkTheme(theme));
  //change theme on click
  const changeTheme = () => {
    if (theme === "dark") {
      setTheme("light");
      dispatch(setDarkTheme("light"));
    } else {
      setTheme("dark");
      dispatch(setDarkTheme("dark"));
    }
  };

  useEffect(() => {
    const refreshTheme = () => {
      localStorage.setItem("theme", theme);
    };
    refreshTheme();
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
export { ThemeContext, ThemeProvider };
