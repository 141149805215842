import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useState } from "react";

//interface
interface ModalProps {
  show: boolean;
  onHide: () => void;
  modalTitle: string;
  bodyContent: React.ReactNode;
  contentType?: string;
  thousandsText?: boolean;
  modalSize?: string;
}
export const ModalComponent: React.FC<ModalProps> = ({
  show,
  onHide,
  modalTitle,
  bodyContent,
  contentType,
  thousandsText,
  modalSize,
}) => {
  const theme = useSelector((state: RootState) => state.card.darkTheme);

  return (
    <Modal
      size={modalSize === "xl" ? "xl" : "lg"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      className={`${theme === "dark" ? "dark-theme" : "light-theme"}`}
    >
      <Modal.Header>
        <div className="left-content d-flex align-items-center">
          <Modal.Title id="contained-modal-title-vcenter">
            {modalTitle}
          </Modal.Title>
          {thousandsText ? (
            <p className="body-text info-text ms-2">
              All the values are in thousands
            </p>
          ) : null}
        </div>
        <span className="material-symbols-outlined float-end" onClick={onHide}>
          close
        </span>
      </Modal.Header>
      <Modal.Body>
        <div
          className={`${
            contentType === "chart" ? "chart d-flex justify-content-center" : ""
          } ${
            modalSize === "xl"
              ? "overflow"
              : modalSize === "small"
              ? "small-height"
              : ""
          }`}
        >
          {bodyContent ? (
            bodyContent
          ) : (
            <div className="no-data-alert">
              <p className="text text-center">No Data Available</p>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
