import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//redux imports
import { Provider } from "react-redux";
import store from "./redux/store";

//imports for login functionality
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

// Primary styles import
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import "./styles/base/typography.scss";
import { ThemeProvider } from "./Theme";

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: any) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
      event.payload.account)
  ) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider>
      <App instance={msalInstance} />
    </ThemeProvider>
  </Provider>
  // {/* </React.StrictMode> */}
);

reportWebVitals();
