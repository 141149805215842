interface Props {
  customClass?: string;
}
export const NoData: React.FC<Props> = ({ customClass }) => {
  return (
    <div className={`no-data-alert ${customClass}`}>
      <p className="text">No Data Available</p>
    </div>
  );
};
