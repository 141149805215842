import {
  Alert,
  Col,
  OverlayTrigger,
  ProgressBar,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { BreadCrumbComponent } from "./GenericComponents/BreadcrumbComponent";
import { useEffect, useRef, useState } from "react";
import {
  setBUType,
  setSubBUId,
  setSelectedMonthYear,
} from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BarChart } from "./GenericComponents/Charts/BarChart";
import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { graphColors } from "../../utils/graphColors";
import { Doughnut } from "react-chartjs-2";

import axios from "axios";
import { ModalComponent } from "./GenericComponents/ModalComponent";
import { formatNumberWithCommasDecimals } from "../../utils/numberFormatter";
import { monthsMap } from "../Dashboard/Content/KPIcards/MonthNames";
import { isValidDataset } from "../../utils/isValidData";

ChartJS.register(ArcElement, Legend);

export const TotalBookedBacklog = () => {
  const dispatch = useDispatch();
  //redux imports
  const token = useSelector((state: RootState) => state.auth.token);
  const bussUnit = useSelector((state: RootState) => state.user.bussUnit);
  const buType = useSelector((state: RootState) => state.user.buType);
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const selectedDateType: any = useSelector(
    (state: RootState) => state.user.selectedDateType
  );
  const selectedMonthYear = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );
  const companyList = useSelector((state: RootState) => state.user.companyList);
  let allCompanyIds = companyList?.map((obj: any) => obj.companyId);
  let allCompanyCodes = companyList?.map((obj: any) => obj.companyCode);
  let companyIds = allCompanyIds?.join(",");
  const subBUList = useSelector((state: RootState) => state.user.subUnitList);
  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const marketList = useSelector((state: RootState) => state.market.marketList);
  const [progress, setProgress] = useState<number>(0);
  const [dispalyProgress, setDispalyProgress] = useState<boolean>(true);
  const [subBUName, setSubBUName] = useState<any>();

  const [selectedPillItem, setSelectedPillItem] = useState("BU");
  const [deliveryMethodsProfit, setDeliveryMethodsProfit] = useState<any>();
  const [deliveryMethodsProfitPer, setDeliveryMethodsProfitPer] =
    useState<any>();
  const [cosFacilitiesProfit, setCOSFacilitiesProfit] = useState<any>();
  const [cosFacilitiesProfitPer, setCOSFacilitiesProfitPer] = useState<any>();
  const [jobStatusChartData, setJobStatusChartData] = useState<any>();
  const [backlogProfitBreakdownbySubBU, setBacklogProfitBreakdownbySubBU] =
    useState<any>();
  const [
    backlogProfitBreakdownbySubBUProfitPer,
    setBacklogProfitBreakdownbySubBUProfitPer,
  ] = useState<any>();

  const [backlogProfitbyDeliveryMethods, setBacklogProfitbyDeliveryMethods] =
    useState<any>();
  const [monthCardDataset, setMonthCardDataset] = useState<any>();
  const [profitbyCOSChartData, setProfitbyCOSChartData] = useState<any>();
  const [profitbyCOSProfitPer, setProfitbyCOSProfitPer] = useState<any>();
  const [contractsChartData, setContractsChartData] = useState<any>();
  const [contractsProfitPer, setContractsProfitPer] = useState<any>();
  //loading
  const [loading, setLoading] = useState(true);
  //store API response and status
  const [tbbResponse, setTBBResponse] = useState<any>(null);
  const [tbbGraphsResponse, setTBBGraphsResponse] = useState<any>(null);
  const [tbbStatus, setTBBStatus] = useState<any>(null);
  const [tbbGraphStatus, setTBBGraphStatus] = useState<any>(null);
  const [cardHeightFacility, setCardHeightFacility] = useState<any>(null);
  const [cardHeightContract, setCardHeightContract] = useState<any>(null);
  useEffect(() => {
    handleFilterUpdateProgress();
    //refresh company list
    let allCompanyIds = companyList?.map((obj: any) => obj.companyId);
    let allCompanyCodes = companyList?.map((obj: any) => obj.companyCode);
    if (marketList) {
      fetchTBBDetails({
        month: selectedMonthYear.month,
        year: selectedMonthYear.year,
        company: allCompanyIds,
        dateType: selectedDateType === "Q" ? "Y" : selectedDateType,
        selectedBU: buType,
        selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
        market: marketList,
      });
    }
    //update selected bu type on page load
    setSelectedPillItem(buType ? buType : selectedPillItem);
    setSubBUName(subBUId);
    //update company list on page load
    setSelectedCompanies(allCompanyIds!);
    setSelectedCompanyNames(allCompanyCodes!);
    setSelectedMarkets(marketList!);
    //setMarket(selectFirstMarket!);
  }, [marketList, subBUFirstId]);

  const fetchTBBDetails = async (payload?: any) => {
    setProgress(0);
    try {
      const {
        month,
        year,
        company,
        selectedBU,
        selectedbussUnit,
        market,
        kpi,
      } = payload || {};
      const currentMonth = month !== undefined ? month + 1 : undefined;
      const selectCompIds = company?.join(",");
      const typeofBU = selectedBU ? selectedBU : buType;
      const bussUnitID = selectedbussUnit
        ? selectedbussUnit
        : typeofBU === "SU"
        ? subBUId
        : bussUnit;
      const marketString = market?.join(",");
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_URL}/bookedBacklogDetails`,
          {
            dateFilter: "M",
            type: typeofBU || "BU",
            unitId: bussUnitID,
            month: currentMonth,
            year: year,
            companies:
              selectCompIds?.length === 0 ? "" : selectCompIds || companyIds,
            market: marketString,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setTBBResponse(res?.data?.data?.Map);
          setTBBStatus(res?.data?.status);
          fetchTBBGraphDetails({
            month: month,
            year: year,
            company:
              selectCompIds?.length == 0 ? "" : selectCompIds || companyIds,
            dateType: "M",
            selectedBU: typeofBU || "BU",
            selectedbussUnit: bussUnitID,
            market: marketString,
          });

          //3 Major Backlog Profit Delivery Methods
          const deliveryCard = res?.data?.data?.Map?.Delivey;
          const sortDeliveryCard =
            deliveryCard?.length > 1
              ? deliveryCard
                  ?.slice()
                  .sort((a: any, b: any) => b.profit - a.profit)
              : deliveryCard;
          const threeeMajorDeliveryObjects = sortDeliveryCard?.slice(0, 3);
          const deliveryMethodsLabels = Array.from(
            new Set(
              threeeMajorDeliveryObjects?.map((item: any) => item.description)
            )
          );
          //Initialize array to store chart data
          const deliveryMethodsProfit: any[] = [];
          const deliveryMethodsProfitPer: any[] = [];
          //iterate over labels
          deliveryMethodsLabels?.forEach((label) => {
            const matchingItem = threeeMajorDeliveryObjects?.find(
              (obj: any) => obj.description === label
            );
            if (matchingItem) {
              deliveryMethodsProfit.push(matchingItem.profit);
              deliveryMethodsProfitPer.push(matchingItem.backlogProfit);
            } else {
              //if no matching description found, push 0 or default value
              deliveryMethodsProfit.push(0);
              deliveryMethodsProfitPer.push(0);
            }
          });
          const data = {
            labels: deliveryMethodsLabels,
            datasets: [
              {
                label: "Profit",
                data: deliveryMethodsProfit,
                backgroundColor: graphColors.blue,
                borderColor: "#fff",
              },
            ],
          };

          setDeliveryMethodsProfit(data);
          setDeliveryMethodsProfitPer(deliveryMethodsProfitPer);
          //3 Major Backlog Profit COS Facilities
          const facilityCard = res?.data?.data?.Map?.Facility;
          const sortFacilityCard =
            facilityCard?.length > 1
              ? facilityCard
                  ?.slice()
                  .sort((a: any, b: any) => b.profit - a.profit)
              : facilityCard;
          const threeeMajorFacilityObjects = sortFacilityCard?.slice(0, 3);
          const facilityCardLabels = Array.from(
            new Set(
              threeeMajorFacilityObjects?.map((item: any) => item.description)
            )
          );
          //Initialize array to store chart data
          const facilityCardProfit: any[] = [];
          const facilityCardProfitPer: any[] = [];
          //iterate over labels
          facilityCardLabels?.forEach((label) => {
            const matchingItem = threeeMajorFacilityObjects?.find(
              (obj: any) => obj.description === label
            );
            if (matchingItem) {
              facilityCardProfit.push(matchingItem.profit);
              facilityCardProfitPer.push(matchingItem.backlogProfit);
            } else {
              //if no matching description found, push 0 or default value
              facilityCardProfit.push(0);
              facilityCardProfitPer.push(0);
            }
          });
          const cosFacilitiesdata = {
            labels: facilityCardLabels,
            datasets: [
              {
                label: "Profit",
                data: facilityCardProfit,
                backgroundColor: [graphColors.blue],
                borderColor: "#fff",
              },
            ],
          };
          setCOSFacilitiesProfit(cosFacilitiesdata);
          setCOSFacilitiesProfitPer(facilityCardProfitPer);

          //Backlog Profit by Delivery Methods
          const deliveryMethodsCard = res?.data?.data?.Map?.Delivey;

          const deliveryMethodsCardLabels = Array.from(
            new Set(deliveryMethodsCard?.map((item: any) => item.description))
          );

          //Initialize array to store chart data
          const backlogProfitbyDeliveryMethodsProfit: any[] = [];
          const backlogProfitbyDeliveryMethodsProfitPer: any[] = [];
          deliveryMethodsCardLabels?.forEach((label) => {
            const matchingItem = deliveryMethodsCard?.find(
              (obj: any) => obj.description === label
            );
            if (matchingItem) {
              backlogProfitbyDeliveryMethodsProfit.push(matchingItem.profit);
              backlogProfitbyDeliveryMethodsProfitPer.push(
                matchingItem.profitPer
              );
            } else {
              //if no matching description found, push 0 or default value
              backlogProfitbyDeliveryMethodsProfit.push(0);
              backlogProfitbyDeliveryMethodsProfitPer.push(0);
            }
          });

          const deliveryDataset = {
            labels: deliveryMethodsCardLabels,
            datasets: [
              {
                type: "line",
                label: "Profit %",
                data: backlogProfitbyDeliveryMethodsProfitPer,
                backgroundColor: graphColors.gray,
                borderColor: graphColors.gray,
                borderWidth: 1.5,
                pointBorderColor: "#fff",
                pointRadius: 3,
                pointSize: 2,
                fill: false,
                yAxisID: "y1",
              },
              {
                type: "bar",
                label: "Profit",
                data: backlogProfitbyDeliveryMethodsProfit,
                backgroundColor: graphColors.blue75O,
                borderColor: graphColors.blue75O,
                borderWidth: 1.5,
                pointBorderColor: "#fff",
                pointRadius: 3,
                pointSize: 2,
                fill: false,
                yAxisID: "y",
              },
            ],
          };

          setBacklogProfitbyDeliveryMethods(deliveryDataset);

          //Backlog Profit Breakdown by COS Facilities
          const breakdownbyFacilityCard = res?.data?.data?.Map?.Facility;

          //calculate height based on data length
          const cardHeightFacility =
            breakdownbyFacilityCard?.length <= 5
              ? "10rem"
              : breakdownbyFacilityCard?.length <= 10
              ? "20rem"
              : breakdownbyFacilityCard?.length * 2 + "rem";
          setCardHeightFacility(cardHeightFacility);

          const breakdownbyFacilityCardLabels = Array.from(
            new Set(
              breakdownbyFacilityCard?.map((item: any) => item.description)
            )
          );
          //Initialize array to store chart data
          const breakdownbyFacilityCardProfit: any[] = [];
          const breakdownbyFacilityCardProfitPer: any[] = [];

          breakdownbyFacilityCardLabels?.forEach((label) => {
            const matchingItem = breakdownbyFacilityCard?.find(
              (obj: any) => obj.description === label
            );
            if (matchingItem) {
              breakdownbyFacilityCardProfit.push(matchingItem.profit);
              breakdownbyFacilityCardProfitPer.push(matchingItem.backlogProfit);
            } else {
              //if no matching description found, push 0 or default value
              backlogProfitbyDeliveryMethodsProfit.push(0);
              backlogProfitbyDeliveryMethodsProfitPer.push(0);
            }
          });
          const profitbyCOS: ChartData<"bar"> = {
            labels: breakdownbyFacilityCardLabels,
            datasets: [
              {
                label: "Profit",
                data: breakdownbyFacilityCardProfit,
                backgroundColor: graphColors.red75O,
                borderColor: "#fff",
              },
            ],
          };
          setProfitbyCOSChartData(profitbyCOS);
          setProfitbyCOSProfitPer(breakdownbyFacilityCardProfitPer);

          //More and Less Backlog Profit
          const breakdownbyContractCard = res?.data?.data?.Map?.Contract;
          const cardHeightContract =
            breakdownbyContractCard?.length <= 5
              ? "10rem"
              : breakdownbyContractCard?.length <= 10
              ? "20rem"
              : breakdownbyContractCard?.length * 2 + "rem";
          setCardHeightContract(cardHeightContract);
          const breakdownbyContractCardLabels = Array.from(
            new Set(
              breakdownbyContractCard?.map((item: any) => item.description)
            )
          );
          //Initialize array to store chart data
          const breakdownbyContractCardProfit: any[] = [];
          const breakdownbyContractCardProfitPer: any[] = [];
          //iterate over labels
          breakdownbyContractCardLabels?.forEach((label) => {
            const matchingItem = breakdownbyContractCard?.find(
              (obj: any) => obj.description === label
            );
            if (matchingItem) {
              breakdownbyContractCardProfit.push(matchingItem.profit);
              breakdownbyContractCardProfitPer.push(matchingItem.backlogProfit);
            } else {
              //if no matching description found, push 0 or default value
              breakdownbyContractCardProfit.push(0);
              breakdownbyContractCardProfitPer.push(0);
            }
          });
          const profit5Contracts: ChartData<"bar"> = {
            labels: breakdownbyContractCardLabels,
            datasets: [
              {
                label: "Profit",
                data: breakdownbyContractCardProfit,
                backgroundColor: graphColors.red75O,
                borderColor: "#fff",
              },
            ],
          };
          setContractsChartData(profit5Contracts);
          setContractsProfitPer(breakdownbyContractCardProfitPer);
          setProgress(100);
          setDispalyProgress(true);
        });
    } catch (error: any) {
      console.error("Error fetching total booked backlog details data", error);
      //update status
      setTBBStatus(error.response?.data?.status);
      //update progress state
      setDispalyProgress(true);
    }
  };

  //fetch total booked backlog data
  const fetchTBBGraphDetails = async (payload?: any) => {
    setProgress(0);
    try {
      const {
        month,
        year,
        company,
        selectedBU,
        selectedbussUnit,
        market,
        kpi,
      } = payload || {};
      const currentMonth = month !== undefined ? month + 1 : undefined;
      //const selectCompIds = company?.join(",");
      const typeofBU = selectedBU ? selectedBU : buType;
      const bussUnitID = selectedbussUnit
        ? selectedbussUnit
        : typeofBU === "SU"
        ? subBUId
        : bussUnit;

      const response = await axios
        .post(
          `${process.env.REACT_APP_API_URL}/bookedBacklogGraphDetails`,
          {
            dateFilter: "M",
            type: typeofBU || "BU",
            unitId: bussUnitID,
            month: currentMonth,
            year: year,
            companies: company,
            market: market,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setTBBGraphsResponse(res?.data?.data?.MapGraphs);
          setTBBGraphStatus(res?.data?.status);
          setLoading(false);

          //Backlog Profit Breakdown by Job Financial Status
          const jobStatusCard =
            res?.data?.data?.MapGraphs?.Job_Financial_Status;
          //labels
          const jobStatusCardLabels = Array.from(
            new Set(jobStatusCard?.map((item: any) => item.description))
          );

          //Initialize array to store chart data
          const jobStatusCardData: any[] = [];

          jobStatusCardLabels?.forEach((label) => {
            const matchingItem = jobStatusCard?.find(
              (obj: any) => obj.description === label
            );
            if (matchingItem) {
              jobStatusCardData.push(
                matchingItem.profit > 0 && matchingItem.profit
              );
            } else {
              //if no matching description found, push 0 or default value
              jobStatusCardData.push(0);
            }
          });
          const jobStatusChartData = {
            labels: jobStatusCardLabels,
            datasets: [
              {
                data: jobStatusCardData,
                backgroundColor: function (context: any) {
                  const colors: string[] = [];
                  jobStatusCardLabels?.forEach((label: any, index: any) => {
                    if (label === "Open") {
                      colors.push(graphColors.green);
                    } else if (label === "Pending") {
                      colors.push(graphColors.red);
                    } else if (label === "Closed") {
                      colors.push(graphColors.yellow);
                    } else {
                      colors.push(graphColors.gray);
                    }
                  });
                  return colors;
                },

                borderColor: "#fff",
              },
            ],
          };
          setJobStatusChartData(jobStatusChartData);

          //Backlog Profit Breakdown by Sub BU
          const subbuCard = res?.data?.data?.MapGraphs?.sub_bu;

          const subbuCardLabels = Array.from(
            new Set(subbuCard?.map((item: any) => item.subUnit))
          );

          //Initialize array to store chart data
          const subbuCardCardData: any[] = [];
          const subbuCardCardDataProfitPer: any[] = [];
          subbuCardLabels?.forEach((label) => {
            const matchingItem = subbuCard?.find(
              (obj: any) => obj.subUnit === label
            );
            if (matchingItem) {
              subbuCardCardData.push(matchingItem.profit);
              subbuCardCardDataProfitPer.push(matchingItem?.backlogProfit);
            } else {
              //if no matching description found, push 0 or default value
              subbuCardCardData.push(0);
              subbuCardCardDataProfitPer.push(0);
            }
          });

          const backlogProfitBreakdownbySubBU = {
            labels: subbuCardLabels,
            datasets: [
              {
                data: subbuCardCardData,
                backgroundColor: graphColors.blue75O,
                //borderColor: "#fff",
                borderWidth: 1.5,
                //pointBorderColor: "#fff",
                pointRadius: 3,
                pointSize: 2,
                fill: false,
              },
            ],
          };
          setBacklogProfitBreakdownbySubBU(backlogProfitBreakdownbySubBU);
          setBacklogProfitBreakdownbySubBUProfitPer(subbuCardCardDataProfitPer);
          //Rolling 12 Months Profit & Profit %
          const monthCardData = res?.data?.data?.MapGraphs?.month_card;
          const monthCard = monthCardData?.sort((a: any, b: any) => {
            if (a.year !== b.year) {
              return a.year - b.year;
            }
            return monthsMap[a.month] - monthsMap[b.month];
          });

          const monthCardLabels = Array.from(
            new Set(monthCard?.map((item: any) => item.month))
          );
          //Initialize array to store chart data
          const monthCardLabelswithYear: string[] = [];
          const monthCardProfit: number[] = [];
          const monthCardProfitPer: number[] = [];
          //iterate over subUnits
          monthCardLabels?.forEach((label) => {
            const matchingItem = monthCard?.find(
              (obj: any) => obj.month === label
            );
            if (matchingItem) {
              monthCardLabelswithYear.push(
                matchingItem.month + " " + matchingItem.year
              );
              monthCardProfit.push(matchingItem.profit);
              monthCardProfitPer.push(matchingItem.profitPer);
            } else {
              //if no matching month found, push 0 or default value
              monthCardProfit.push(0);
              monthCardProfitPer.push(0);
            }
          });
          const monthCardDataset = {
            labels: monthCardLabelswithYear,
            datasets: [
              {
                type: "line" as const,
                label: "Profit %",
                data: monthCardProfitPer,
                backgroundColor: graphColors.gray,
                borderColor: graphColors.gray,
                borderWidth: 1.5,
                pointBorderColor: "#fff",
                pointRadius: 3,
                pointSize: 2,
                fill: false,
                yAxisID: "y1",
              },
              {
                type: "bar" as const,
                label: "Profit",
                data: monthCardProfit,
                backgroundColor: graphColors.blue75O,
                borderColor: graphColors.blue75O,
                borderWidth: 1.5,
                pointBorderColor: "#fff",
                pointRadius: 3,
                pointSize: 2,
                fill: false,
                yAxisID: "y",
              },
            ],
          };
          setMonthCardDataset(monthCardDataset);
        });
    } catch (error: any) {
      setLoading(false);
      console.error("Error fetching total booked backlog details data", error);
      //update status
      setTBBGraphStatus(error.response?.data?.status);

      //update progress state
      setDispalyProgress(true);
    }
  };
  //BU type functionality
  const handleMenuItemClick = (buType: string) => {
    handleFilterUpdateProgress();
    setSelectedPillItem(buType);
    dispatch(setBUType(buType));
    const unitID = buType === "SU" ? subBUId : bussUnit;
    fetchTBBDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: selectedCompanies,

      selectedBU: buType,
      selectedbussUnit: unitID,
      market: selectedMarkets,
    });
  };

  //Sub BU dropdown change functionality
  const handleSubBUChange = (subBUId: string) => {
    handleFilterUpdateProgress();
    setSubBUName(subBUId);
    dispatch(setSubBUId(subBUId));
    fetchTBBDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: selectedCompanies,

      selectedBU: buType,
      selectedbussUnit: subBUId,
      market: selectedMarkets,
    });
  };
  //Datepicker functionality
  const handleDateChange = (date: Date | null) => {
    handleFilterUpdateProgress();
    if (date) {
      const month = date.getMonth();
      const year = date!.getFullYear();
      dispatch(
        setSelectedMonthYear({
          month,
          year,
        })
      );
    }
    fetchTBBDetails({
      month: date?.getMonth(),
      year: date?.getFullYear(),
      company: selectedCompanies,

      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
      market: selectedMarkets,
    });
  };

  const currentMonth = new Date();
  const selectedDate = new Date(
    selectedMonthYear.year,
    selectedMonthYear.month
  );

  //multi select dropdown
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>(
    allCompanyIds ? allCompanyIds : []
  );
  const [selectedMarkets, setSelectedMarkets] = useState<any[]>(
    marketList ? marketList : []
  );

  const [selectedCompanyNames, setSelectedCompanyNames] = useState<number[]>(
    allCompanyCodes ? allCompanyCodes : []
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isMarketDropdownOpen, setIsMarketDropdownOpen] = useState(false);
  //close multi select drop-down on outside click
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRefMarket = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleCloseOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
      if (
        dropdownRefMarket?.current &&
        !dropdownRefMarket?.current.contains(event.target as Node)
      ) {
        setIsMarketDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleCloseOutside);
    return () => {
      document.removeEventListener("mousedown", handleCloseOutside);
    };
  }, [dropdownRef, dropdownRefMarket]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleMarketDropdown = () => {
    setIsMarketDropdownOpen(!isMarketDropdownOpen);
  };
  //handle company filter change
  const toggleCompany = (
    event: React.ChangeEvent<HTMLInputElement>,
    company: any
  ) => {
    handleFilterUpdateProgress();
    event.stopPropagation();

    const toggleCompanyIds = [];

    if (selectedCompanies.includes(company.companyId)) {
      setSelectedCompanies(
        selectedCompanies.filter((id) => id !== company.companyId)
      );
      setSelectedCompanyNames(
        selectedCompanyNames.filter((code) => code !== company.companyCode)
      );
      let companyIds = selectedCompanies.filter(
        (id) => id !== company.companyId
      );
      toggleCompanyIds.push(companyIds);
    } else {
      setSelectedCompanies([...selectedCompanies, company.companyId]);
      let companyIds = [...selectedCompanies, company.companyId];
      toggleCompanyIds.push(companyIds);
      setSelectedCompanyNames([...selectedCompanyNames, company.companyCode]);
    }

    fetchTBBDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: toggleCompanyIds,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
      market: selectedMarkets,
    });
  };
  //makret list handler
  const toggleMarket = (
    event: React.ChangeEvent<HTMLInputElement>,
    market: string
  ) => {
    handleFilterUpdateProgress();
    event.stopPropagation();
    const toggleMarkets = [];

    if (selectedMarkets?.includes(market)) {
      let markets = selectedMarkets?.filter((item: any) => item !== market);
      setSelectedMarkets(markets);
      toggleMarkets.push(markets);
    } else {
      let markets = [...selectedMarkets, market];
      toggleMarkets.push(markets);
      setSelectedMarkets(markets);
    }
    fetchTBBDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: selectedCompanies,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
      market: toggleMarkets,
    });
  };
  //select or deselect all the markets
  const toggleAllOptions = () => {
    handleFilterUpdateProgress();
    const toggleMarkets = [];
    if (selectedMarkets?.length) {
      setSelectedMarkets([]);
      toggleMarkets?.push("");
    } else {
      setSelectedMarkets(marketList!);
      toggleMarkets?.push(marketList);
    }
    fetchTBBDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: selectedCompanies,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
      market: toggleMarkets,
    });
  };
  //show selected company codes
  const renderCompanyCodes = () => {
    if (selectedCompanyNames?.length === 0) {
      return <span>Filter by Company</span>;
    } else {
      let displayContent = selectedCompanyNames.slice(0, 2).join(",");
      let badge = "";
      if (selectedCompanyNames?.length > 2) {
        badge = `+ <div className="company-count-circle">${
          selectedCompanyNames?.length - 2
        }</div>`;
      }

      if (selectedCompanyNames?.length === allCompanyIds?.length) {
        displayContent = "All Companies";
        badge = "";
      }
      return (
        <div
          className="filtered-companylist"
          dangerouslySetInnerHTML={{ __html: `${displayContent} ${badge}` }}
        ></div>
      );
    }
  };
  //modal funcionality
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [componentType, setComponentType] = useState("");
  const [modalSize, setModalSize] = useState<string>();
  //show modal
  const handleShowModal = (
    content: React.ReactNode,
    title: string,
    type: string,
    modalSize?: string
  ) => {
    setModalContent(content);
    setModalTitle(title);
    setComponentType(type);
    setShowModal(true);
    setModalSize(modalSize);
  };

  //close modal
  const handleCloseModal = () => setShowModal(false);

  //update progress value
  const handleFilterUpdateProgress = () => {
    setProgress(0);
    setDispalyProgress(false);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 10;

        if (newProgress >= 100) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 100);
  };
  if (loading) {
    return (
      <div className="global-loading d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  //Options for doughnut chart
  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = context.dataset.label || "";
            const value = context.raw as number;

            return `${label} ${formatNumberWithCommasDecimals(value * 1)}`;
          },
        },
      },
      legend: {
        display: true,
        position: "right" as const,

        labels: {
          boxWidth: 10,
          boxHeight: 10,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
    },
  };
  //show doughnut chart values
  const displayDoughnutChartValue = {
    id: "displayDoughnutChartValue",
    afterDatasetsDraw: (chart: any) => {
      const { ctx, data } = chart;
      ctx.save();
      data.datasets.forEach((dataset: any, i: number) => {
        const meta = chart?.getDatasetMeta(i);
        meta?.data?.forEach((datapoint: any, index: number) => {
          if (!meta.data[index].hidden) {
            const { x, y } = datapoint?.tooltipPosition();
            const value =
              dataset?.data[index] > 0
                ? formatNumberWithCommasDecimals(dataset?.data[index])
                : "";
            ctx.fillStyle = "#fff";
            ctx.textAlign = "center";
            ctx.textBaseLine = "middle";
            ctx.fillText(value, x, y);
          }
        });
      });
    },
  };

  return (
    <>
      <Row>
        <BreadCrumbComponent item="Total Booked Backlog Details" />
        <div
          className={`progress-bar-container ${
            dispalyProgress ? "show-progress" : ""
          }`}
        >
          <ProgressBar now={progress} />
        </div>
        <div className="mb-3"></div>
        <Col xs={12}>
          <Row className="align-items-center gx-3">
            <Col xs={12} md={3} lg={3} xl={2} className="mb-3">
              <h5 className="mb-0 details-heading animate__animated animate__bounce">
                Total Booked Backlog
              </h5>
            </Col>
            <Col xs={12} md={4} lg={4} xl={3} className="mb-3">
              {/* BU type pills */}
              <div className="pills">
                <div className="px-2 py-1 nav-fill border-1 nav nav-pills">
                  <div className="nav-item">
                    <a
                      onClick={() => handleMenuItemClick("BU")}
                      className={`nav-link ${
                        selectedPillItem === "BU" ? "active" : ""
                      } ${subBUList && subBUList?.length <= 1 ? "active" : ""}`}
                    >
                      Business Unit
                    </a>
                  </div>
                  <div
                    className={`nav-item ${
                      subBUList && subBUList?.length <= 1 ? "not-allowed" : ""
                    }`}
                  >
                    <a
                      onClick={() => handleMenuItemClick("SU")}
                      role="button"
                      className={`nav-link sub-bu ${
                        subBUList && subBUList?.length <= 1 ? "disabled" : ""
                      } ${
                        selectedPillItem === "SU" &&
                        subBUList &&
                        subBUList?.length > 1
                          ? "active"
                          : ""
                      }`}
                    >
                      Sub Business Unit
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            {selectedPillItem === "SU" && subBUList?.length! > 1 ? (
              <Col xs={6} md={3} lg={2} xl={2} className="mb-3">
                {/* Sub business unit dropdown */}
                <select
                  className="filter form-select"
                  value={subBUName}
                  onChange={(e) => handleSubBUChange(e.target.value)}
                >
                  {subBUList?.map((su: any) => (
                    <option value={su.subUnitId} key={su.subUnitId}>
                      {su.subUnitDesc}
                    </option>
                  ))}
                </select>
              </Col>
            ) : null}

            <Col
              xs={6}
              md={3}
              lg={3}
              xl={2}
              className={`mb-3 ${
                selectedPillItem === "SU" && subBUList?.length! > 1
                  ? ""
                  : "offset-xl-2"
              }`}
            >
              <OverlayTrigger
                overlay={<Tooltip>Select Company</Tooltip>}
                placement="top"
              >
                <div className="multiselect-dropdown float-end">
                  <div className="dropdown-header" onClick={toggleDropdown}>
                    {renderCompanyCodes()}

                    <span className="material-symbols-outlined">
                      expand_more
                    </span>
                  </div>
                  <div ref={dropdownRef} className="dropdown-options-parent">
                    {isOpen && (
                      <div className="dropdown-options">
                        {companyList?.map((company: any) => (
                          <label key={company.companyId}>
                            <input
                              type="checkbox"
                              value={company.companyId}
                              checked={selectedCompanies.includes(
                                company.companyId
                              )}
                              onChange={(e) => toggleCompany(e, company)}
                            />
                            &nbsp;{company.companyCode}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </OverlayTrigger>
            </Col>
            <Col xs={6} md={3} lg={3} xl={2} className="mb-3">
              <OverlayTrigger
                overlay={<Tooltip>Select Market</Tooltip>}
                placement="top"
              >
                <div className="multiselect-dropdown w-100">
                  <div
                    className="dropdown-header"
                    onClick={toggleMarketDropdown}
                  >
                    {selectedMarkets?.length === marketList?.length
                      ? "All Markets"
                      : "Market List"}
                    <span className="material-symbols-outlined">
                      expand_more
                    </span>
                  </div>
                  <div
                    ref={dropdownRefMarket}
                    className="dropdown-options-parent"
                  >
                    {isMarketDropdownOpen && (
                      <div className="dropdown-options">
                        <label>
                          <input
                            type="checkbox"
                            checked={
                              selectedMarkets?.length === marketList?.length
                            }
                            onChange={toggleAllOptions}
                          />
                          All Markets
                        </label>
                        {marketList?.map((market: any) => (
                          <label key={market}>
                            <input
                              type="checkbox"
                              value={market}
                              checked={selectedMarkets.includes(market)}
                              onChange={(e) => toggleMarket(e, market)}
                            />
                            &nbsp;{market}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </OverlayTrigger>
            </Col>
            <Col xs={6} md={2} lg={2} xl={1} className="mb-3 tbb-datepicker">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MMM/yyyy"
                maxDate={currentMonth}
                showMonthYearPicker
                wrapperClassName="datepicker"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="alert alert-info text-center p-2 gray" role="alert">
        Contract to date data
      </div>
      {tbbStatus === 200 &&
      tbbGraphStatus === 200 &&
      tbbResponse &&
      tbbGraphsResponse ? (
        <>
          <Row className="mb-3 g-2">
            <Col xs={12}>
              <div className="gp-parent-card-container key-highlights blue">
                <h3 className="semi-bold card-heading heading-uppercase mb-2">
                  Key Highlights
                </h3>

                <Row className="gx-3">
                  <Col xs={12} md={6} lg={6} xl={4} className="mb-3 mb-xl-0">
                    <div className="card-details">
                      <div className="card-details-header no-bg">
                        <h6 className="regular-text semi-bold mb-0">
                          3 Major Backlog Profit Delivery Methods
                        </h6>
                      </div>
                      <div className="card-details-body">
                        <div className="card-details-chart-tbb height-175">
                          {deliveryMethodsProfit ? (
                            <BarChart
                              chartData={deliveryMethodsProfit}
                              chartOptions="horizontalStackedBaroptionsSingle"
                              pluginsAvailable={true}
                              axisText1="Profit"
                              callBackData={deliveryMethodsProfitPer}
                            />
                          ) : (
                            <div className="no-data-alert">
                              <p className="text text-center">
                                No Data Available
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={4} className="mb-3 mb-xl-0">
                    <div className="card-details">
                      <div className="card-details-header no-bg">
                        <h6 className="regular-text semi-bold mb-0">
                          3 Major Backlog Profit COS Facilities
                        </h6>
                      </div>
                      <div className="card-details-body">
                        <div className="card-details-chart-tbb height-175">
                          {cosFacilitiesProfit ? (
                            <BarChart
                              chartData={cosFacilitiesProfit}
                              chartOptions="horizontalStackedBaroptionsSingle"
                              pluginsAvailable={true}
                              callBackData={cosFacilitiesProfitPer}
                              axisText1="Profit"
                            />
                          ) : (
                            <div className="no-data-alert">
                              <p className="text text-center">
                                No Data Available
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={4} className="mb-3 mb-xl-0">
                    <div className="card-details">
                      <div className="card-details-header no-bg">
                        <h6 className="regular-text semi-bold mb-0">
                          Backlog Profit Breakdown by Job Financial Status
                        </h6>
                      </div>
                      <div className="card-details-body">
                        <div className="card-details-chart-tbb height-175">
                          {tbbGraphsResponse?.Job_Financial_Status &&
                          isValidDataset(jobStatusChartData?.datasets) ? (
                            <Doughnut
                              data={jobStatusChartData}
                              options={doughnutChartOptions}
                              plugins={[displayDoughnutChartValue]}
                            />
                          ) : (
                            <div className="no-data-alert">
                              <p className="text text-center">
                                No Data Available
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col xs={12} md={6} xl={6} className="mb-3 height-300px">
              <div className="details-chart-container">
                <div className="chart-header">
                  <div className="left-content d-xl-flex align-items-center">
                    <h3 className="semi-bold mb-0 card-heading me-2">
                      Backlog Profit Breakdown by Sub BU
                    </h3>
                    <p className="body-text info-text">
                      All the values are in thousands
                    </p>
                  </div>

                  <div className="card-icons">
                    <span
                      className="expand-icon"
                      onClick={() =>
                        handleShowModal(
                          <BarChart
                            chartData={backlogProfitBreakdownbySubBU}
                            chartOptions="verticleBaroptions"
                            axisText1="Profit"
                            pluginsAvailable={true}
                            barValuePosition="top"
                            legendRequired={false}
                            callBackData={
                              backlogProfitBreakdownbySubBUProfitPer
                            }
                          />,
                          "Backlog Profit Breakdown by Sub BU",
                          "chart"
                        )
                      }
                    ></span>
                    <span className="move-icon"></span>
                  </div>
                </div>
                {backlogProfitBreakdownbySubBU ? (
                  <div className="chart-body">
                    <BarChart
                      chartData={backlogProfitBreakdownbySubBU}
                      chartOptions="verticleBaroptions"
                      axisText1="Profit"
                      pluginsAvailable={true}
                      barValuePosition="top"
                      legendRequired={false}
                      callBackData={backlogProfitBreakdownbySubBUProfitPer}
                    />
                  </div>
                ) : (
                  <div className="no-data-alert">
                    <p className="text text-center">No Data Available</p>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} md={6} xl={6} className="mb-3 height-300px">
              <div className="details-chart-container">
                <div className="chart-header">
                  <div className="left-content d-xl-flex align-items-center">
                    <h3 className="semi-bold mb-0 card-heading me-2">
                      Backlog Profit by Delivery Methods
                    </h3>
                    <p className="body-text info-text">
                      All the values are in thousands
                    </p>
                  </div>

                  <div className="card-icons">
                    <span
                      className="expand-icon"
                      onClick={() =>
                        handleShowModal(
                          <BarChart
                            chartData={backlogProfitbyDeliveryMethods}
                            chartOptions="multiTypeChartOptiosMultiAxis"
                            axisText1="Profit"
                            axisText2="Profit %"
                            pluginsAvailable={true}
                          />,
                          "Backlog Profit by Delivery Methods",
                          "chart"
                        )
                      }
                    ></span>
                    <span className="move-icon"></span>
                  </div>
                </div>
                {backlogProfitbyDeliveryMethods ? (
                  <div className="chart-body">
                    <BarChart
                      chartData={backlogProfitbyDeliveryMethods}
                      chartOptions="multiTypeChartOptiosMultiAxis"
                      axisText1="Profit"
                      axisText2="Profit %"
                      pluginsAvailable={true}
                    />
                  </div>
                ) : (
                  <div className="no-data-alert">
                    <p className="text text-center">No Data Available</p>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} className="mb-3 height-300px">
              <div className="details-chart-container">
                <div className="chart-header">
                  <div className="left-content d-xl-flex align-items-center">
                    <h3 className="semi-bold mb-0 card-heading me-2">
                      Rolling 12 Months Profit & Profit %
                    </h3>
                    <p className="body-text info-text">
                      All the values are in thousands
                    </p>
                  </div>

                  <div className="card-icons">
                    <span
                      className="expand-icon"
                      onClick={() =>
                        handleShowModal(
                          <BarChart
                            chartData={monthCardDataset}
                            chartOptions="multiTypeChartOptiosMultiAxis"
                            axisText1="Profit"
                            axisText2="Profit %"
                            pluginsAvailable={true}
                          />,
                          "Rolling 12 Months Profit & Profit %",
                          "chart"
                        )
                      }
                    ></span>
                    <span className="move-icon"></span>
                  </div>
                </div>
                {monthCardDataset ? (
                  <div className="chart-body overflow-x">
                    <div className="scroll-x">
                      <BarChart
                        chartData={monthCardDataset}
                        chartOptions="multiTypeChartOptiosMultiAxis"
                        axisText1="Profit"
                        axisText2="Profit %"
                        pluginsAvailable={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="no-data-alert">
                    <p className="text text-center">No Data Available</p>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} md={12} xl={12} className="mb-3 height-300px">
              <div className="details-chart-container">
                <div className="chart-header">
                  <div className="left-content d-xl-flex align-items-center">
                    <h3 className="semi-bold mb-0 card-heading me-2">
                      Backlog Profit Breakdown by COS Facilities
                    </h3>
                    <p className="body-text info-text">
                      All the values are in thousands
                    </p>
                  </div>

                  <div className="card-icons">
                    <span
                      className="expand-icon"
                      onClick={() =>
                        handleShowModal(
                          <div
                            className="width-100"
                            style={{
                              height: cardHeightFacility,
                            }}
                          >
                            <BarChart
                              chartData={profitbyCOSChartData}
                              chartOptions="horizontalStackedBaroptionsSingle"
                              pluginsAvailable={true}
                              callBackData={profitbyCOSProfitPer}
                              axisText1="Profit"
                              labelFontSize={10}
                            />
                          </div>,
                          "Backlog Profit Breakdown by COS Facilities",
                          "chart",
                          "xl"
                        )
                      }
                    ></span>
                    <span className="move-icon"></span>
                  </div>
                </div>
                {profitbyCOSChartData ? (
                  <div className="chart-body overflow">
                    <div
                      className="width-100"
                      style={{
                        height: cardHeightFacility,
                      }}
                    >
                      <BarChart
                        chartData={profitbyCOSChartData}
                        chartOptions="horizontalStackedBaroptionsSingle"
                        pluginsAvailable={true}
                        callBackData={profitbyCOSProfitPer}
                        axisText1="Profit"
                        labelFontSize={10}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="no-data-alert">
                    <p className="text text-center">No Data Available</p>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} md={12} xl={12} className="mb-3 height-300px">
              <div className="details-chart-container">
                <div className="chart-header">
                  <div className="left-content d-xl-flex align-items-center">
                    <h3 className="semi-bold mb-0 card-heading me-2">
                      More and Less Backlog Profit
                    </h3>
                    <p className="body-text info-text">
                      All the values are in thousands
                    </p>
                  </div>

                  <div className="card-icons">
                    <span
                      className="expand-icon"
                      onClick={() =>
                        handleShowModal(
                          <div
                            className="width-100"
                            style={{
                              height: cardHeightContract,
                            }}
                          >
                            <BarChart
                              chartData={contractsChartData}
                              chartOptions="horizontalStackedBaroptionsSingle"
                              pluginsAvailable={true}
                              callBackData={contractsProfitPer}
                              axisText1="Profit"
                            />
                          </div>,
                          "More and Less Backlog Profit",
                          "chart",
                          "xl"
                        )
                      }
                    ></span>
                    <span className="move-icon"></span>
                  </div>
                </div>
                {contractsChartData ? (
                  <div className="chart-body overflow">
                    <div
                      className="width-100"
                      style={{
                        height: cardHeightContract,
                      }}
                    >
                      <BarChart
                        chartData={contractsChartData}
                        chartOptions="horizontalStackedBaroptionsSingle"
                        pluginsAvailable={true}
                        callBackData={contractsProfitPer}
                        axisText1="Profit"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="no-data-alert">
                    <p className="text text-center">No Data Available</p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="h-100vh">
          <Col xs={12}>
            <Alert variant="warning">
              <Alert.Heading>No Data Available!</Alert.Heading>
              {selectedCompanies?.length > 0 && selectedMarkets?.length > 0 ? (
                <p>For the selected filters, Please modify the filters</p>
              ) : selectedMarkets?.length === 0 ? (
                <p>Please select at least one market</p>
              ) : (
                <p>Please select at least one company</p>
              )}
            </Alert>
          </Col>
        </Row>
      )}
      {/* Generic Modal Component will send the data onClick */}
      <ModalComponent
        show={showModal}
        onHide={handleCloseModal}
        modalTitle={modalTitle}
        bodyContent={modalContent}
        contentType={componentType}
        thousandsText={true}
        modalSize={modalSize}
      />
    </>
  );
};
