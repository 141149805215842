import { Table } from "react-bootstrap";
import "./ComparisionTable.scss";
interface TableProps {
  tableData: any;
  tableDataHighlights: any;
}
export const ComparisionTable: React.FC<TableProps> = ({
  tableData,
  tableDataHighlights,
}) => {
  //formatNumberWithCommas
  const formatNumberWithCommas = (value: any): string => {
    if (typeof value !== "number" || isNaN(value)) {
      return value;
    }
    return new Intl.NumberFormat("en-US").format(value);
  };
  return tableData?.length > 0 ? (
    <Table hover responsive>
      <thead>
        <tr>
          <th>Flash Row Description</th>
          <th>Actual Amount</th>
          <th>Plan Amount</th>
          <th>Variance Amount</th>
          <th>Variance %</th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((data: any, index: any) => (
          <tr key={index}>
            <td>{data.flashDesc}</td>
            <td>
              {data.actAmount < 0
                ? `(${formatNumberWithCommas(data.actAmount * -1)})`
                : formatNumberWithCommas(data.actAmount)}
            </td>
            <td>
              {data.planAmount < 0
                ? `(${formatNumberWithCommas(data.planAmount * -1)})`
                : formatNumberWithCommas(data.planAmount)}
              <span className="circle-width"></span>
            </td>
            <td>
              {data.varAmount < 0
                ? `(${formatNumberWithCommas(data.varAmount * -1)})`
                : formatNumberWithCommas(data.varAmount)}
            </td>

            <td>
              <span>
                {data.varPer < 0
                  ? `(${(data.varPer * -1).toFixed(2)}%)`
                  : `${data.varPer?.toFixed(2)}%`}
              </span>
              <span
                className={`${
                  data.colorType === "Green" ? "green-circle" : "red-circle"
                }`}
              ></span>
            </td>
          </tr>
        ))}

        {tableDataHighlights?.length > 0
          ? tableDataHighlights?.map((data: any, index: any) => (
              <tr className="highlights-row" key={index}>
                <td>{data.flashDesc}</td>
                <td>
                  {data.actAmount < 0
                    ? `(${(data.actAmount * -1).toFixed(2)}%)`
                    : `${data.actAmount?.toFixed(2)}%`}
                </td>
                <td>
                  {data.planAmount < 0
                    ? `(${(data.planAmount * -1).toFixed(2)}%)`
                    : `${data.planAmount?.toFixed(2)}%`}

                  <span
                    className={`${
                      data.colorType === "Green" ? "green-circle" : "red-circle"
                    }`}
                  ></span>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            ))
          : null}
      </tbody>
    </Table>
  ) : (
    <div className="no-data-alert">
      <p className="text">No Data Available</p>
    </div>
  );
};
