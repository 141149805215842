import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface progressState{
    progress: number | undefined;
    displayProgress: boolean | null;
}

const initialState:progressState = {
    progress: 0,
    displayProgress: true,
}
const progressSlice = createSlice({
    name: 'progress',
    initialState,
    reducers:{
        setProgress:(state, action: PayloadAction<number|undefined>)=>{
            state.progress = action.payload;
        },
        setDisplayProgress:(state, action: PayloadAction<boolean|null>)=>{
            state.displayProgress = action.payload;
        }
    }
})

export const {setProgress,setDisplayProgress} = progressSlice.actions;
export default progressSlice.reducer;