import "./CommonKPICard.scss";
import { useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Legend,
  LineElement,
  BarElement,
} from "chart.js";

//Tooltip imports
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Line, Pie, Doughnut, Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,

  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        font: {
          size: 11,
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 11,
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

//options for doughnut chart
const optionsDoughnut = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        display: false,
        beginAtZero: true,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    x: {
      ticks: {
        display: false,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};
interface KPICardProps {
  // index: number;
  cardData: any;
  //heading:string,
  isActive: boolean;
  onClick: () => void;
}
export const KPICard: React.FC<KPICardProps> = ({
  cardData,
  isActive,
  onClick,
}) => {
  //Flip card
  const [isFlipped, setIsFlipped] = useState(false);
  const handleFlipClick = () => {
    //setIsFlipped(!isFlipped);
  };
  // const handleCardClick = () => {
  //   //onClick(index)
  // };
  const data = {
    labels: [2018, 2020, 2022, 2023, 2024, 2026],
    datasets: [
      {
        data: [0, 0.2, 0.4, 1, 1.2, 0.3],
        backgroundColor: "#24282b",
        borderColor: "#59656e",
        fill: false,
      },
    ],
  };

  //Tooltip
  const tooltip = (
    <Tooltip id="tooltip">
      {cardData.expCurrentValue
        ? `Expectation > ${cardData.expCurrentValue}`
        : `Expectation > ${cardData.currentValue}`}
    </Tooltip>
  );

  
  return (
    <div
      className={`card ${isFlipped ? "flipped" : ""} ${
        isActive ? "active" : ""
      }`}
      //onClick={onClick}
    >
      <div className="disable-overlay"></div>
      {/* Front side of the card */}
      <div className="front">
        <Row className="g-0">
          <Col xs={11}>
            <h4 className="heading semi-bold">
              <Link to={`/${cardData.link}`} className="heading-anchor">
                {cardData.heading}
              </Link>
            </h4>
          </Col>
          <Col xs={1}>
            <span
              className="bulb-icon float-end"
              onClick={handleFlipClick}
            ></span>
          </Col>
        </Row>
        <Row className="g-0">
          <Col xs={6}>
            {cardData.marginValue ? (
              <h3 className="revenue">${cardData.marginValue}</h3>
            ) : (
              ""
            )}
            <Row className="g-0">
              <Col xs={6}>
                {cardData.currentValue ? (
                  <p className="current">Current</p>
                ) : (
                  ""
                )}
                <OverlayTrigger placement="bottom" overlay={tooltip}>
                  <Badge bg="danger" className="badge-blue semi-bold">
                    {cardData.currentValue ? (
                      <span className="material-symbols-outlined">
                        arrow_upward
                      </span>
                    ) : (
                      ""
                    )}

                    {cardData.currentValue}
                  </Badge>
                </OverlayTrigger>
              </Col>
              <Col xs={6}>
                {cardData.topPerfomerValue ? (
                  <p className="top-performer">Top Performer</p>
                ) : (
                  ""
                )}
                <Badge bg="danger" className="badge-blue semi-bold">
                  {cardData.topPerfomerValue}
                </Badge>
              </Col>
            </Row>
            {cardData.aspirationalValue ? (
              <p className="aspirational">Aspirational</p>
            ) : (
              ""
            )}
            <p className="subtitle-percentage">{cardData.aspirationalValue}</p>
          </Col>
          <Col xs={6}>
            <div className="chart-container">
              {cardData.chartType === "line" && (
                <Line data={cardData.chartData} options={options} />
              )}
              {cardData.chartType === "pie" && (
                <Pie data={cardData.chartData} options={options} />
              )}
              {cardData.chartType === "doughnut" && (
                <Doughnut data={cardData.chartData} options={optionsDoughnut} />
              )}
              {cardData.chartType === "bar" && (
                <Bar data={cardData.chartData} options={options} />
              )}
            </div>
          </Col>
        </Row>
      </div>

      
    </div>
  );
};
