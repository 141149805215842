import axios from "axios";
import "./VoluntaryDetails.scss";
import { useEffect, useRef, useState } from "react";
//Drag and drop imports
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import DatePicker from "react-datepicker";
//user details import
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
//React chart imports
import {
  Breadcrumb,
  Col,
  Modal,
  Nav,
  OverlayTrigger,
  Tooltip,
  Row,
  Spinner,
  Alert,
  ProgressBar,
} from "react-bootstrap";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  LineController,
  BarController,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  setBUType,
  setSubBUId,
  setSelectedMonthYear,
} from "../../redux/userSlice";
import { Link } from "react-router-dom";
import { KeyHilightsVol } from "./GenericComponents/KeyHighlightsVol";
import { BarChart } from "./GenericComponents/Charts/BarChart";
import { graphColors } from "../../utils/graphColors";
import { monthsMap } from "../Dashboard/Content/KPIcards/MonthNames";
import { BreadCrumbComponent } from "./GenericComponents/BreadcrumbComponent";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  LineController,
  BarController
);

//Options for doughnut chart
const doughnutChartOptions = {
  plugins: {
    legend: {
      display: true,
      position: "bottom" as const,
      labels: {
        boxWidth: 8,
        boxHeight: 8,
        padding: 5,

        font: {
          size: 12,
        },
      },
    },
  },
};
//interface for draggable Card and graph data
interface CardItem {
  id: string;
  modalId: string;
  heading: string;
  dataAvailable: boolean;
  chartData: any;
  chartOptions: any;
  axisText1?: string;
  axisText2?: string;
  colSize: string;
  chartType: string;
  plugins?: boolean;
  decimals?: number;
  gridRequired?: boolean;
  callBackData?: any;
  callBackData2?: any;
  callBackData3?: any;
  callBackData4?: any;
  callBackData5?: any;
  callBackData6?: any;
}
export const VoluntaryDetails = () => {
  //fetch user details
  const token: any = useSelector((state: RootState) => state.auth.token);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const bussUnit = useSelector((state: RootState) => state.user.bussUnit);
  const theme = useSelector((state: RootState) => state.card.darkTheme);
  const buType = useSelector((state: RootState) => state.user.buType);
  const subBUList = useSelector((state: RootState) => state.user.subUnitList);
  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const selectedDateType = useSelector(
    (state: RootState) => state.user.selectedDateType
  );
  const selectedMonthYear = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );
  const dispatch = useDispatch();
  const [progress, setProgress] = useState<number>(0);
  const [dispalyProgress, setDispalyProgress] = useState<boolean>(true);

  const fetchCardPositionFilters = {
    email: user?.email,
    kpi: "Voluntary",
  };
  //payload filters
  const [dateType, setDateType] = useState<string>("Y");

  //current month
  const currenMonth = new Date();
  const selectedDate = new Date(
    selectedMonthYear.year,
    selectedMonthYear.month
  );
  const [subBUName, setSubBUName] = useState<any>();
  //Date type drop-down functionality
  const handleDateTypeChange = (value: string) => {
    handleFilterUpdateProgress();
    setDateType(value);
    const stringFilters = selectedFilters?.map((filter) => filter).join(",");
    fetchVoluntaryDetailsData({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      dateType: value,
      filter: stringFilters,
      category: selectedCategory,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUFirstId : bussUnit,
    });
  };
  //category change functionality
  const handleCategoryChange = (category: any) => {
    handleFilterUpdateProgress();
    setCategory(category);
    const stringFilters = selectedFilters?.map((filter) => filter).join(",");
    fetchVoluntaryDetailsData({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      dateType: dateType,
      filter: stringFilters,
      category: category,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUFirstId : bussUnit,
    });
  };
  //Datepicker functionality
  const handleDateChange = (date: Date | null) => {
    handleFilterUpdateProgress();

    if (date) {
      const month = date.getMonth();
      const year = date!.getFullYear();
      dispatch(
        setSelectedMonthYear({
          month,
          year,
        })
      );
    }
    const stringFilters = selectedFilters?.map((filter) => filter).join(",");
    fetchVoluntaryDetailsData({
      month: date?.getMonth(),
      year: date?.getFullYear(),
      dateType: dateType,
      filter: stringFilters,
      category: selectedCategory,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUFirstId : bussUnit,
    });
  };
  //store response
  const [vtResponse, setVTResponse] = useState<any>(null);
  const [vtStatus, setVTStatus] = useState<any>(null);
  //set loading state
  const [loading, setLoading] = useState(true);
  const [alertShow, setAlertShow] = useState<boolean>(true);

  //modal funcionality
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCardId, setSelectedCardId] = useState<string>("");
  const handleClose = () => {
    setShowModal(false);
  };

  const handleShowModal = (cardId: string) => {
    setSelectedCardId(cardId);
    setShowModal(true);
  };

  //card data array
  const [initialCards, setInitialCards] = useState<CardItem[]>([]);
  const [cards, setCards] = useState<CardItem[]>([]);
  //store details data
  const [
    voluntaryPageHighLightsDataActiveEmpCount,
    setVoluntaryPageHighLightsDataActiveEmpCount,
  ] = useState<any>(null);
  const [
    voluntaryPageHighLightsDataTurnoverRate,
    setVoluntaryPageHighLightsDataTurnoverRate,
  ] = useState<any>(null);
  const [highLowPerformancewise, setHighLowPerformancewise] =
    useState<any>(null);
  const [highLowCareerwise, setHighLowCareerwise] = useState<any>(null);
  const [highLowUnitwise, setHighLowUnitwise] = useState<any>(null);

  const [saveCardPositionRes, setSaveCardPositionRes] = useState<any>(null);

  useEffect(() => {
    handleFilterUpdateProgress();
    const stringFilters = selectedFilters?.map((filter) => filter).join(",");
    fetchVoluntaryDetailsData({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      dateType: selectedDateType,
      filter: stringFilters,
      category: selectedCategory,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
    setSelectedPillItem(buType ? buType : selectedPillItem);
    setSubBUName(subBUId);
    setDateType(selectedDateType);
  }, [subBUFirstId]);
  useEffect(() => {
    if (
      fetchCardPositionFilters.email !== null &&
      fetchCardPositionFilters.email !== undefined
    ) {
      fetchCardPosition();
    }
  }, [vtResponse]);

  const fetchCardPosition = async () => {
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/fetchUserPersonalizationData`,
        fetchCardPositionFilters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const cardIds = res.data.data?.Details?.cardPosition?.split(",");
        const initialCardIDs = initialCards?.map((card) => card.id);
        const defaultORUpdatedCardIds = cardIds
          ? cardIds?.length === initialCardIDs?.length
            ? cardIds
            : initialCardIDs
          : initialCardIDs;
        const updatedCards = defaultORUpdatedCardIds?.map((id: any) => {
          const matchingCard = cards?.find((card) => card?.id === id);

          return matchingCard ? { ...matchingCard } : null;
        });

        setCards([...updatedCards]);
      });
  };
  const fetchVoluntaryDetailsData = async (payload?: any) => {
    setProgress(0);
    const {
      month,
      year,
      dateType,
      filter,
      category,
      selectedBU,
      selectedbussUnit,
    } = payload || {};
    const currentMonth = month !== undefined ? month + 1 : undefined;
    const typeofBU = selectedBU ? selectedBU : buType;
    const bussUnitID = selectedbussUnit
      ? selectedbussUnit
      : typeofBU === "SU"
      ? subBUId
      : bussUnit;

    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/voluntaryPageDetails`,
        {
          dateFilter: dateType || "Y",
          type: typeofBU || "BU",
          unitId: bussUnitID || user?.bussUnit,
          month: currentMonth,
          year: year,
          filter: filter?.length == 0 ? "" : filter || "Vol,Inv,Other",
          category: category || "top talent,others",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setVTStatus(res.data?.status);
        setVTResponse(res.data?.data?.Map);
        setLoading(false);
        //1 Employee count card
        const countCardData = res.data?.data?.Map?.countcard;
        setVoluntaryPageHighLightsDataActiveEmpCount(countCardData);

        //2 Turnover rate card
        const turnoverrate = res.data?.data?.Map?.turnoverrate;
        const filteredTurnoverRate =
          turnoverrate?.length >= 0 ? turnoverrate[0] : "";
        setVoluntaryPageHighLightsDataTurnoverRate(filteredTurnoverRate);

        //3 Performance Category Turnover Rate
        const csodtrunover = res.data?.data?.Map?.topcsod;
        setHighLowPerformancewise(csodtrunover);

        //4 High & Low Career Turnover Rate
        const careerWiseTurnOver = res.data?.data?.Map?.topcareer;
        setHighLowCareerwise(careerWiseTurnOver);

        //5 High & Low Risk Turnover Rate
        // const riskWiseTurnOver = res.data?.data?.Map?.toprisk;
        // setHighLowRiskwise(riskWiseTurnOver);

        //6 High & Low SUB BU Turnover Rate
        const unitWiseTurnOver = res.data?.data?.Map?.topsubbu;
        setHighLowUnitwise(unitWiseTurnOver);

        //Graphs
        //7 Turnover Rate Breakdown by CSOD
        const volCsodRateCard = res.data?.data?.Map?.csodratecard;
        //labels
        const volCsodRateCardLabels = volCsodRateCard?.map(
          (obj: volCsodRateCardObject) => obj?.description
        );
        //data
        const volCsodRateCardData = volCsodRateCard?.map(
          (obj: volCsodRateCardObject) => obj?.turnoverRate?.toFixed(2)
        );
        // //formatted details
        // const formattedVolCsodRateCard = {
        //   labels: volCsodRateCardLabels,
        //   datasets: [
        //     {
        //       data: volCsodRateCardData,
        //       backgroundColor: graphColors.yellow,
        //       borderColor: graphColors.yellow,
        //       borderWidth: 1.5,
        //       pointBorderColor: "#fff",
        //       pointRadius: 3,
        //       pointSize: 2,
        //       fill: false,
        //     },
        //   ],
        // };

        //8 Turnover Rate Breakdown by Risk
        const volRiskRateCard = res.data?.data?.Map?.toprisk;
        //labels
        const volRiskRateCardLabels = volRiskRateCard?.map(
          (obj: volCsodRateCardObject) => obj?.description
        );
        //data
        const volRiskRateCardData = volRiskRateCard?.map(
          (obj: volCsodRateCardObject) => obj?.turnoverRate
        );

        //formatted details
        const formattedVolBreakdownByRisk = {
          labels: volRiskRateCardLabels,
          datasets: [
            {
              //label:"Turnover Rate",
              data: volRiskRateCardData,
              backgroundColor: function (context: any) {
                const colors: string[] = [];
                volRiskRateCardLabels?.forEach((label: any, index: any) => {
                  if (label === "High") {
                    colors.push(graphColors.gray);
                  } else if (label === "Medium") {
                    colors.push(graphColors.yellow);
                  } else if (label === "Low") {
                    colors.push(graphColors.blue);
                  } else if (label === "Not Scored") {
                    colors.push(graphColors.red);
                  }
                });
                return colors;
              },
              borderColor: "#fff",
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
            },
          ],
        };

        //10 Turnover Rate Breakdown by Career
        const volCareerRateCard = res.data?.data?.Map.topcareer;
        //labels
        const volCareerRateCardLabels = volCareerRateCard?.map(
          (obj: volCsodRateCardObject) => obj?.description
        );
        //data turnover rate
        const volCareerRateCardDataTurnover = volCareerRateCard?.map(
          (obj: volCsodRateCardObject) => obj?.turnoverRate
        );
        const volCareerRateCardDataLeftCount = volCareerRateCard?.map(
          (obj: volCsodRateCardObject) => obj?.leftCount
        );
        const volCareerRateCardDataActiveCount = volCareerRateCard?.map(
          (obj: volCsodRateCardObject) => obj?.avgActiveCount
        );
        //formatted details
        const formattedVolCareerRateCard = {
          labels: volCareerRateCardLabels,
          datasets: [
            {
              label: "Turnover Rate",
              data: volCareerRateCardDataTurnover,
              backgroundColor: graphColors.blue,
              borderColor: graphColors.blue,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
            },
          ],
        };

        //9 Employee Count Breakdown by Career and Status
        const volCareerCountCard = res.data?.data?.Map?.careercountcard;
        const volCareerCountCardactiveEmpCount: number[] = [];
        const volCareerCountCardteminatedEmpCount: number[] = [];
        const volCareerCountCardRetiredEmpCount: number[] = [];
        const volCareerCountCardDeceasedEmpCount: number[] = [];
        const descritptions: string[] = [];

        volCareerCountCard?.forEach((employee: any) => {
          const { empStatus, description, empCount } = employee;
          if (!descritptions.includes(description)) {
            descritptions.push(description);
          }
          if (empStatus === "Active") {
            const index = descritptions.indexOf(description);
            if (volCareerCountCardactiveEmpCount[index]) {
              volCareerCountCardactiveEmpCount[index] += empCount;
            } else {
              volCareerCountCardactiveEmpCount[index] = empCount;
            }
          } else if (empStatus === "Terminated") {
            const index = descritptions.indexOf(description);
            if (volCareerCountCardteminatedEmpCount[index]) {
              volCareerCountCardteminatedEmpCount[index] += empCount;
            } else {
              volCareerCountCardteminatedEmpCount[index] = empCount;
            }
          } else if (empStatus === "Retired") {
            const index = descritptions.indexOf(description);
            if (volCareerCountCardRetiredEmpCount[index]) {
              volCareerCountCardRetiredEmpCount[index] += empCount;
            } else {
              volCareerCountCardRetiredEmpCount[index] = empCount;
            }
          } else if (empStatus === "Deceased") {
            const index = descritptions.indexOf(description);
            if (volCareerCountCardDeceasedEmpCount[index]) {
              volCareerCountCardDeceasedEmpCount[index] += empCount;
            } else {
              volCareerCountCardDeceasedEmpCount[index] = empCount;
            }
          }
        });

        //formatted details
        const formattedVolCareerCountCard = {
          labels: descritptions,
          datasets: [
            {
              type: "line",
              label: "Active",
              data: volCareerCountCardactiveEmpCount,
              backgroundColor: graphColors.green,
              borderColor: graphColors.green,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
              yAxisID: "y",
            },
            {
              type: "bar",
              label: "Terminated",
              data: volCareerCountCardteminatedEmpCount,
              backgroundColor: graphColors.red,
              borderColor: graphColors.red,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
              yAxisID: "y1",
            },
            {
              type: "bar",
              label: "Retired",
              data: volCareerCountCardRetiredEmpCount,
              backgroundColor: graphColors.yellow,
              borderColor: graphColors.yellow,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
              yAxisID: "y1",
            },
            {
              type: "bar",
              label: "Deceased",
              data: volCareerCountCardDeceasedEmpCount,
              backgroundColor: graphColors.gray,
              borderColor: graphColors.gray,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
              yAxisID: "y1",
            },
          ],
        };

        //11 Employee Count Breakdown by Month and Status
        const activeEmpCount: number[] = [];
        const teminatedEmpCount: number[] = [];
        const retiredEmpCount: number[] = [];
        const deceasedEmpCount: number[] = [];
        const dateCountLabels: any[] = [];
        const volMonthCountCard =
          dateType === "Q"
            ? res.data?.data?.Map?.datecountcard
            : dateType === "M"
            ? res.data?.data?.Map?.datecountcard
            : res.data?.data?.Map?.datecard;
        const monthCountCortedObjectsWithMonth = volMonthCountCard?.sort(
          dateType === "M"
            ? (a: any, b: any) => monthsMap[a.month] - monthsMap[b.month]
            : (a: any, b: any) => a.year - b.year
        );
        monthCountCortedObjectsWithMonth?.forEach((item: any) => {
          const { empStatus, month, year, quarter, empCount } = item;
          let label: any;
          if (month !== null) {
            label = month;
          } else if (quarter !== null) {
            label = year + " Q" + quarter;
          } else {
            label = year;
          }
          //update labels array
          if (!dateCountLabels.includes(label)) {
            dateCountLabels.push(label);
          }
          //console.log("emp count label", label);
          //Update counts arrays based on empStatus
          const index = dateCountLabels.indexOf(label);

          if (empStatus === "Active") {
            if (activeEmpCount[index]) {
              activeEmpCount[index] += empCount;
            } else {
              activeEmpCount[index] = empCount;
            }
          } else if (empStatus === "Terminated") {
            if (teminatedEmpCount[index]) {
              teminatedEmpCount[index] += empCount;
            } else {
              teminatedEmpCount[index] = empCount;
            }
          } else if (empStatus === "Retired") {
            if (retiredEmpCount[index]) {
              retiredEmpCount[index] += empCount;
            } else {
              retiredEmpCount[index] = empCount;
            }
          } else if (empStatus === "Deceased") {
            if (deceasedEmpCount[index]) {
              deceasedEmpCount[index] += empCount;
            } else {
              deceasedEmpCount[index] = empCount;
            }
          }
        });
        const formattedVolMonthCountCard = {
          labels: dateCountLabels,
          datasets: [
            {
              type: "line" as const,
              label: "Active",
              data: activeEmpCount,
              backgroundColor: graphColors.green,
              borderColor: graphColors.green,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
              yAxisID: "y",
            },
            {
              type: "bar" as const,
              label: "Terminated",
              data: teminatedEmpCount,
              backgroundColor: graphColors.red,
              borderColor: graphColors.red,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
              yAxisID: "y1",
            },
            {
              type: "bar" as const,
              label: "Retired",
              data: retiredEmpCount,
              backgroundColor: graphColors.yellow,
              borderColor: graphColors.yellow,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
              yAxisID: "y1",
            },
            {
              type: "bar" as const,
              label: "Deceased",
              data: deceasedEmpCount,
              backgroundColor: graphColors.gray,
              borderColor: graphColors.gray,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
              yAxisID: "y1",
            },
          ],
        };

        //12 Turnover Rate Breakdown by Month
        const volMonthRateCard = res.data?.data?.Map?.dateratecard;
        let volMonthRateCardLabels: any[] = [];
        const volMonthRateCardDataTurnover: number[] = [];
        const volMonthRateCardDataActiveCount: number[] = [];
        const volMonthRateCardDataLeftCount: number[] = [];
        const volMonthRateCardLineData: number[] = [];
        const volMonthRateCardLineDataActive: number[] = [];
        const volMonthRateCardLineDataLeft: number[] = [];
        const sortedvolMonthRateCard = volMonthRateCard?.sort(
          dateType === "M"
            ? (a: any, b: any) => monthsMap[a.month] - monthsMap[b.month]
            : (a: any, b: any) => a.year - b.year
        );
        sortedvolMonthRateCard?.forEach((item: any) => {
          const {
            turnoverRate,
            avgActiveCount,
            leftCount,
            cumulativeRate,
            cumulativeAvgActiveCount,
            cumulativeLeftCount,
            month,
            year,
            quarter,
          } = item;
          let label: any;
          if (month !== null) {
            label = month;
          } else if (quarter !== null) {
            label = year + " Q" + quarter;
          } else {
            label = year;
          }
          //update labels array
          if (!volMonthRateCardLabels.includes(label)) {
            volMonthRateCardLabels.push(label);
          }
          const index = volMonthRateCardLabels.indexOf(label);
          if (volMonthRateCardDataTurnover[index]) {
            volMonthRateCardDataTurnover[index] += turnoverRate;
          } else {
            volMonthRateCardDataTurnover[index] = turnoverRate;
          }
          if (volMonthRateCardDataActiveCount[index]) {
            volMonthRateCardDataActiveCount[index] += avgActiveCount;
          } else {
            volMonthRateCardDataActiveCount[index] = avgActiveCount;
          }
          if (volMonthRateCardDataLeftCount[index]) {
            volMonthRateCardDataLeftCount[index] += leftCount;
          } else {
            volMonthRateCardDataLeftCount[index] = leftCount;
          }
          if (volMonthRateCardLineData[index]) {
            volMonthRateCardLineData[index] += cumulativeRate;
          } else {
            volMonthRateCardLineData[index] = cumulativeRate;
          }
          if (volMonthRateCardLineDataActive[index]) {
            volMonthRateCardLineDataActive[index] +=
              cumulativeAvgActiveCount?.toFixed(0);
          } else {
            volMonthRateCardLineDataActive[index] =
              cumulativeAvgActiveCount?.toFixed(0);
          }
          if (volMonthRateCardLineDataLeft[index]) {
            volMonthRateCardLineDataLeft[index] +=
              cumulativeLeftCount?.toFixed(0);
          } else {
            volMonthRateCardLineDataLeft[index] =
              cumulativeLeftCount?.toFixed(0);
          }
        });

        //formatted details
        const formattedVolTurnoverRateBreakdownMonth = {
          labels: volMonthRateCardLabels,
          datasets: [
            {
              type: "line" as const,
              label: "Cumulative Turnover Rate",
              data: volMonthRateCardLineData,
              backgroundColor: graphColors.red,
              borderColor: graphColors.red,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
            },
            {
              type: "bar" as const,
              label: "Turnover Rate",
              data: volMonthRateCardDataTurnover,
              backgroundColor: graphColors.green,
              borderColor: graphColors.green,
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
            },
          ],
        };

        const initialCards: CardItem[] = [
          // {
          //   id: "card1",
          //   modalId: "showTRBreakdownByCSOD",
          //   heading: "Turnover Rate Breakdown by CSOD",
          //   dataAvailable: res.data?.data?.Map?.csodratecard ? true : false,
          //   chartData: formattedVolCsodRateCard,
          //   chartOptions: "multiTypeChartOptions",
          //   colSize: "col-md-7 col-xl-8",
          //   chartType: "bar",
          // },
          // {
          //   id: "card2",
          //   modalId: "showTRBreakdownByRisk",
          //   heading: "Turnover Rate Breakdown by Risk",
          //   dataAvailable: res.data?.data?.Map?.toprisk ? true : false,
          //   chartData: formattedVolBreakdownByRisk,
          //   chartOptions: doughnutChartOptions,
          //   colSize: "col-md-5 col-xl-4",
          //   chartType: "doughnut",
          // },
          {
            id: "card3",
            modalId: "showEmpCountBreakdownByCareerStatus",
            heading: "Employee Count Breakdown by Career and Status",
            dataAvailable: res.data?.data?.Map?.careercountcard ? true : false,
            chartData: formattedVolCareerCountCard,
            chartOptions: "multiTypeChartOptiosMultiAxis",
            axisText1: "Active Count",
            axisText2: "Terminated, Retired & Deceased Count",
            colSize: "col-md-6",
            chartType: "bar",
            plugins: true,
          },
          {
            id: "card4",
            modalId: "showTRBreakdownByCareer",
            heading: "Turnover Rate Breakdown by Career",
            dataAvailable: res.data?.data?.Map?.topcareer ? true : false,
            chartData: formattedVolCareerRateCard,
            callBackData: volCareerRateCardDataActiveCount,
            callBackData2: volCareerRateCardDataLeftCount,
            callBackData3: volCareerRateCardDataTurnover,
            chartOptions: "verticleBaroptionsVol",
            axisText1: "Turnover Rate (%)",
            colSize: "col-md-6",
            chartType: "bar",
            plugins: true,
            decimals: 1,
            gridRequired: false,
          },
          {
            id: "card5",
            modalId: "showTRBreakdownByCareer",
            heading: `Employee Count Breakdown by ${
              dateType === "M" ? "Month" : dateType === "Q" ? "Quarter" : "Year"
            } and Status`,
            dataAvailable: volMonthCountCard ? true : false,
            chartData: formattedVolMonthCountCard,
            chartOptions: "multiTypeChartOptiosMultiAxis",
            axisText1: "Active Count",
            axisText2: "Terminated, Retired Count",
            colSize: "col-md-6",
            chartType: "bar",
            plugins: true,
          },
          {
            id: "card6",
            modalId: "showTRBreakdownByMonth",
            heading: `Turnover Rate Breakdown by ${
              dateType === "M" ? "Month" : dateType === "Q" ? "Quarter" : "Year"
            }`,
            dataAvailable: res.data?.data?.Map?.dateratecard ? true : false,
            chartData: formattedVolTurnoverRateBreakdownMonth,
            callBackData: volMonthRateCardDataActiveCount,
            callBackData2: volMonthRateCardDataLeftCount,
            callBackData3: volMonthRateCardDataTurnover,
            callBackData4: volMonthRateCardLineDataActive,
            callBackData5: volMonthRateCardLineDataLeft,
            callBackData6: volMonthRateCardLineData,
            chartOptions: "verticleBaroptionsVol",
            axisText1: "Turnover Rate (%)",
            colSize: "col-md-6",
            chartType: "bar",
            plugins: true,
            decimals: 1,
            gridRequired: false,
          },
        ];
        setCards(initialCards);
        setInitialCards(initialCards);
        setProgress(100);
        setDispalyProgress(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data", error);
        setVTStatus(error.response?.data?.status);
        setDispalyProgress(true);
      });
  };
  //check the chart data is valid

  const isValidDataset = (data: any[]): boolean => {
    const allValues = data?.flatMap((dataset) => dataset.data);
    return allValues?.some((item) => item !== 0 && item !== null);
  };

  interface volCsodRateCardObject {
    description: string;
    turnoverRate: number;
    avgActiveCount: number;
    empCount: number;
    month: number;
    year: number;
    leftCount: number;
  }
  //update progres value
  const handleFilterUpdateProgress = () => {
    setProgress(0);
    setDispalyProgress(false);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 10;
        if (newProgress >= 100) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 100);
  };

  //Business Unit and Sub Business Unit tab/ pills
  const [selectedPillItem, setSelectedPillItem] = useState("BU");
  const handleMenuItemClick = (buType: string) => {
    handleFilterUpdateProgress();
    setSelectedPillItem(buType);
    dispatch(setBUType(buType));
    const unitID = buType === "SU" ? subBUId : bussUnit;
    const stringFilters = selectedFilters?.map((filter) => filter).join(",");
    fetchVoluntaryDetailsData({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      dateType: dateType,
      filter: stringFilters,
      category: selectedCategory,
      selectedBU: buType,
      selectedbussUnit: unitID,
    });
  };
  //handle sub bu change
  const handleSubBUChange = (subBUId: string) => {
    handleFilterUpdateProgress();
    setSubBUName(subBUId);
    dispatch(setSubBUId(subBUId));
    const stringFilters = selectedFilters?.map((filter) => filter).join(",");
    fetchVoluntaryDetailsData({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      dateType: dateType,
      filter: stringFilters,
      category: selectedCategory,
      selectedBU: buType,
      selectedbussUnit: subBUId,
    });
  };

  //drag and drop functionality
  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const newCards = Array.from(cards);
    const [reorderedCard] = newCards.splice(source.index, 1);
    newCards.splice(destination.index, 0, reorderedCard);
    setCards(newCards);
    const cardIds = newCards?.map((card) => card?.id);
    savePersonalization(cardIds);
  };

  //save personalized dashboard
  const savePersonalization = async (cardids: any) => {
    const vountaryCardIds = cardids?.map((card: any) => card).join(",");
    const savePersonalizationFilters = {
      email: user?.email,
      kpi: "Voluntary",
      position: vountaryCardIds,
    };
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/saveUserPersonalization`,
        savePersonalizationFilters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSaveCardPositionRes(200);
        setTimeout(() => {
          setSaveCardPositionRes(0);
        }, 1000);
      });
  };
  // //reset card position to default
  const resetPersonalization = () => {
    setCards(initialCards);
    const cardIds = initialCards?.map((card) => card?.id);
    savePersonalization(cardIds);
  };

  //multi select dropdown functionality
  const defaultFilters = ["Vol", "Inv", "Other"];
  const filters = [
    { value: "Vol", name: "Voluntary" },
    { value: "Inv", name: "Involuntary" },
    { value: "Other", name: "Other" },
  ];
  const categoriees = ["top talent,others", "top talent", "others"];
  const [selectedFilters, setSelectedFilters] =
    useState<string[]>(defaultFilters);
  const [selectedCategory, setCategory] = useState<string>("top talent,others");

  const [isOpen, setIsOpen] = useState(false);
  //close multi select drop-down on outside click
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleCloseOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleCloseOutside);
    return () => {
      document.removeEventListener("mousedown", handleCloseOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleCompany = (filter: string) => {
    handleFilterUpdateProgress();
    const index = selectedFilters.indexOf(filter);
    let updateFilters: string[];

    if (index !== -1) {
      updateFilters = [
        ...selectedFilters.slice(0, index),
        ...selectedFilters.slice(index + 1),
      ];
    } else {
      updateFilters = [...selectedFilters, filter];
    }
    setSelectedFilters(updateFilters);

    const stringFilters = updateFilters?.map((filter) => filter).join(",");

    fetchVoluntaryDetailsData({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      dateType: dateType,
      filter: stringFilters,
      category: selectedCategory,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUFirstId : bussUnit,
    });
  };

  if (loading) {
    return (
      <div className="global-loading d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <Row className="gx-3">
        <BreadCrumbComponent item="Voluntary Turnover Details" />
        <div
          className={`progress-bar-container ${
            dispalyProgress ? "show-progress" : ""
          }`}
        >
          <ProgressBar now={progress} />
        </div>
        {saveCardPositionRes === 200 ? (
          <div className="alert alert-primary col-md-3 move-alert" role="alert">
            <p>Card position has been saved.</p>
          </div>
        ) : null}
        <div className="mb-3"></div>
        <Col xs={12}>
          <Row className="align-items-center gx-3">
            <Col xs={12} md={3} lg={2} xl={2} className="mb-3">
              <h5 className="mb-0 details-heading">Voluntary Turnover</h5>
            </Col>
            {/* Business unit and Sub business unit tabs / pills */}
            <Col xs={12} md={4} lg={4} xl={3} className="mb-3">
              <div className="pills">
                <div className="px-2 py-1 nav-fill border-1 nav nav-pills">
                  <div className="nav-item">
                    <a
                      onClick={() => handleMenuItemClick("BU")}
                      className={`nav-link ${
                        selectedPillItem === "BU" ? "active" : ""
                      } ${subBUList && subBUList?.length <= 1 ? "active" : ""}`}
                    >
                      Business Unit
                    </a>
                  </div>
                  <div
                    className={`nav-item ${
                      subBUList && subBUList?.length <= 1 ? "not-allowed" : ""
                    }`}
                  >
                    <a
                      onClick={() => handleMenuItemClick("SU")}
                      role="button"
                      className={`nav-link sub-bu ${
                        subBUList && subBUList?.length <= 1 ? "disabled" : ""
                      } ${
                        selectedPillItem === "SU" &&
                        subBUList &&
                        subBUList?.length > 1
                          ? "active"
                          : ""
                      }`}
                    >
                      Sub Business Unit
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            {/* Sub business unit dropdown */}
            {selectedPillItem === "SU" && subBUList?.length! > 1 ? (
              <Col xs={6} md={2} lg={2} xl={2} className="mb-3">
                <select
                  className="filter form-select width-75p"
                  value={subBUName}
                  onChange={(e) => handleSubBUChange(e.target.value)}
                >
                  {subBUList?.map((su: any) => (
                    <option value={su.subUnitId} key={su.subUnitId}>
                      {su.subUnitDesc}
                    </option>
                  ))}
                </select>
              </Col>
            ) : null}
            <Col
              xs={6}
              md={4}
              lg={3}
              xl={2}
              className={`mb-3 d-flex align-items-center ${
                selectedPillItem === "SU" && subBUList?.length! > 1
                  ? "offset-xl-0"
                  : "offset-xl-2"
              }`}
            >
              <OverlayTrigger
                overlay={<Tooltip>Reset Personalization</Tooltip>}
                placement="bottom"
              >
                <button
                  className="btn border-0 reset-btn pe-3"
                  onClick={resetPersonalization}
                >
                  <span className="restore-page-icon"></span>
                </button>
              </OverlayTrigger>
              <div className="multiselect-dropdown">
                <div className="dropdown-header" onClick={toggleDropdown}>
                  Termination Type
                  <span className="material-symbols-outlined">expand_more</span>
                </div>
                <div ref={dropdownRef} className="dropdown-options-parent">
                  {isOpen && (
                    <div className="dropdown-options">
                      {filters?.map((filter: any, index: any) => (
                        <label key={index}>
                          <input
                            type="checkbox"
                            value={filter.value}
                            checked={selectedFilters?.includes(filter.value)}
                            onChange={() => toggleCompany(filter.value)}
                          />
                          &nbsp;{filter.name}
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Col>

            <Col xs={6} md={2} lg={2} xl={1} className="mb-3">
              <OverlayTrigger
                overlay={<Tooltip>Performance Type</Tooltip>}
                placement="top"
              >
                <select
                  className="filter form-select"
                  value={selectedCategory}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                >
                  <option value="top talent,others">All</option>
                  <option value="top talent">Top Talent</option>
                  <option value="others">Others</option>
                </select>
              </OverlayTrigger>
            </Col>

            <Col xs={6} md={2} lg={2} xl={1} className="mb-3">
              <select
                className="filter form-select"
                value={dateType}
                onChange={(e) => handleDateTypeChange(e.target.value)}
              >
                <option value="Y">YTD</option>
                <option value="Q">QTD</option>
                <option value="M">MTD</option>
              </select>
            </Col>
            <Col xs={6} md={2} lg={2} xl={1} className="mb-3">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MMM/yyyy"
                maxDate={currenMonth}
                showMonthYearPicker
                wrapperClassName="datepicker"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {vtStatus === 200 && vtResponse ? (
        <>
          <Row className="gx-3">
            <Col xs={12} xl={6}>
              <div className="employee-count parent-box vt mb-3">
                <Row className="displa-flex align-items-center">
                  <Col xs={6} md={3}>
                    <h6 className="mb-0 box-heading semi-bold">
                      Employee Count
                    </h6>
                  </Col>
                  {voluntaryPageHighLightsDataActiveEmpCount?.map(
                    (count: any, index: any) => (
                      <Col xs={6} md={3} key={index}>
                        <div className="count-box mb-2 mb-sm-0">
                          <p className="box-text">
                            {count.empStatus || "No Data"}
                          </p>

                          <p className="box-value semi-bold">
                            {count.empCount || 0}
                          </p>
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              </div>
            </Col>
            {/* Turnover rate */}
            <Col xs={12} xl={6}>
              <div className="employee-count parent-box vt gray-background mb-3">
                <Row className="displa-flex align-items-center">
                  <Col xs={6} md={3}>
                    <h6 className="mb-0 box-heading semi-bold">
                      Turnover Rate
                    </h6>
                  </Col>

                  <Col xs={6} md={3}>
                    <div className="count-box mb-2 mb-sm-0">
                      <p className="box-text">
                        Turnover Rate
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {voluntaryPageHighLightsDataTurnoverRate?.turnoverRateCal
                                ? voluntaryPageHighLightsDataTurnoverRate?.turnoverRateCal
                                : ""}
                            </Tooltip>
                          }
                          placement="bottom"
                        >
                          <span className="info-icon"></span>
                        </OverlayTrigger>
                      </p>

                      <p className="box-value semi-bold">
                        {voluntaryPageHighLightsDataTurnoverRate?.turnoverRate
                          ? voluntaryPageHighLightsDataTurnoverRate?.turnoverRate
                          : 0}
                        %
                      </p>
                    </div>
                  </Col>
                  <Col xs={6} md={3}>
                    <div className="count-box">
                      <p className="box-text">Left Count</p>

                      <p className="box-value semi-bold">
                        {voluntaryPageHighLightsDataTurnoverRate?.leftCount
                          ? voluntaryPageHighLightsDataTurnoverRate?.leftCount
                          : 0}
                      </p>
                    </div>
                  </Col>
                  <Col xs={6} md={3}>
                    <div className="count-box">
                      <p className="box-text">Avg Active Count</p>

                      <p className="box-value semi-bold">
                        {voluntaryPageHighLightsDataTurnoverRate?.avgActiveCount
                          ? voluntaryPageHighLightsDataTurnoverRate?.avgActiveCount
                          : 0}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12}>
              <Row className="gx-3 vol-high-low">
                <Col
                  xs={12}
                  md={6}
                  className={`mb-3 ${
                    highLowUnitwise?.length > 1 ? "col-xl-4" : "col-xl-6"
                  }`}
                >
                  <KeyHilightsVol
                    data={highLowPerformancewise}
                    heading="Performance Category Turnover Rate"
                  />
                </Col>
                <Col
                  xs={12}
                  md={6}
                  className={`mb-3 ${
                    highLowUnitwise?.length > 1 ? "col-xl-4" : "col-xl-6"
                  }`}
                >
                  <KeyHilightsVol
                    data={highLowCareerwise}
                    heading="High & Low Career Turnover Rate"
                  />
                </Col>
                {highLowUnitwise?.length > 1 ? (
                  <Col xs={12} md={6} xl={4} className="mb-3">
                    <KeyHilightsVol
                      data={highLowUnitwise}
                      heading="High & Low SUB BU Turnover Rate"
                    />
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="row gx-3"
                >
                  {cards?.length > 0
                    ? cards.map((card, index) => (
                        <Draggable
                          key={card?.id}
                          draggableId={card?.id}
                          index={index}
                        >
                          {(provided) => (
                            <div className={`col-xs-12 ${card?.colSize} mb-3`}>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="details-chart-container">
                                  <div className="chart-header">
                                    <h5 className="semi-bold mb-0 details-heading">
                                      {card?.heading}
                                    </h5>
                                    <div className="card-icons">
                                      <OverlayTrigger
                                        overlay={<Tooltip>Expand</Tooltip>}
                                        placement="top"
                                      >
                                        <span
                                          className="expand-icon"
                                          onClick={() =>
                                            handleShowModal(card.id)
                                          }
                                        ></span>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        overlay={<Tooltip>Move Cards</Tooltip>}
                                        placement="top"
                                      >
                                        <span className="move-icon"></span>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div className="chart">
                                    {card?.dataAvailable &&
                                    card?.chartData &&
                                    isValidDataset(
                                      card?.chartData?.datasets
                                    ) ? (
                                      <>
                                        {card?.chartType === "bar" &&
                                          card?.chartData && (
                                            <BarChart
                                              chartData={card?.chartData}
                                              callBackData={card?.callBackData}
                                              callBackData2={
                                                card?.callBackData2
                                              }
                                              callBackData3={
                                                card?.callBackData3
                                              }
                                              callBackData4={
                                                card?.callBackData4
                                              }
                                              callBackData5={
                                                card?.callBackData5
                                              }
                                              callBackData6={
                                                card?.callBackData6
                                              }
                                              chartOptions={card?.chartOptions}
                                              pluginsAvailable={card?.plugins}
                                              axisText1={card?.axisText1}
                                              axisText2={card?.axisText2}
                                              decimals={card?.decimals}
                                              gridRequired={card?.gridRequired}
                                            />
                                          )}
                                        {card?.chartType === "doughnut" &&
                                          card?.chartData && (
                                            <div className="chart d-flex justify-content-center">
                                              <Doughnut
                                                data={card?.chartData}
                                                options={card?.chartOptions}
                                              />
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      <div className="no-data-alert">
                                        <p className="text">
                                          No Data Available
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <Modal
                                  size="lg"
                                  aria-labelledby="contained-modal-title-vcenter"
                                  centered
                                  show={showModal && selectedCardId === card.id}
                                  onHide={handleClose}
                                  className={`${
                                    theme === "dark" ? "dark-theme" : ""
                                  }`}
                                >
                                  <Modal.Header>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                      {card?.heading}
                                    </Modal.Title>
                                    <span
                                      className="material-symbols-outlined float-end"
                                      onClick={handleClose}
                                    >
                                      close
                                    </span>
                                  </Modal.Header>
                                  <Modal.Body>
                                    {card?.dataAvailable &&
                                    card?.chartData &&
                                    isValidDataset(
                                      card?.chartData?.datasets
                                    ) ? (
                                      <div
                                        className={`chart ${
                                          card?.chartType === "doughnut"
                                            ? "d-flex justify-content-center"
                                            : ""
                                        }`}
                                      >
                                        {card?.chartType === "bar" &&
                                          card?.chartData && (
                                            <BarChart
                                              chartData={card?.chartData}
                                              callBackData={card?.callBackData}
                                              callBackData2={
                                                card?.callBackData2
                                              }
                                              callBackData3={
                                                card?.callBackData3
                                              }
                                              callBackData4={
                                                card?.callBackData4
                                              }
                                              callBackData5={
                                                card?.callBackData5
                                              }
                                              callBackData6={
                                                card?.callBackData6
                                              }
                                              chartOptions={card?.chartOptions}
                                              pluginsAvailable={card?.plugins}
                                              axisText1={card?.axisText1}
                                              axisText2={card?.axisText2}
                                              gridRequired={card?.gridRequired}
                                            />
                                          )}
                                        {card.chartType === "doughnut" &&
                                          card?.chartData && (
                                            <div className="d-flex justify-content-center">
                                              <Doughnut
                                                data={card?.chartData}
                                                options={card?.chartOptions}
                                              />
                                            </div>
                                          )}
                                      </div>
                                    ) : (
                                      <div className="no-data-alert">
                                        <p className="text">
                                          No Data Available
                                        </p>
                                      </div>
                                    )}
                                  </Modal.Body>
                                </Modal>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : null}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <Row className="h-100vh">
          <Col xs={12}>
            <Alert variant="warning" onClose={() => setAlertShow(false)}>
              <Alert.Heading>No Data Available!</Alert.Heading>
              {selectedFilters?.length > 0 ? (
                <p>For the selected filters, Please modify the filters</p>
              ) : (
                <p>Please select at least one termination type</p>
              )}
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};
