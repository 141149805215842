import axios from "axios";
import "./TRIRDetails.scss";
import { useEffect, useState } from "react";
//redux toolkit imports
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  setBUType,
  setSubBUId,
  setSelectedDateType,
  setSelectedMonthYear,
} from "../../redux/userSlice";

//date picker imports
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//bootstrap components
import {
  Breadcrumb,
  Col,
  Modal,
  OverlayTrigger,
  Tooltip,
  Row,
  Spinner,
  Alert,
  ProgressBar,
} from "react-bootstrap";
import { ChartData } from "chart.js/auto";

//chartjs imports
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  LineController,
  Filler,
  BarController,
  registerables,
} from "chart.js";
import { Bar, Chart, Line, Pie } from "react-chartjs-2";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { BarChart } from "./GenericComponents/Charts/BarChart";
import { LineChart } from "./GenericComponents/Charts/LineChart";
import { Link } from "react-router-dom";
import { graphColors } from "../../utils/graphColors";
import { BreadCrumbComponent } from "./GenericComponents/BreadcrumbComponent";
import { KeyHighlightsTRIR } from "./GenericComponents/KeyHighlightsTRIR";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  LineController,
  Filler,
  BarController,
  ...registerables
);

//interface for draggable Card and graph data
interface ChildCard {
  chartType: string;
  heading: any[];
  chartData: any[];
  chartOptions: any;
  noChildCards?: boolean;
  axisText1?: string;
  axisText2?: string;
  decimals?: boolean;
}
interface CardItem {
  id: string;
  heading: string;
  chartData?: any;
  chartOptions?: any;
  dataAvailable: boolean;
  colSize: string;
  chartType: string;
  childCardData?: ChildCard[];
  axisText1?: string;
  axisText2?: string;
  decimals?: number;
  gridRequired?: boolean;
  plugins?: boolean;
}
//pie chart options
const optionsPieChart = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "bottom" as const,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        padding: 5,
        font: {
          size: 11,
        },
      },
    },
  },
};

export const TRIRDetails = () => {
  //data from redux toolkit
  const token: any = useSelector((state: RootState) => state.auth.token);
  const user: any = useSelector((state: RootState) => state.user.userDetails);
  const bussUnit = useSelector((state: RootState) => state.user.bussUnit);
  const theme: any = useSelector((state: RootState) => state.card.darkTheme);
  const buType = useSelector((state: RootState) => state.user.buType);
  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const subBUList = useSelector((state: RootState) => state.user.subUnitList);
  const selectedDateType = useSelector(
    (state: RootState) => state.user.selectedDateType
  );
  const selectedMonthYear = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );

  useEffect(() => {
    handleFilterUpdateProgress();
    //fetch data on page load and on BU change
    fetchTRIRGraphs({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      dateType: selectedDateType,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
    //update pill item on page load
    setSelectedPillItem(buType ? buType : selectedPillItem);
    //update sub BU on page load
    setSubBUName(subBUId);
    //update date type on page load
    setDateType(selectedDateType);
  }, [subBUFirstId]);

  //store response
  const [trirResponse, setTRIRResponse] = useState<any>(null);
  const [trirStatus, setTRIRStatus] = useState<any>(null);
  const [initialCards, setInitialCards] = useState<CardItem[]>([]);
  const [cards, setCards] = useState<CardItem[]>([]);
  //progress bar
  const [progress, setProgress] = useState<number>(0);
  const [displayProgress, setDisplayProgress] = useState<boolean>(true);
  //store data
  //Key Highlights - 3 cards
  const [keyHighlights3Cards, setKeyHighlights3Cards] = useState<any>(null);
  //High and Low Date wise
  const [highLowTRIRRate, setHighLowTRIRRate] = useState<any>(null);

  //High & Low TRIR Rollup Job
  const [highLowRollupJob, setHighLowRollupJob] = useState<any>(null);

  //TRIR, Fatal & Lost Time Rate Breakdown & Comparision by Month
  const [tFLTComparisionbyMonth, setTFLTComparisionbyMonth] =
    useState<any>(null);

  //Job Count Breakdown
  const [totalJobCount, setTotalJobCount] = useState<any>(null);
  const [jobcounts, setJobCounts] = useState<any>(null);
  const [jobCountLabels, setJobCountLabels] = useState<any>(null);
  const colors = [
    "#4596b2",
    "rgb(69 150 178 / 90%)",
    "rgb(69 150 178 / 80%)",
    "rgb(69 150 178 / 70%)",
    "rgb(69 150 178 / 60%)",
    "rgb(69 150 178 / 50%)",
    "rgb(69 150 178 / 40%)",
    "rgb(69 150 178 / 30%)",
    "rgb(69 150 178 / 20%)",
  ];

  //months
  const monthsMap: { [key: string]: number } = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };
  const getMonthText = (month: number): string => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month - 1];
  };

  const fetchTRIRGraphs = async (payload?: any) => {
    setProgress(0);
    try {
      const { month, year, dateType, selectedBU, selectedbussUnit } =
        payload || {};
      const currentMonth = month !== undefined ? month + 1 : undefined;
      const typeofBU = selectedBU ? selectedBU : buType;
      const bussUnitID = selectedbussUnit
        ? selectedbussUnit
        : typeofBU === "SU"
        ? subBUId
        : bussUnit;
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_URL}/trirPageDetails`,
          {
            dateFilter: dateType || "Y",
            type: typeofBU || "BU",
            unitId: bussUnitID,
            month: currentMonth,
            year: year,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setTRIRResponse(res.data?.data?.Map);
          setTRIRStatus(res.data?.status);

          //Key Highlights 3 Cards
          const keyHighlightsData = res?.data?.data?.Map?.trir_fatal_lt_rate;
          const keyHighlights = keyHighlightsData
            ? keyHighlightsData[0]
            : keyHighlightsData;
          const keyHighlightsObjects = [
            {
              heading: "TRIR Rate",
              aspirationalVal: 0.75,
              rate: keyHighlights?.trirPer,
              count: keyHighlights?.recordableCount,
              hours: keyHighlights?.totalHours,
            },
            {
              heading: "Fatalities",
              aspirationalVal: 0,
              rate: keyHighlights?.fatalRate,
              count: keyHighlights?.fatalCount,
              hours: keyHighlights?.totalHours,
            },
            {
              heading: "Lost Time",
              aspirationalVal: 0.0,
              rate: keyHighlights?.lastRate,
              count: keyHighlights?.lastTimeCount,
              hours: keyHighlights?.totalHours,
            },
          ];
          setKeyHighlights3Cards(keyHighlightsObjects);

          //Key Highlights 2 Cards
          const keyHighlightsDateWise = res?.data?.data?.Map?.top2_card;

          //filter data for high and lowest TRIR % objects
          const sortedKeyHighlights2Cards =
            keyHighlightsDateWise?.length > 1
              ? keyHighlightsDateWise
                  ?.slice()
                  ?.sort((a: any, b: any) => a.trirPer - b.trirPer)
              : keyHighlightsDateWise;
          const highestDateWise =
            sortedKeyHighlights2Cards?.length > 1
              ? sortedKeyHighlights2Cards[sortedKeyHighlights2Cards?.length - 1]
              : "";
          const lowestDateWise = sortedKeyHighlights2Cards
            ? sortedKeyHighlights2Cards[0]
            : "";
          const keyHighlights2CardsYear = [
            {
              date: highestDateWise?.quarter
                ? highestDateWise?.year + " Q" + highestDateWise?.quarter
                : highestDateWise?.monthId
                ? getMonthText(highestDateWise?.monthId % 100)
                : highestDateWise?.year,
              trirPer: highestDateWise?.trirPer,
              recordableCount: highestDateWise?.recordableCount,
              totalHours: highestDateWise?.totalHours,
            },
            {
              date: lowestDateWise?.quarter
                ? lowestDateWise?.year + " Q" + lowestDateWise?.quarter
                : lowestDateWise?.monthId
                ? getMonthText(lowestDateWise?.monthId % 100)
                : lowestDateWise?.year,
              trirPer: lowestDateWise?.trirPer,
              recordableCount: lowestDateWise?.recordableCount,
              totalHours: lowestDateWise?.totalHours,
            },
          ];
          setHighLowTRIRRate(keyHighlights2CardsYear);

          //High & Low TRIR Rollup Job
          const keyHighlightsRollupJob = res?.data?.data?.Map?.top4_card;
          //filter data for high and lowest TRIR % objects
          const sortedKeyHighlightsRollupJob =
            keyHighlightsRollupJob?.length > 1
              ? keyHighlightsRollupJob
                  ?.slice()
                  .sort((a: any, b: any) => a.trirPer - b.trirPer)
              : keyHighlightsRollupJob;
          const highestRollupJob =
            sortedKeyHighlightsRollupJob?.length > 1
              ? sortedKeyHighlightsRollupJob[
                  sortedKeyHighlightsRollupJob?.length - 1
                ]
              : sortedKeyHighlightsRollupJob;
          const lowestRollupJob = sortedKeyHighlightsRollupJob
            ? sortedKeyHighlightsRollupJob[0]
            : "";
          const keyHighlights2CardsRollup = [
            {
              jobCode: highestRollupJob?.jobCode,
              trirPer: highestRollupJob?.trirPer,
              recordableCount: highestRollupJob?.recordableCount,
              totalHours: highestRollupJob?.totalHours,
            },
            {
              jobCode: lowestRollupJob?.jobCode,
              trirPer: lowestRollupJob?.trirPer,
              recordableCount: lowestRollupJob?.recordableCount,
              totalHours: lowestRollupJob?.totalHours,
            },
          ];
          setHighLowRollupJob(keyHighlights2CardsRollup);

          //TRIR, Fatalities & Lost Time Rate Breakdown by Month
          const tFLTBreakdownbyMonth =
            dateType === "Q"
              ? res?.data?.data?.Map?.quarter_trir_fatal_lt_rate
              : dateType === "M"
              ? res?.data?.data?.Map?.month_trir_fatal_lt_rate
              : res?.data?.data?.Map?.year_trir_fatal_lt_rate;
          const sortedTFLTBreakdownbyMonth =
            tFLTBreakdownbyMonth?.length > 1
              ? tFLTBreakdownbyMonth?.sort(
                  dateType === "M"
                    ? (a: any, b: any) =>
                        monthsMap[a.month] - monthsMap[b.month]
                    : (a: any, b: any) => a.year - b.year
                )
              : tFLTBreakdownbyMonth;
          const trir: any[] = [];
          const fatal: any[] = [];
          const ltRate: any[] = [];
          const pyTrirRate: any[] = [];
          const pyFatalRate: any[] = [];
          const pyLtRate: any[] = [];
          const labelsTFLT: any[] = [];
          sortedTFLTBreakdownbyMonth?.forEach((obj: any, index: number) => {
            trir.push(obj.trirPer?.toFixed(2));
            fatal.push(obj.fatalRate?.toFixed(2));
            ltRate.push(obj.lastRate?.toFixed(2));
            pyTrirRate.push(obj.pyTrirRate?.toFixed(2));
            pyFatalRate.push(obj.pyFatalRate?.toFixed(2));
            pyLtRate.push(obj.pyLtRate?.toFixed(2));
            labelsTFLT.push(
              obj.quarter
                ? obj.year + " Q" + obj.quarter
                : obj.monthId
                ? getMonthText(obj.monthId % 100)
                : obj.year
            );
          });

          const tFLTBreakdownbyMonthChartData: ChartData<"line"> = {
            labels: labelsTFLT,
            datasets: [
              {
                label: "Fatalities",
                data: fatal,
                borderColor: graphColors.red,
                borderWidth: 2,
                backgroundColor: graphColors.red75O,
                fill: true,
              },
              {
                label: "Lost Time Rate",
                data: ltRate,
                borderColor: graphColors.yellow,
                borderWidth: 2,
                backgroundColor: graphColors.yellow75O,
                fill: true,
              },
              {
                label: "TRIR",
                data: trir,
                borderColor: graphColors.gray,
                borderWidth: 2,
                backgroundColor: graphColors.gray75O,
                fill: true,
              },
            ],
          };

          //Comparision data
          const tFLTComparisionbyMonthChartData = {
            labels: labelsTFLT,
            datasets: [
              {
                label: "PY Fatalities",
                data: pyFatalRate,
                borderColor: "#7f2529",
                borderWidth: 2,
                backgroundColor: "rgb(127 37 41 / 50%)",
                fill: true,
              },
              {
                label: "CY Fatalities",
                data: fatal,
                borderColor: "#7f2529",
                borderWidth: 2,
                backgroundColor: "rgb(127 37 41 / 100%)",
                fill: true,
              },
              {
                label: "PY Lost Time Rate",
                data: pyLtRate,
                borderColor: "#ffd578",
                borderWidth: 2,
                backgroundColor: "rgb(255 213 120 / 50%)",
                fill: true,
              },
              {
                label: "CY Lost Time Rate",
                data: ltRate,
                borderColor: "#ffd578",
                borderWidth: 2,
                backgroundColor: "rgb(255 213 120 / 100%)",
                fill: true,
              },
              {
                label: "PY TRIR",
                data: pyTrirRate,
                borderColor: "#59656e",
                borderWidth: 2,
                backgroundColor: "rgb(89 101 110 / 50%)",
                fill: true,
              },
              {
                label: "CY TRIR",
                data: trir,
                borderColor: "#59656e",
                borderWidth: 2,
                backgroundColor: "rgb(89 101 110 / 100%)",
                fill: true,
              },
            ],
          };
          setTFLTComparisionbyMonth(tFLTComparisionbyMonthChartData);

          //Job Count Breakdown
          const recordCount = res?.data?.data?.Map?.record_count;
          const jobcountLabels = recordCount?.map(
            (obj: any) => obj.recordableCount
          );
          const jcData = recordCount?.map((obj: any) => obj.jobCount);

          //sum all the job count values
          const totalJobCount = jcData?.reduce(
            (acc: any, curr: any) => acc + curr,
            0
          );
          setTotalJobCount(totalJobCount);
          const jobCountData = {
            labels: jobcountLabels,
            datasets: [
              {
                data: jcData,
                borderColor: "#fff",
                backgroundColor: [
                  "#4596b2",
                  "rgb(69 150 178 / 90%)",
                  "rgb(69 150 178 / 80%)",
                  "rgb(69 150 178 / 70%)",
                  "rgb(69 150 178 / 60%)",
                  "rgb(69 150 178 / 50%)",
                  "rgb(69 150 178 / 40%)",
                  "rgb(69 150 178 / 30%)",
                  "rgb(69 150 178 / 20%)",
                ],
                fill: true,
              },
            ],
          };
          const { labels, datasets } = jobCountData;
          setJobCounts(datasets);
          setJobCountLabels(labels);

          //Hours Breakdown by Sub BU
          const subbuHours = res?.data?.data?.Map?.Subbu_hours;
          const subbuHoursCardLabels: any[] = Array.from(
            new Set(subbuHours?.map((item: any) => item?.unitDesc))
          );

          //Initialize array to store chart data
          const subbuHoursData: any[] = [];
          //iterate over subUnits
          subbuHoursCardLabels?.forEach((unitDesc) => {
            const filteredData = subbuHours?.filter(
              (obj: any) => obj.unitDesc === unitDesc
            );
            //Extract amounts
            const gilbaneHours = filteredData?.map(
              (obj: any) => obj.gilbaneHours / 1000000
            );
            const tradeHours = filteredData?.map(
              (obj: any) => obj.tradeHours / 1000000
            );
            const totalHours = filteredData?.map(
              (obj: any) => obj.totalHours / 1000000
            );
            //Create datasets
            const data: ChartData<"bar"> = {
              labels: [""],
              datasets: [
                {
                  label: "Gilbane Hours",
                  data: gilbaneHours,
                  backgroundColor: "#a1cad8",
                  borderColor: "#fff",
                },
                {
                  label: "Trade Hours",
                  data: tradeHours,
                  backgroundColor: "#73b0c5",
                  borderColor: "#fff",
                },
                {
                  label: "Total Hours",
                  data: totalHours,
                  backgroundColor: "#4596b2",
                  borderColor: "#fff",
                },
              ],
            };
            //push chart data into array
            subbuHoursData.push(data);
          });

          //Hours Breakdown by Month

          const hoursBreakdownbyMonth =
            dateType === "Q"
              ? res?.data?.data?.Map?.quarter_trir_fatal_lt_rate
              : dateType === "M"
              ? res?.data?.data?.Map?.month_trir_fatal_lt_rate
              : res?.data?.data?.Map?.year_trir_fatal_lt_rate;
          //sort the data by dates
          const sortedHoursBreakdownbyMonth =
            hoursBreakdownbyMonth?.length > 1
              ? hoursBreakdownbyMonth?.sort(
                  dateType === "M"
                    ? (a: any, b: any) =>
                        monthsMap[a.month] - monthsMap[b.month]
                    : (a: any, b: any) => a.year - b.year
                )
              : hoursBreakdownbyMonth;
          const totalHours: any[] = [];
          const gilbaneHours: any[] = [];
          const tradeHours: any[] = [];
          const labelsHoursBreakdownByMonth: any[] = [];
          sortedHoursBreakdownbyMonth?.forEach((obj: any, index: number) => {
            totalHours.push(obj.totalHours / 1000000);
            gilbaneHours.push(obj.gilbaneHours / 1000000);
            tradeHours.push(obj.tradeHours / 1000000);

            labelsHoursBreakdownByMonth.push(
              obj.quarter
                ? obj.year + " Q" + obj.quarter
                : obj.monthId
                ? getMonthText(obj.monthId % 100)
                : obj.year
            );
          });
          const hoursBreakdownByMonthData = {
            labels: labelsHoursBreakdownByMonth,
            datasets: [
              {
                label: "Gilbane Hours",
                data: gilbaneHours,
                borderColor: graphColors.blue,
                backgroundColor: graphColors.blue,
                stepped: true,
              },
              {
                label: "Trade Hours",
                data: tradeHours,
                borderColor: graphColors.yellow,
                backgroundColor: graphColors.yellow,
                stepped: true,
              },
              {
                label: "Total Hours",
                data: totalHours,
                borderColor: graphColors.green,
                backgroundColor: graphColors.green,
                stepped: true,
              },
            ],
          };

          //Incident Count Breakdown by
          const incidentBreakdownbyMonth =
            dateType === "Q"
              ? res?.data?.data?.Map?.quarter_trir_fatal_lt_rate
              : dateType === "M"
              ? res?.data?.data?.Map?.month_trir_fatal_lt_rate
              : res?.data?.data?.Map?.year_trir_fatal_lt_rate;

          const sortedIncidentBreakdownbyMonth =
            incidentBreakdownbyMonth?.length > 1
              ? incidentBreakdownbyMonth.sort(
                  dateType === "M"
                    ? (a: any, b: any) =>
                        monthsMap[a.month] - monthsMap[b.month]
                    : (a: any, b: any) => a.year - b.year
                )
              : incidentBreakdownbyMonth;
          const totalHoursEmpCount: any[] = [];
          const recordableCount: any[] = [];
          const labelsEmpCount: any[] = [];

          sortedIncidentBreakdownbyMonth?.forEach((obj: any, index: number) => {
            totalHoursEmpCount.push(obj.totalHours / 1000000);
            recordableCount.push(obj.recordableCount);

            labelsEmpCount.push(
              obj.quarter
                ? obj.year + " Q" + obj.quarter
                : obj.monthId
                ? getMonthText(obj.monthId % 100)
                : obj.year
            );
          });
          const empCountbyMonthandStatusChartData = {
            labels: labelsEmpCount,
            datasets: [
              {
                type: "line" as const,
                label: "Total Hours",
                data: totalHoursEmpCount,
                backgroundColor: graphColors.red75O,
                borderColor: graphColors.red75O,
                borderWidth: 1.5,
                pointBorderColor: graphColors.red75O,
                pointRadius: 3,
                pointSize: 2,
                fill: false,
              },
              {
                type: "bar" as const,
                label: "Recordable Count",
                data: recordableCount,
                backgroundColor: graphColors.gray75O,
                borderColor: graphColors.gray75O,
                borderWidth: 1.5,
                pointBorderColor: graphColors.gray75O,
                pointRadius: 3,
                pointSize: 2,
                fill: false,
              },
            ],
          };

          //TRIR, Fatalities & Lost Rate Breakdown by Roll up Job
          const ib_rollup_job_code_trir_fatal_lt_rate =
            res?.data?.data?.Map?.ib_rollup_job_code_trir_fatal_lt_rate;

          //Initialize array to store chart data
          const rollupJobLabels: any = [];
          const trirPerRJ: any[] = [];
          const fatalRateRJ: any[] = [];
          const lastRateRJ: any[] = [];
          //iterate over subUnits
          ib_rollup_job_code_trir_fatal_lt_rate?.forEach(
            (obj: any, index: number) => {
              trirPerRJ.push(obj.trirPer?.toFixed(2));
              fatalRateRJ.push(obj.fatalRate?.toFixed(2));
              lastRateRJ.push(obj.lastRate?.toFixed(2));
              rollupJobLabels.push(obj.jobCode);
            }
          );
          const rollUpData: ChartData<"bar"> = {
            labels: rollupJobLabels,
            datasets: [
              {
                label: "TRIR",
                data: trirPerRJ,
                borderColor: graphColors.gray75O,
                backgroundColor: graphColors.gray75O,
              },
              {
                label: "Fatalities",
                data: fatalRateRJ,
                borderColor: graphColors.red75O,
                backgroundColor: graphColors.red75O,
              },
              {
                label: "Lost Time Rate",
                data: lastRateRJ,
                borderColor: graphColors.yellow75O,
                backgroundColor: graphColors.yellow75O,
              },
            ],
          };

          //TRIR Rate by Sub BU
          const ratebySubBU = res?.data?.data?.Map?.Subbu_trir_rate;

          const ratebySubBULabels = Array.from(
            new Set(ratebySubBU?.map((item: any) => item.unitDesc))
          );
          let trirPer: any[] = [];
          ratebySubBULabels?.forEach((unitDesc) => {
            const filteredRatebySubBUData = ratebySubBU?.filter(
              (obj: any) => obj.unitDesc === unitDesc
            );
            const trirPers = filteredRatebySubBUData?.map((obj: any) =>
              trirPer.push(obj.trirPer?.toFixed(2))
            );
          });
          const ratebySubBUData: ChartData<"bar"> = {
            labels: ratebySubBULabels,
            datasets: [
              {
                label: "TRIR Rate",
                data: trirPer,
                borderColor: graphColors.blue,
                backgroundColor: graphColors.blue,
              },
            ],
          };

          //store data to array for drag and drop
          //child cards data
          const childCards = subbuHoursCardLabels?.map((heading, index) => ({
            chartType: "bar",
            heading: heading,
            chartOptions: "multiBarOptionsTooltip",
            chartData: subbuHoursData[index],
            axisText1: "Hours(Millions)",
            noChildCards: subbuHoursCardLabels?.length > 0 ? true : false,
            plugins: true,
          }));

          const initialCards: CardItem[] = [
            {
              id: "card1",
              heading: `TRIR, Fatalities & Lost Time Rate`,
              dataAvailable: tFLTBreakdownbyMonth ? true : false,
              chartData: tFLTBreakdownbyMonthChartData,
              chartOptions: "areaChartOptions",
              colSize: "col-md-6",
              chartType: "line",
              axisText1: "TRIR, Fatalities & Lost Time Rate",
              plugins: true,
            },
            {
              id: "card2",
              heading: "TRIR Rate by Sub BU",
              dataAvailable: ratebySubBU ? true : false,
              chartData: ratebySubBUData,
              chartOptions: "multiBarOptions",
              colSize: "col-md-6 col-xl-3",
              chartType: "bar",
              axisText1: "TRIR Rate",
              gridRequired: false,
              plugins: true,
            },
            {
              id: "card3",
              heading: "Job Count Breakdown",
              dataAvailable: recordCount ? true : false,
              chartData: jobCountData,
              chartOptions: optionsPieChart,
              colSize: "col-md-6 col-xl-3",
              chartType: "pie",
            },
            {
              id: "card4",
              heading: "Hours Breakdown by Sub BU",
              dataAvailable: subbuHours ? true : false,
              childCardData: childCards,
              colSize: "mt-0",
              chartType: "bar",
            },
            {
              id: "card5",
              heading: "TRIR, Fatalities & Lost Rate Breakdown by Roll up Job",
              dataAvailable: ib_rollup_job_code_trir_fatal_lt_rate
                ? true
                : false,
              chartData: rollUpData,
              chartOptions: "multiBarOptions",
              colSize: "col-md-6",
              chartType: "bar",
              axisText1: "TRIR, Fatalities & Lost Rate",
              decimals: 1,
              gridRequired: true,
              plugins: true,
            },
            {
              id: "card6",
              heading: `Hours Breakdown by ${
                dateType === "M"
                  ? "Month"
                  : dateType === "Q"
                  ? "Quarter"
                  : "Year"
              }`,
              dataAvailable: hoursBreakdownbyMonth ? true : false,
              chartData: hoursBreakdownByMonthData,
              chartOptions: "squareLineChartOptions",
              colSize: "col-md-6",
              chartType: "line",
              axisText1: "Hours(Millions)",
              plugins: true,
            },
            {
              id: "card7",
              heading: `Incident Count Breakdown by ${
                dateType === "M"
                  ? "Month"
                  : dateType === "Q"
                  ? "Quarter"
                  : "Year"
              }`,
              dataAvailable: incidentBreakdownbyMonth ? true : false,
              chartData: empCountbyMonthandStatusChartData,
              chartOptions: "multiTypeChartOptions",
              colSize: "col-md-6",
              chartType: "bar",
              axisText1: "Hours(Millions)",
              plugins: true,
            },
          ];
          setInitialCards(initialCards);
          setCards(initialCards);

          setProgress(100);
          setDisplayProgress(true);
        });
    } catch (error: any) {
      console.error("Error fetching TRIR graph details data", error);
      setLoading(false);
      setTRIRStatus(error.response?.data?.status);
      setDisplayProgress(true);
    }
  };

  //drag and drop modal
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCardId, setSelectedCardId] = useState<string>("");
  const handleShowModal = (cardId: string) => {
    setSelectedCardId(cardId);
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  //drag and drop
  const fetchCardPositionFilters = {
    email: user?.email,
    kpi: "trir",
  };
  useEffect(() => {
    if (
      fetchCardPositionFilters.email !== null &&
      fetchCardPositionFilters.email !== undefined
    ) {
      fetchCardPosition();
    }
  }, [trirResponse]);
  //fetch card positions
  const fetchCardPosition = async () => {
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/fetchUserPersonalizationData`,
        fetchCardPositionFilters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const cardIds = res.data.data?.Details?.cardPosition?.split(",");
        const initialCardIDs = initialCards?.map((card) => card.id);
        const defaultORUpdatedCardIds = cardIds
          ? cardIds?.length === initialCardIDs?.length
            ? cardIds
            : initialCardIDs
          : initialCardIDs;
        const updatedCards = defaultORUpdatedCardIds?.map(
          (id: any, index: any) => {
            const matchingCard = cards?.find((card) => card?.id === id);
            return matchingCard ? { ...matchingCard } : null;
          }
        );
        setCards([...updatedCards]);
      });
  };
  //drag and drop functionality
  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const newCards = Array.from(cards);
    const [reorderedCard] = newCards?.splice(source.index, 1);
    newCards?.splice(destination.index, 0, reorderedCard);
    setCards(newCards);

    const cardIds = newCards?.map((card) => card?.id);
    savePersonalization(cardIds);
  };
  const [saveCardPositionRes, setSaveCardPositionRes] = useState<any>(null);
  //save personalized dashboard
  const savePersonalization = async (cardids: any) => {
    const trirCardIds = cardids?.map((card: any) => card).join(",");
    const savePersonalizationFilters = {
      email: user?.email,
      kpi: "trir",
      position: trirCardIds,
    };
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/saveUserPersonalization`,
        savePersonalizationFilters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSaveCardPositionRes(200);
        setTimeout(() => {
          setSaveCardPositionRes(0);
        }, 1000);
      });
  };
  // //reset card position to default
  const resetPersonalization = () => {
    setCards(initialCards);
    const cardIds = initialCards?.map((card) => card?.id);
    savePersonalization(cardIds);
  };
  const [selectedPillItem, setSelectedPillItem] = useState("BU");
  const dispatch = useDispatch();
  //BU and Sub BU tabs
  const handleMenuItemClick = (buType: string) => {
    handleFilterUpdateProgress();
    setSelectedPillItem(buType);
    dispatch(setBUType(buType));
    const unitID = buType === "SU" ? subBUId : bussUnit;
    fetchTRIRGraphs({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      dateType: dateType,
      selectedBU: buType,
      selectedbussUnit: unitID,
    });
  };
  //handle sub bu dropdown change
  const handleSubBUChange = (subBUId: string) => {
    handleFilterUpdateProgress();
    setSubBUName(subBUId);
    dispatch(setSubBUId(subBUId));
    fetchTRIRGraphs({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      dateType: dateType,
      selectedBU: "SU",
      selectedbussUnit: subBUId,
    });
  };
  //payload filters
  const [dateType, setDateType] = useState<string>("Y");
  const [subBUName, setSubBUName] = useState<any>();

  //calculate the last month
  const lastDayofPreviousMonth = new Date();
  lastDayofPreviousMonth.setMonth(lastDayofPreviousMonth.getMonth());
  lastDayofPreviousMonth.setDate(1);
  const selectedDate = new Date(
    selectedMonthYear.year,
    selectedMonthYear.month
  );

  //update progres bar value
  const handleFilterUpdateProgress = () => {
    setProgress(0);
    setDisplayProgress(false);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 10;
        if (newProgress >= 100) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 100);
  };
  //date type (YTD, QTD or MTD)
  const handleDateTypeChange = (value: string) => {
    handleFilterUpdateProgress();
    setDateType(value);
    dispatch(setSelectedDateType(value));
    fetchTRIRGraphs({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,

      dateType: value,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
  };

  //Date filter change
  const handleDateChange = (date: Date | null) => {
    handleFilterUpdateProgress();
    if (date) {
      const month = date.getMonth();
      const year = date!.getFullYear();
      dispatch(
        setSelectedMonthYear({
          month,
          year,
        })
      );
    }
    fetchTRIRGraphs({
      month: date?.getMonth(),
      year: date?.getFullYear(),
      dateType: dateType,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
  };

  //loading
  const [loading, setLoading] = useState(true);

  //toggle display
  const [toggleDisplay, setToggleDisplay] = useState(true);

  const handletoggleDisplay = () => {
    setToggleDisplay(!toggleDisplay);
  };

  //check the chart data is valid
  const isValidDataset = (data: any[]): boolean => {
    const allValues = data?.flatMap((dataset) => dataset.data);
    return allValues?.some((item) => item !== undefined && item !== null);
  };

  //formatNumberWithCommas
  const formatNumberWithCommas = (value: any): string => {
    if (typeof value !== "number" || isNaN(value)) {
      return value;
    }
    return new Intl.NumberFormat("en-US").format(value);
  };
  if (loading) {
    return (
      <div className="global-loading d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <>
      <Row className="gx-3">
        {/* Breadcrumbs */}
        <BreadCrumbComponent item="TRIR Details" />
        {/* Progress bar */}
        <div
          className={`progress-bar-container ${
            displayProgress ? "show-progress" : ""
          }`}
        >
          <ProgressBar now={progress} />
        </div>
        {/* Card position saved */}
        {saveCardPositionRes === 200 ? (
          <div className="alert alert-primary col-md-3 move-alert" role="alert">
            <p>Card position has been saved.</p>
          </div>
        ) : null}
        <div className="mb-3"></div>
        <Col xs={12}>
          <Row className="align-items-center gx-3">
            <Col xs={12} md={3} lg={2} xl={3} className="mb-3">
              <h5 className="mb-0 details-heading">
                Total Recordable Incident Rate
              </h5>
            </Col>
            <Col xs={12} md={4} lg={4} xl={3} className="mb-3">
              {/* BU and Sub BU tabs */}
              <div className="pills">
                <div className="px-2 py-1 nav-fill border-1 nav nav-pills">
                  <div className="nav-item">
                    <a
                      onClick={() => handleMenuItemClick("BU")}
                      className={`nav-link ${
                        selectedPillItem === "BU" ? "active" : ""
                      } ${subBUList && subBUList?.length <= 1 ? "active" : ""}`}
                    >
                      Business Unit
                    </a>
                  </div>
                  <div
                    className={`nav-item ${
                      subBUList && subBUList?.length <= 1 ? "not-allowed" : ""
                    }`}
                  >
                    <a
                      onClick={() => handleMenuItemClick("SU")}
                      role="button"
                      className={`nav-link sub-bu ${
                        subBUList && subBUList?.length <= 1 ? "disabled" : ""
                      } ${
                        selectedPillItem === "SU" &&
                        subBUList &&
                        subBUList?.length > 1
                          ? "active"
                          : ""
                      }`}
                    >
                      Sub Business Unit
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            {/* Sub BU dropdown */}
            {selectedPillItem === "SU" && subBUList?.length! > 1 ? (
              <Col xs={6} md={2} lg={2} xl={2} className="mb-3">
                <select
                  className="filter form-select width-75p"
                  value={subBUName}
                  onChange={(e) => handleSubBUChange(e.target.value)}
                >
                  {subBUList?.map((su: any) => (
                    <option value={su.subUnitId} key={su.subUnitId}>
                      {su.subUnitDesc}
                    </option>
                  ))}
                </select>
              </Col>
            ) : null}
            <Col
              xs={6}
              md={2}
              lg={2}
              xl={1}
              className={`${
                selectedPillItem === "SU" && subBUList?.length! > 1
                  ? "offset-lg-1"
                  : "offset-lg-3"
              } mb-3 text-right`}
            >
              <OverlayTrigger
                overlay={<Tooltip>Reset Personalization</Tooltip>}
                placement="bottom"
              >
                <button
                  className="btn border-0 reset-btn px-0"
                  onClick={resetPersonalization}
                >
                  <span className="restore-page-icon"></span>
                </button>
              </OverlayTrigger>
            </Col>
            {/* date type dropdown */}
            <Col xs={6} md={2} lg={2} xl={1} className="mb-3">
              <select
                className="filter form-select"
                value={dateType}
                onChange={(e) => handleDateTypeChange(e.target.value)}
              >
                <option value="Y">YTD</option>
                <option value="Q">QTD</option>
                <option value="M">MTD</option>
              </select>
            </Col>
            {/* Date Picker */}
            <Col xs={6} md={2} lg={2} xl={1} className="mb-3">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MMM/yyyy"
                maxDate={lastDayofPreviousMonth}
                showMonthYearPicker
                wrapperClassName="datepicker"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {trirStatus === 200 && trirResponse ? (
        <>
          <Row className="g-2">
            <Col xs={12}>
              <Row className="mb-3 g-2">
                <Col xs={12}>
                  <div className="parent-card-container trir blue">
                    <h3 className="semi-bold card-heading heading-uppercase mb-2">
                      Key Highlights
                    </h3>
                    <div className="key-highlights-card-container">
                      <Row className="gx-3">
                        {keyHighlights3Cards?.map(
                          (card: any, index: number) => (
                            <KeyHighlightsTRIR
                              card={card}
                              key={index}
                              index={index}
                            />
                          )
                        )}
                      </Row>
                      <Row className="gx-3">
                        <Col xs={12} md={6} xl={6} className="mb-3">
                          <div className="card-details trirHL hover-effect">
                            <div className="card-details-header">
                              <p className="regular-text semi-bold">
                                High & Low TRIR{" "}
                                {dateType === "Q"
                                  ? "Quarter"
                                  : dateType === "M"
                                  ? "Month"
                                  : "Year"}
                              </p>
                            </div>
                            <div className="card-details-body">
                              <Row className="gx-3 mb-2 align-items-center justify-content-center">
                                <Col xs={2} className="offset-2">
                                  <p className="border-box-heading semi-bold">
                                    {dateType === "Q"
                                      ? "Quarter"
                                      : dateType === "M"
                                      ? "Month"
                                      : "Year"}
                                  </p>
                                </Col>
                                <Col xs={2}>
                                  <p className="border-box-heading align-items-center semi-bold">
                                    TRIR Rate
                                  </p>
                                </Col>
                                <Col xs={2}>
                                  <p className="border-box-heading align-items-center semi-bold">
                                    Recordable Count
                                  </p>
                                </Col>
                                <Col xs={2}>
                                  <p className="border-box-heading align-items-center semi-bold">
                                    Total Hours
                                  </p>
                                </Col>
                              </Row>
                              {highLowTRIRRate
                                ? highLowTRIRRate?.map(
                                    (card: any, index: any) => (
                                      <Row
                                        key={index}
                                        className="mb-2 justify-content-center align-items-center gx-2 gx-lg-4"
                                      >
                                        <Col xs={2}>
                                          <p className="left-text high-low">
                                            {index === 0 ? "High" : "Low"}
                                          </p>
                                        </Col>
                                        <Col xs={2}>
                                          <div className="border-box gray">
                                            <p className="text semi-bold">
                                              {card?.date
                                                ? card?.date
                                                : "No Data"}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col xs={2}>
                                          <div className="border-box gray">
                                            <p className="text semi-bold">
                                              {card?.trirPer
                                                ? card?.trirPer?.toFixed(2)
                                                : 0}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col xs={2}>
                                          <div className="border-box gray">
                                            <p className="text semi-bold">
                                              {card?.recordableCount
                                                ? card?.recordableCount
                                                : 0}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col xs={2}>
                                          <div className="border-box gray">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>
                                                  {card?.totalHours
                                                    ? formatNumberWithCommas(
                                                        card?.totalHours
                                                      )
                                                    : 0}
                                                </Tooltip>
                                              }
                                              placement="bottom"
                                            >
                                              <p className="text semi-bold">
                                                {card?.totalHours
                                                  ? (
                                                      card?.totalHours / 1000000
                                                    )?.toFixed(2)
                                                  : 0}{" "}
                                                M
                                              </p>
                                            </OverlayTrigger>
                                          </div>
                                        </Col>
                                      </Row>
                                    )
                                  )
                                : null}
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} md={6} xl={6} className="mb-3 mb-md-0">
                          <div className="card-details">
                            <div className="card-details-header">
                              <p className="regular-text semi-bold">
                                High & Low TRIR Rollup Job
                              </p>
                            </div>
                            <div className="card-details-body">
                              <Row className="gx-3 mb-2 align-items-center justify-content-center">
                                <Col xs={2} className="offset-2">
                                  <p className="border-box-heading semi-bold">
                                    Rollup Job
                                  </p>
                                </Col>
                                <Col xs={2}>
                                  <p className="border-box-heading align-items-center semi-bold">
                                    TRIR Rate
                                  </p>
                                </Col>
                                <Col xs={2}>
                                  <p className="border-box-heading align-items-center semi-bold">
                                    Recordable Count
                                  </p>
                                </Col>
                                <Col xs={2}>
                                  <p className="border-box-heading align-items-center semi-bold">
                                    Total Hours
                                  </p>
                                </Col>
                              </Row>
                              {highLowRollupJob
                                ? highLowRollupJob?.map(
                                    (card: any, index: any) => (
                                      <Row
                                        key={index}
                                        className="mb-2 justify-content-center align-items-center gx-2 gx-lg-4"
                                      >
                                        <Col xs={2}>
                                          <p className="left-text high-low">
                                            {index === 0 ? "High" : "Low"}
                                          </p>
                                        </Col>
                                        <Col xs={2}>
                                          <div className="border-box gray">
                                            <p className="text semi-bold">
                                              {card?.jobCode
                                                ? card?.jobCode
                                                : "No Data"}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col xs={2}>
                                          <div className="border-box gray">
                                            <p className="text semi-bold">
                                              {card?.trirPer
                                                ? card?.trirPer?.toFixed(2)
                                                : 0}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col xs={2}>
                                          <div className="border-box gray">
                                            <p className="text semi-bold">
                                              {card?.recordableCount
                                                ? card?.recordableCount
                                                : 0}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col xs={2}>
                                          <div className="border-box gray">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>
                                                  {card?.totalHours
                                                    ? formatNumberWithCommas(
                                                        card?.totalHours
                                                      )
                                                    : 0}
                                                </Tooltip>
                                              }
                                              placement="bottom"
                                            >
                                              <p className="text semi-bold">
                                                {card?.totalHours
                                                  ? (
                                                      card?.totalHours / 1000000
                                                    )?.toFixed(2)
                                                  : 0}{" "}
                                                M
                                              </p>
                                            </OverlayTrigger>
                                          </div>
                                        </Col>
                                      </Row>
                                    )
                                  )
                                : null}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="row gx-3"
                >
                  {cards?.length > 0
                    ? cards?.map((card, index) => (
                        <Draggable
                          key={card?.id}
                          draggableId={card?.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className={`col-xs-12 ${card?.colSize} mb-3 ${
                                card?.childCardData
                                  ? card?.childCardData?.length <= 2
                                    ? "col-md-6"
                                    : ""
                                  : ""
                              }`}
                            >
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="details-chart-container">
                                  <div className="chart-header">
                                    <h5 className="semi-bold mb-0 details-heading">
                                      {card?.id === "card1"
                                        ? `${card?.heading} ${
                                            toggleDisplay
                                              ? "Breakdown"
                                              : "Comparision"
                                          } by ${
                                            dateType === "M"
                                              ? "Month"
                                              : dateType === "Q"
                                              ? "Quarter"
                                              : "Year"
                                          }`
                                        : card?.heading}
                                    </h5>
                                    <div className="card-icons">
                                      {card?.id === "card1" ? (
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip>Comparision</Tooltip>
                                          }
                                          placement="top"
                                        >
                                          <span
                                            className="comparision-icon"
                                            onClick={handletoggleDisplay}
                                          ></span>
                                        </OverlayTrigger>
                                      ) : null}
                                      {card?.childCardData ? null : (
                                        <OverlayTrigger
                                          overlay={<Tooltip>Expand</Tooltip>}
                                          placement="top"
                                        >
                                          <span
                                            className="expand-icon"
                                            onClick={() =>
                                              handleShowModal(card.id)
                                            }
                                          ></span>
                                        </OverlayTrigger>
                                      )}
                                      <OverlayTrigger
                                        overlay={<Tooltip>Move Cards</Tooltip>}
                                        placement="top"
                                      >
                                        <span className="move-icon"></span>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  {card?.childCardData &&
                                  card?.dataAvailable ? (
                                    <div className="p-3 pb-2 pb-xl-3">
                                      <Row className="gx-3 gx-10px">
                                        {card?.childCardData?.map(
                                          (child: any, index: any) => (
                                            <Col
                                              key={index}
                                              className={`mb-2 mb-xl-0 ${
                                                trirResponse?.Subbu_hours
                                                  ?.length === 1
                                                  ? "col-12 col-md-12 col-xl-12"
                                                  : trirResponse?.Subbu_hours
                                                      ?.length === 2
                                                  ? "col-12 col-md-6 col-xl-6"
                                                  : trirResponse?.Subbu_hours
                                                      ?.length === 3
                                                  ? "col-12 col-md-6 col-xl-4"
                                                  : "col-12 col-md-6 col-xl-3"
                                              }`}
                                            >
                                              <div className="card-details">
                                                <div className="card-details-header">
                                                  <p className="regular-text semi-bold">
                                                    {child?.heading}
                                                  </p>
                                                </div>
                                                <div
                                                  className={`card-details-body ${
                                                    trirResponse?.Subbu_hours
                                                      ?.length === 1 ||
                                                    trirResponse?.Subbu_hours
                                                      ?.length === 2
                                                      ? "height-221-ni"
                                                      : "height-300"
                                                  }`}
                                                >
                                                  {child?.chartData ? (
                                                    <BarChart
                                                      chartData={
                                                        child?.chartData
                                                      }
                                                      chartOptions={
                                                        child?.chartOptions
                                                      }
                                                      axisText1={
                                                        child?.axisText1
                                                      }
                                                      decimals={child?.decimals}
                                                      gridRequired={
                                                        child?.gridRequired
                                                      }
                                                      pluginsAvailable={
                                                        child?.plugins
                                                      }
                                                    />
                                                  ) : null}
                                                </div>
                                              </div>
                                            </Col>
                                          )
                                        )}
                                      </Row>
                                    </div>
                                  ) : card?.dataAvailable &&
                                    card?.chartData &&
                                    isValidDataset(
                                      card?.chartData?.datasets
                                    ) ? (
                                    <div className="chart d-flex justify-content-center">
                                      {card?.id === "card1" ? (
                                        toggleDisplay ? (
                                          <LineChart
                                            chartData={card?.chartData}
                                            chartOptions={card?.chartOptions}
                                            axisText1={card?.axisText1}
                                            axisText2={card?.axisText2}
                                            pluginsAvailable={card?.plugins}
                                          />
                                        ) : (
                                          <BarChart
                                            chartData={tFLTComparisionbyMonth}
                                            chartOptions="verticleBaroptions"
                                            axisText1="TRIR, Fatalities & Lost Time Rate"
                                            decimals={1}
                                            gridRequired={true}
                                            pluginsAvailable={true}
                                            barValuePosition="end"
                                          />
                                        )
                                      ) : card?.heading ===
                                        "Job Count Breakdown" ? (
                                        <Col md={12} className="h-75">
                                          {card?.chartType === "pie" &&
                                            card?.chartData && (
                                              <Pie
                                                data={card?.chartData}
                                                options={card?.chartOptions}
                                              />
                                            )}
                                          <div className="mb-2">
                                            <ul className="job-count-list mt-2 mb-2">
                                              {jobcounts?.[0].data?.map(
                                                (item: any, index: any) => (
                                                  <li key={index}>
                                                    <div
                                                      style={{
                                                        backgroundColor:
                                                          colors[index],
                                                      }}
                                                    ></div>
                                                    Recordable Count{" "}
                                                    <span>
                                                      {jobCountLabels[index]}
                                                    </span>
                                                    : {item}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                          <p className="body-text text-center ms-1 semi-bold total-count">
                                            Total Job Count: {totalJobCount}
                                          </p>
                                        </Col>
                                      ) : (
                                        <>
                                          {card?.chartType === "bar" && (
                                            <BarChart
                                              chartData={card?.chartData}
                                              chartOptions={card?.chartOptions}
                                              axisText1={card?.axisText1}
                                              axisText2={card?.axisText2}
                                              decimals={card?.decimals}
                                              gridRequired={card?.gridRequired}
                                              pluginsAvailable={card?.plugins}
                                            />
                                          )}
                                          {card?.chartType === "line" && (
                                            <LineChart
                                              chartData={card?.chartData}
                                              chartOptions={card?.chartOptions}
                                              axisText1={card?.axisText1}
                                              axisText2={card?.axisText2}
                                              pluginsAvailable={card?.plugins}
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="no-data-alert">
                                      <p className="text">No Data Available</p>
                                    </div>
                                  )}
                                </div>

                                <Modal
                                  size="lg"
                                  aria-labelledby="contained-modal-title-vcenter"
                                  centered
                                  show={
                                    showModal && selectedCardId === card?.id
                                  }
                                  onHide={handleClose}
                                  className={`${
                                    theme === "dark" ? "dark-theme" : ""
                                  }`}
                                >
                                  <Modal.Header>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                      {card?.id === "card1"
                                        ? `${card?.heading} ${
                                            toggleDisplay
                                              ? "Breakdown"
                                              : "Comparision"
                                          } by ${
                                            dateType === "M"
                                              ? "Month"
                                              : dateType === "Q"
                                              ? "Quarter"
                                              : "Year"
                                          }`
                                        : card?.heading}
                                    </Modal.Title>
                                    <span
                                      className="material-symbols-outlined float-end"
                                      onClick={handleClose}
                                    >
                                      close
                                    </span>
                                  </Modal.Header>
                                  <Modal.Body>
                                    {card?.dataAvailable &&
                                    card?.chartData &&
                                    isValidDataset(
                                      card?.chartData?.datasets
                                    ) ? (
                                      <div className="chart d-flex justify-content-center">
                                        {card?.id === "card1" ? (
                                          toggleDisplay ? (
                                            <LineChart
                                              chartData={card?.chartData}
                                              chartOptions={card?.chartOptions}
                                              axisText1={card?.axisText1}
                                              axisText2={card?.axisText2}
                                              pluginsAvailable={card?.plugins}
                                            />
                                          ) : (
                                            <BarChart
                                              chartData={tFLTComparisionbyMonth}
                                              chartOptions="multiTypeChartOptions"
                                              axisText1="TRIR, Fatalities & Lost Time Rate"
                                              decimals={1}
                                              gridRequired={true}
                                              pluginsAvailable={true}
                                            />
                                          )
                                        ) : card?.heading ===
                                          "Job Count Breakdown" ? (
                                          <Col md={12} className="h-75">
                                            {card?.chartType === "pie" &&
                                              card?.chartData && (
                                                <Pie
                                                  data={card?.chartData}
                                                  options={card?.chartOptions}
                                                />
                                              )}
                                            <div className="mb-2 text-center">
                                              <ul className="job-count-list mt-2 mb-2">
                                                {jobcounts?.[0].data?.map(
                                                  (item: any, index: any) => (
                                                    <li key={index}>
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            colors[index],
                                                        }}
                                                      ></div>
                                                      Recordable Count{" "}
                                                      <span>
                                                        {jobCountLabels[index]}
                                                      </span>
                                                      : {item}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </div>
                                            <p className="body-text text-center ms-1 semi-bold total-count">
                                              Total Job Count: {totalJobCount}
                                            </p>
                                          </Col>
                                        ) : (
                                          <>
                                            {card.chartType === "bar" && (
                                              <BarChart
                                                chartData={card?.chartData}
                                                chartOptions={
                                                  card?.chartOptions
                                                }
                                                axisText1={card?.axisText1}
                                                axisText2={card?.axisText2}
                                                decimals={card?.decimals}
                                                gridRequired={
                                                  card?.gridRequired
                                                }
                                                pluginsAvailable={card?.plugins}
                                              />
                                            )}
                                            {card?.chartType === "line" && (
                                              <LineChart
                                                chartData={card?.chartData}
                                                chartOptions={
                                                  card?.chartOptions
                                                }
                                                axisText1={card?.axisText1}
                                                axisText2={card?.axisText2}
                                                pluginsAvailable={card?.plugins}
                                              />
                                            )}
                                            {card?.chartType === "pie" &&
                                              card?.chartData && (
                                                <Pie
                                                  data={card?.chartData}
                                                  options={card?.chartOptions}
                                                />
                                              )}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="no-data-alert">
                                        <p className="text">
                                          No Data Available
                                        </p>
                                      </div>
                                    )}
                                  </Modal.Body>
                                </Modal>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : null}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <Row className="h-100vh">
          <Col xs={12}>
            <Alert variant="warning">
              <Alert.Heading>No Data Available!</Alert.Heading>
              <p>For the selected filters, Please modify the filters</p>
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};
