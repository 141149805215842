// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row .kpi-card-column {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1024px) {
  .row .kpi-card-column {
    padding-left: 7.5px;
    padding-right: 7.5px;
    margin-bottom: 15px;
  }
  .px-5px {
    padding-left: 4.5px;
    padding-right: 4.5px;
  }
}
.aggregate.chart-container {
  height: 104px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Content/KPICards.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EACA,mBAAA;AACJ;;AAEA;EACI;IACI,mBAAA;IACA,oBAAA;IACA,mBAAA;EACN;EACE;IACI,mBAAA;IACA,oBAAA;EACN;AACF;AAEA;EACI,wBAAA;AAAJ","sourcesContent":[".row .kpi-card-column {\n    padding-left: 10px;\n    padding-right: 10px;\n    margin-bottom: 20px;\n}\n\n@media screen and (min-width: 1024px) {\n    .row .kpi-card-column {\n        padding-left: 7.5px;\n        padding-right: 7.5px;\n        margin-bottom: 15px;\n    }\n    .px-5px {\n        padding-left: 4.5px;\n        padding-right: 4.5px;\n    }\n}\n\n.aggregate.chart-container {\n    height: 104px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
