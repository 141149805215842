import { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
interface Props {
  heading: string;
  data: any;
}

export const KeyHilightsVol: React.FC<Props> = ({ heading, data }) => {
  const [highObject, setHighObject] = useState<any>(null);
  const [lowObject, setLowObject] = useState<any>(null);
  useEffect(() => {
    const sortedData =
      data?.length > 1
        ? data
            ?.slice()
            ?.sort((a: any, b: any) => a.turnoverRate - b.turnoverRate)
        : data;
    const subbuHigh =
      sortedData && sortedData?.length > 1
        ? sortedData[sortedData?.length - 1]
        : sortedData?.length === 1
        ? sortedData?.length[0]
        : undefined;
    const subbuLow =
      sortedData && sortedData?.length >= 0 ? sortedData[0] : undefined;
    setHighObject(subbuHigh);
    setLowObject(subbuLow);
  }, [data]);

  return (
    <div className="card-details">
      <div className="card-details-header">
        <p className="regular-text semi-bold">{heading}</p>
      </div>
      <div className="card-details-body">
        <Row className="gx-2 align-items-center justify-content-center">
          <Col xs={3} className="offset-5">
            <p className="border-box-heading semi-bold">
              {highObject?.description}
            </p>
          </Col>
          <Col xs={3}>
            <p className="border-box-heading semi-bold">
              {lowObject?.description}
            </p>
          </Col>
        </Row>
        <Row className="mb-1 gx-3 align-items-center justify-content-center">
          <Col xs={5}>
            <p className="left-text">
              Turnover Rate&nbsp;
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    {highObject?.turnoverRateCal
                      ? highObject?.turnoverRateCal
                      : ""}
                  </Tooltip>
                }
                placement="bottom"
                //delayShow={300}
                //delayHide={150}
              >
                <span className="info-icon"></span>
              </OverlayTrigger>
            </p>
          </Col>
          <Col xs={3}>
            <div className="border-box red">
              <p className="text semi-bold">
                {highObject?.turnoverRate ? highObject?.turnoverRate : 0}%
              </p>
            </div>
          </Col>
          <Col xs={3}>
            <div className="border-box green">
              <p className="text semi-bold">
                {lowObject?.turnoverRate ? lowObject?.turnoverRate : 0}%
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mb-1 gx-3 align-items-center justify-content-center">
          <Col xs={5}>
            <p className="left-text">Left Count</p>
          </Col>
          <Col xs={3}>
            <div className="border-box red">
              <p className="text semi-bold">
                {highObject?.leftCount ? highObject?.leftCount : 0}
              </p>
            </div>
          </Col>
          <Col xs={3}>
            <div className="border-box green">
              <p className="text semi-bold">
                {lowObject?.leftCount ? lowObject?.leftCount : 0}
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mb-1 gx-3 align-items-center justify-content-center">
          <Col xs={5}>
            <p className="left-text">Avg Active Count</p>
          </Col>
          <Col xs={3}>
            <div className="border-box red">
              <p className="text semi-bold">
                {highObject?.avgActiveCount ? highObject?.avgActiveCount : 0}
              </p>
            </div>
          </Col>
          <Col xs={3}>
            <div className="border-box green">
              <p className="text semi-bold">
                {lowObject?.avgActiveCount ? lowObject?.avgActiveCount : 0}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
