import {configureStore} from '@reduxjs/toolkit';
import authReducer from "./authSlice";
import userReducer from './userSlice';
import cardReducer from './cardSlice';
import progressReducer from './progressSlice'
import marketSlice from './marketSlice';
const store = configureStore({
    reducer:{
        auth:authReducer,
        user:userReducer,        
        card: cardReducer,
        progress:progressReducer,
        market:marketSlice,
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export default store;