import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { loginRequest } from "./authConfig";

export const LoginFun = () => {
  //MSAL configuration
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  //handle login page redirection
  useEffect(() => {
    handleRedirect();
  }, [isAuthenticated, instance]);
  const handleRedirect = async () => {
    if (!isAuthenticated) {
      await instance
        .loginRedirect({
          ...loginRequest,
          prompt: "create",
        })
        .catch((error) => console.log(error));
    }
  };

  return null;
};
