import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
//Props
interface Props {
  item: string;
}
export const BreadCrumbComponent: React.FC<Props> = ({ item }) => {
  return (
    <Breadcrumb className="breadcrumb-parent">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/dashboard" }}>
        Dashboard
      </Breadcrumb.Item>
      <Breadcrumb.Item active>{item}</Breadcrumb.Item>
    </Breadcrumb>
  );
};
