import { Line } from "react-chartjs-2";
import { ChartData } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { formatNumberWithCommasDecimals } from "../../../../utils/numberFormatter";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface Props<T> {
  chartData: ChartData<"line">;
  chartOptions?: string;
  axisText1?: string;
  axisText2?: string;
  pluginsAvailable?: boolean;
}
export const LineChart = <T,>({
  chartData,
  chartOptions,
  axisText1,
  axisText2,
  pluginsAvailable,
}: Props<T>) => {
  const theme: any = useSelector((state: RootState) => state.card.darkTheme);
  const dashboardOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: -5,
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: axisText1,
          font: {
            size: 11,
          },
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        ticks: {
          font: {
            size: 11,
          },

          callback: (value: any, index: any, values: any) => {
            return formatNumberWithCommasDecimals(value * 1, 1);
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 11,
          },
        },
        offset: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = "";
            if (context.parsed.y) {
              label = formatNumberWithCommasDecimals(context.parsed.y * 1);
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };
  //square line chart options
  const squareLineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 70,
      },
    },
    plugins: {
      datalabels: {
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return `${
            value > 0 ? formatNumberWithCommasDecimals(value * 1000000, 0) : "0"
          }`;
        },
        color: theme === "light" ? "#000" : "#fff",
        align: "right" as const,
      },
      //to display the actual values in tooltip
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = "";
            if (context.parsed.y) {
              label =
                formatNumberWithCommasDecimals(context.parsed.y * 1000000, 0) +
                "";
            }
            return label;
          },
        },
      },
      legend: {
        display: true,
        position: "top" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 12,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        Axes: [{ stacked: true }],

        grid: {
          tickBorderDash: [2, 3],
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
        title: {
          display: true,
          text: axisText1,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        ticks: {
          font: {
            size: 11,
          },
        },
      },
    },
  };
  const areaChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return `${value > 0 ? value : "0"}`;
        },
        color: theme === "light" ? "#000" : "#fff",
        align: "left" as const,
      },
      legend: {
        display: true,
        position: "top" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,
          font: {
            size: 11,
          },
        },
      },
    },
    scales: {
      // to remove the labels
      x: {
        ticks: {
          display: true,
          size: 11,
        },
        offset: true,
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: axisText1,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        ticks: {
          font: {
            size: 11,
          },
        },
        grid: {
          display: true,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
      },
    },
  };
  return (
    <Line
      data={chartData}
      options={
        chartOptions === "areaChartOptions"
          ? areaChartOptions
          : chartOptions === "squareLineChartOptions"
          ? squareLineChartOptions
          : dashboardOptions
      }
      plugins={pluginsAvailable ? [ChartDataLabels] : []}
    />
  );
};
