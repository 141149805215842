import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface UserState{
    userName: string | null;
    userDetails:{
        email: string;                
        lastLogin:any;
        unitDesc:string;
        bussUnit: string;
    } | null;   
    bussUnit:string | null; 
    buList: [] | null; 
    userStatus: number | null;
    userResponseMessage: string | null;
    subBUFirstId:string|null;
    subBUId: string | null;
    companyList:[]|null;
    subUnitList:any[]|null;
    selectedDateType:string;
    selectedMonthYear:{
        month: number,
        year: number,
    },
    
    buType:string | null;
}

const initialState: UserState={
    userName:null,
    userDetails:null,
    bussUnit:null,
    buList:null,
    userStatus:null,
    userResponseMessage:null,
    subBUId:null,
    subBUFirstId:null,
    companyList:null,
    subUnitList:null,
    selectedMonthYear:{
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    },
    
    selectedDateType:"Y",
    buType:null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        setUserDetails(state, action:PayloadAction<{email:string; userName:string;bussUnit:string; lastLogin:any,unitDesc:string}>){
            state.userDetails = action.payload;
        },
        setBussUnit(state, action:PayloadAction<string>){
            state.bussUnit=action.payload;
        },
        setBUList(state, action:PayloadAction<[]>){
            state.buList=action.payload;
        },
        setUserStatus(state, action:PayloadAction<number>){
            state.userStatus=action.payload;
        },
        setUserResponseMessage(state, action:PayloadAction<string>){
            state.userResponseMessage=action.payload;
        },
        setUserName(state, action:PayloadAction<string>){
            state.userName=action.payload;
        },
        setSubBUId(state, action:PayloadAction<string>){
            state.subBUId=action.payload;
        },
        setSubBUFirstId(state, action:PayloadAction<string>){
            state.subBUFirstId=action.payload;
        },
        setCompanyList(state, action:PayloadAction<[]>){
            state.companyList=action.payload;
        },
        setSubUnitList(state, action:PayloadAction<any[]>){
            state.subUnitList=action.payload;
        },
        setSelectedMonthYear(state, action:PayloadAction<{month:number,year:number}>){
            state.selectedMonthYear=action.payload;
        },


        setSelectedDateType(state, action:PayloadAction<string>){
            state.selectedDateType=action.payload;
        },
        setBUType(state, action:PayloadAction<string>){
            state.buType=action.payload;
        },
    }
});

export const {setUserName,setUserDetails, setBussUnit, setBUList, setUserStatus, setUserResponseMessage,setSubBUId,setSubBUFirstId,setCompanyList,setSubUnitList,setSelectedMonthYear,setSelectedDateType,setBUType} = userSlice.actions;
export default userSlice.reducer;

