import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//Theme change context imports
import { useContext } from "react";
import { ThemeContext } from "./Theme";

//components import
import { DashboardContainer } from "./components/Dashboard/DashboardContainer";
import { NotFound } from "./components/NotFound";
import { Reports } from "./components/Dashboard/Content/Reports";
import { ComingSoon } from "./components/Dashboard/Content/ComingSoon";
// import { AttritionDetails } from "./components/Details/AttritionDetails";
// import { AggregateDetails } from "./components/Details/AggregateDetails";
import { VoluntaryDetails } from "./components/Details/VoluntaryDetails";
import { TopNavbar } from "./components/Dashboard/TopNavbar";
import {
  LeftSidebar,
  SidebarProvider,
} from "./components/Dashboard/LeftSidebar";

//login related imports
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Container, Spinner } from "react-bootstrap";
import { LoginFun } from "./LoginFun";
import { UnAuthorisedPage } from "./UnAuthorisedPage";
import { UserData } from "./UserData";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { GrossProfitDetails } from "./components/Details/GrossProfitDetails";
import { TRIRDetails } from "./components/Details/TRIRDetails";
import { NetExpensesDetail } from "./components/Details/NetExpDetails";
import { NetIncomeDetails } from "./components/Details/NetIncomeDetails";
import { TotalBookedBacklog } from "./components/Details/TotalBookedBacklog";

//main APP
const App = ({ instance }: any) => {
  //Theme change context
  const { theme } = useContext(ThemeContext);
  const userStatus = useSelector((state: RootState) => state.user.userStatus);
  const userMessage = useSelector(
    (state: RootState) => state.user.userResponseMessage
  );

  //console.log("test", userStatus, userMessage);
  return (
    <MsalProvider instance={instance}>
      {/* If authentication is successful, will display below template */}
      <AuthenticatedTemplate>
        <div className={`App theme--${theme}`}>
          <div className="base">
            {/* Main Content */}
            <UserData />
            {userStatus === null || userStatus === undefined ? (
              <div className="global-loading d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="danger" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : userStatus === 401 || userStatus === 403 ? (
              <UnAuthorisedPage />
            ) : (
              <Router>
                <Container fluid className="dashboard">
                  <div className="mt-5"></div>
                  <SidebarProvider>
                    {/* Top header component*/}
                    <TopNavbar />
                    {/* Left sidebar component */}
                    <LeftSidebar />
                  </SidebarProvider>

                  <Routes>
                    <Route path="/" element={<DashboardContainer />} />
                    <Route path="/dashboard" element={<DashboardContainer />} />
                    <Route path="/reports" element={<ComingSoon />} />
                    <Route path="/reference" element={<ComingSoon />} />
                    <Route path="/feeds" element={<ComingSoon />} />
                    <Route path="/recommendations" element={<ComingSoon />} />
                    <Route
                      path="/dashboard/gp-details"
                      element={<GrossProfitDetails />}
                    />
                    <Route
                      path="/dashboard/net-expense-details"
                      element={<NetExpensesDetail />}
                    />
                    <Route
                      path="/dashboard/net-income-details"
                      element={<NetIncomeDetails />}
                    />
                    {/* <Route
                      path="/dashboard/attrition-details"
                      element={<AttritionDetails />}
                    /> */}
                    <Route
                      path="/dashboard/trir-details"
                      element={<TRIRDetails />}
                    />
                    <Route
                      path="/dashboard/voluntary-details"
                      element={<VoluntaryDetails />}
                    />
                    <Route
                      path="/dashboard/total-booked-backlog-details"
                      element={<TotalBookedBacklog />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Container>
              </Router>
            )}
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginFun />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;
