import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
const doughnutOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutoutPercentage: 65,
  rotation: -90,
  circumference: 180,
  cutout: "80%",
  plugins: {
    legend: {
      display: false,
    },
  },
};
interface Props {
  actualVal: number;
  plannedVal: number;
  varianceVal: any;
  loading: boolean;
  heading: string;
}
export const ActualvsPlanned: React.FC<Props> = ({
  actualVal,
  plannedVal,
  varianceVal,
  loading,
  heading,
}) => {
  //importing theme from redux toolkit to change the chart colors in dark mode
  const theme = useSelector((state: RootState) => state.card.darkTheme);
  //store gauge chart target angle position
  const [targetAngle, setTargetAngle] = useState(0);
  //store chart Data
  const [gaugeChartData, setGaugeChartData] = useState<any>(null);
  useEffect(() => {
    const maxAngle = 180;
    //set target angle
    const maxValue = plannedVal * 1.25;
    const revenueAPIncrement = maxAngle / maxValue;
    let targetAngle = plannedVal * revenueAPIncrement + 90;

    //datasets

    setTargetAngle(targetAngle);
    const formattedRevenueAP = {
      labels: ["Actual", "Maximum"],
      datasets: [
        {
          data: [actualVal, maxValue - actualVal],
          backgroundColor: ["#4596b2", "#ebeeef"],
          borderWidth: 0,
          borderColor: "#fff",
          fill: false,
        },
      ],
    };
    setGaugeChartData(formattedRevenueAP);
  }, [actualVal, plannedVal]);
  const formatNumberWithCommas = (value: any): string => {
    if (typeof value !== "number" || isNaN(value)) {
      return value;
    }
    return new Intl.NumberFormat("en-US").format(value);
  };
  return (
    <>
      {!loading ? (
        <Col xs={12} md={6} xl={3} className="mb-2 mb-xl-0">
          <div className="card-details">
            <div className="card-details-header">
              <p className="regular-text semi-bold">{heading}</p>
            </div>
            <div className="card-details-body">
              {actualVal && plannedVal && varianceVal ? (
                <Row>
                  <Col xs={7}>
                    <div className="card-details-chart-gp-actual-planned">
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            Actual:{" "}
                            {actualVal < 0
                              ? `(${formatNumberWithCommas(actualVal * -1)})`
                              : formatNumberWithCommas(actualVal)}
                            <br />
                            Planned:{" "}
                            {plannedVal < 0
                              ? `(${formatNumberWithCommas(plannedVal * -1)})`
                              : formatNumberWithCommas(plannedVal)}
                          </Tooltip>
                        }
                        placement="top"
                      >
                        <svg
                          width="150"
                          height="100"
                          viewBox="0 0 150 100"
                          style={{
                            position: "absolute",
                            left: 0,
                            top: "10px",
                          }}
                        >
                          <line
                            x1="75"
                            y1="75"
                            x2="75"
                            y2="155"
                            stroke={theme === "light" ? "#59656e" : "#fff"}
                            strokeWidth="3"
                            transform={`rotate(${
                              targetAngle ? targetAngle : 0
                            } 75 75)`}
                          />
                          <line
                            x1="75"
                            y1="75"
                            x2="75"
                            y2="125"
                            stroke={theme === "light" ? "#fff" : "#24282b"}
                            strokeWidth="5"
                            transform={`rotate(${
                              targetAngle ? targetAngle : 0
                            } 75 75)`}
                          />
                        </svg>
                      </OverlayTrigger>
                      {gaugeChartData ? (
                        <Doughnut
                          data={gaugeChartData}
                          options={doughnutOptions}
                          width="150"
                          height="100"
                        />
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={5}>
                    <div className="mb-3"></div>
                    <Row className="gx-0 mb-2">
                      <Col xs={6}>
                        <p className="body-text semi-bold">Actual</p>
                      </Col>
                      <Col xs={6}>
                        <p className="body-text">
                          {actualVal < 0
                            ? `(${formatNumberWithCommas(actualVal * -1)})`
                            : formatNumberWithCommas(actualVal)}
                        </p>
                      </Col>
                    </Row>
                    <Row className="gx-0 mb-2">
                      <Col xs={6}>
                        <p className="body-text semi-bold">Planned</p>
                      </Col>
                      <Col xs={6}>
                        <p className="body-text">
                          {plannedVal < 0
                            ? `(${formatNumberWithCommas(plannedVal * -1)})`
                            : formatNumberWithCommas(plannedVal)}
                        </p>
                      </Col>
                    </Row>
                    <Row className="gx-0 mb-2">
                      <Col xs={6}>
                        <p className="body-text semi-bold">Variance</p>
                      </Col>
                      <Col xs={6}>
                        <p className="body-text">
                          {varianceVal < 0
                            ? `(${formatNumberWithCommas(varianceVal * -1)})`
                            : formatNumberWithCommas(varianceVal)}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <div className="no-data-alert mb-34">
                  <p className="text text-center">No Data Available</p>
                </div>
              )}
            </div>
          </div>
        </Col>
      ) : null}
    </>
  );
};
