import axios from "axios";
import "./GrossProfitDetails.scss";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//redux toolkit imports
import { RootState } from "../../redux/store";
import {
  setBUType,
  setSubBUId,
  setSelectedDateType,
  setSelectedMonthYear,
} from "../../redux/userSlice";

//util imports
import { graphColors } from "../../utils/graphColors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//table descriptions to sort the rows
import {
  summaryTableDescriptions,
  expensesTableDescriptions,
} from "./GenericComponents/TableDescriptions";
import {
  Alert,
  Col,
  OverlayTrigger,
  ProgressBar,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  LineController,
  BarController,
  ArcElement,
} from "chart.js";
import { ChartData, ChartOptions } from "chart.js/auto";
import { KeyHighlightsCard } from "./GenericComponents/KeyHighlightsCard";
import { ModalComponent } from "./GenericComponents/ModalComponent";
import { ComparisionTable } from "./GenericComponents/ComparisionTable";
import { BarChart } from "./GenericComponents/Charts/BarChart";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { monthsMap } from "../Dashboard/Content/KPIcards/MonthNames";
import { BreadCrumbComponent } from "./GenericComponents/BreadcrumbComponent";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  LineController,
  BarController,
  ArcElement
);

export const NetIncomeDetails = () => {
  const dispatch = useDispatch();
  //redux imports
  const token: any = useSelector((state: RootState) => state.auth.token);
  const user = useSelector((state: RootState) => state.user.userDetails);
  const bussUnit = useSelector((state: RootState) => state.user.bussUnit);
  const buType = useSelector((state: RootState) => state.user.buType);
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const selectedDateType: any = useSelector(
    (state: RootState) => state.user.selectedDateType
  );
  const selectedMonthYear = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );
  const companyList = useSelector((state: RootState) => state.user.companyList);
  let allCompanyIds = companyList?.map((obj: any) => obj.companyId);
  let allCompanyCodes = companyList?.map((obj: any) => obj.companyCode);
  let companyIds = allCompanyIds?.join(",");

  const subBUList = useSelector((state: RootState) => state.user.subUnitList);
  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const [progress, setProgress] = useState<number>(0);
  const [dispalyProgress, setDispalyProgress] = useState<boolean>(true);
  const [subBUName, setSubBUName] = useState<any>();

  const [selectedPillItem, setSelectedPillItem] = useState("BU");
  //BU type functionality
  const handleMenuItemClick = (buType: string) => {
    handleFilterUpdateProgress();
    setSelectedPillItem(buType);
    dispatch(setBUType(buType));
    const unitID = buType === "SU" ? subBUId : bussUnit;
    fetchNIDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: selectedCompanies,
      dateType: dateType,
      selectedBU: buType,
      selectedbussUnit: unitID,
    });
  };
  //Sub BU dropdown change functionality
  const handleSubBUChange = (subBUId: string) => {
    handleFilterUpdateProgress();
    setSubBUName(subBUId);
    dispatch(setSubBUId(subBUId));
    fetchNIDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: selectedCompanies,
      dateType: dateType,
      selectedBU: buType,
      selectedbussUnit: subBUId,
    });
  };
  //loading
  const [loading, setLoading] = useState(true);
  //store API response and status
  const [niResponse, setNIResponse] = useState<any>(null);
  const [niStatus, setNIStatus] = useState<any>(null);

  //store cards data
  const [initialCards, setInitialCards] = useState<CardItem[]>([]);
  const [cards, setCards] = useState<CardItem[]>([]);

  //store Key highlights data
  const [keyHighlights, setKeyHighlights] = useState<any>(null);

  //Store Sub BU Net Income data
  const [subUnitsNetInc, setSubUnitsNetInc] = useState<any>(null);

  const [saveCardPositionRes, setSaveCardPositionRes] = useState<any>(null);
  //card position filters
  const fetchCardPositionFilters = {
    email: user?.email,
    kpi: "netIncome",
  };
  useEffect(() => {
    if (
      fetchCardPositionFilters.email !== null &&
      fetchCardPositionFilters.email !== undefined
    ) {
      fetchCardPosition();
    }
  }, [niResponse]);
  //net income details API request on page load
  useEffect(() => {
    handleFilterUpdateProgress();
    let allCompanyIds = companyList?.map((obj: any) => obj.companyId);
    let allCompanyCodes = companyList?.map((obj: any) => obj.companyCode);
    fetchNIDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: allCompanyIds,
      dateType: selectedDateType === "Q" ? "Y" : selectedDateType,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
    setSelectedPillItem(buType ? buType : selectedPillItem);
    setSubBUName(subBUId);
    setDateType(selectedDateType === "Q" ? "Y" : selectedDateType);

    setSelectedCompanies(allCompanyIds!);
    setSelectedCompanyNames(allCompanyCodes!);
  }, [subBUFirstId]);

  const [dateType, setDateType] = useState<string>("Y");

  //current month
  const currentMonth = new Date();
  const selectedDate = new Date(
    selectedMonthYear.year,
    selectedMonthYear.month
  );
  //Date type functionality
  const handleDateTypeChange = (value: string) => {
    handleFilterUpdateProgress();
    setDateType(value);
    dispatch(setSelectedDateType(value));
    fetchNIDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: selectedCompanies,
      dateType: value,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
  };
  //Datepicker functionality
  const handleDateChange = (date: Date | null) => {
    handleFilterUpdateProgress();
    if (date) {
      const month = date.getMonth();
      const year = date!.getFullYear();
      dispatch(
        setSelectedMonthYear({
          month,
          year,
        })
      );
    }
    fetchNIDetails({
      month: date?.getMonth(),
      year: date?.getFullYear(),
      company: selectedCompanies,
      dateType: dateType,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
  };
  //multi select dropdown
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>(
    allCompanyIds ? allCompanyIds : []
  );

  const [selectedCompanyNames, setSelectedCompanyNames] = useState<number[]>(
    allCompanyCodes ? allCompanyCodes : []
  );
  const [isOpen, setIsOpen] = useState(false);
  //close multi select drop-down on outside click
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleCloseOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleCloseOutside);
    return () => {
      document.removeEventListener("mousedown", handleCloseOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  //Company multi select dropdown functionality
  const toggleCompany = (company: any) => {
    handleFilterUpdateProgress();
    const toggleCompanyIds = [];

    if (selectedCompanies.includes(company.companyId)) {
      setSelectedCompanies(
        selectedCompanies.filter((id) => id !== company.companyId)
      );
      setSelectedCompanyNames(
        selectedCompanyNames.filter((code) => code !== company.companyCode)
      );
      let companyIds = selectedCompanies.filter(
        (id) => id !== company.companyId
      );
      toggleCompanyIds.push(companyIds);
    } else {
      setSelectedCompanies([...selectedCompanies, company.companyId]);
      let companyIds = [...selectedCompanies, company.companyId];
      toggleCompanyIds.push(companyIds);
      setSelectedCompanyNames([...selectedCompanyNames, company.companyCode]);
    }
    fetchNIDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: toggleCompanyIds,
      dateType: dateType,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
  };
  //Display selected company codes in dropdown
  const renderCompanyCodes = () => {
    if (selectedCompanyNames.length === 0) {
      return <span>Filter by Company</span>;
    } else {
      let displayContent = selectedCompanyNames.slice(0, 2).join(",");
      let badge = "";
      if (selectedCompanyNames.length > 2) {
        badge = `+ <div className="company-count-circle">${
          selectedCompanyNames.length - 2
        }</div>`;
      }

      if (selectedCompanyNames.length === allCompanyIds?.length) {
        displayContent = "All Companies";
        badge = "";
      }
      return (
        <div
          className="filtered-companylist"
          dangerouslySetInnerHTML={{ __html: `${displayContent} ${badge}` }}
        ></div>
      );
    }
  };
  //interface for draggable Card and graph data
  interface ChildCard {
    chartType: string;
    heading: any;
    chartData: any;
    chartOptions?: any;
    noChildCards?: boolean;
    loading?: boolean;
    pluginsAvailable?: boolean;
    dataAvailable?: boolean;
    autoAdjustWidth?: boolean;
    axisText1?: string;
  }
  interface CardItem {
    id: string;
    heading: string;
    data?: any;
    data2?: any;
    chartOptions?: any;
    dataAvailable: boolean;
    colSize: string;
    chartType?: string;
    childCardData?: ChildCard[];
    contentType?: string;
    expandAvailable?: boolean;
    pluginsAvailable?: boolean;
    axisText1?: string;
  }
  //fetch net income details data on filters change
  const fetchNIDetails = async (payload?: any) => {
    setProgress(0);
    try {
      const { month, year, company, dateType, selectedBU, selectedbussUnit } =
        payload || {};
      const currentMonth = month !== undefined ? month + 1 : undefined;
      const selectCompIds = company?.join(",");
      const typeofBU = selectedBU ? selectedBU : buType;
      const bussUnitID = selectedbussUnit
        ? selectedbussUnit
        : typeofBU === "SU"
        ? subBUId
        : bussUnit;
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_URL}/netIncomeDetailsPage`,
          {
            dateFilter: dateType || "Y",
            type: typeofBU || "BU",
            unitId: bussUnitID,
            month: currentMonth,
            year: year,
            companies:
              selectCompIds?.length == 0 ? "" : selectCompIds || companyIds,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setNIResponse(res.data?.data);
          setNIStatus(res.data?.status);
          setLoading(false);
          //Key Highlights data
          const keyHighlights = res.data?.data?.KeyHigh;
          setKeyHighlights(keyHighlights);

          //summary Profit && Loss
          const summaryPL = res.data?.data?.SummaryCard;
          //sort the table rows
          const sortedSummaryPL =
            summaryPL?.length > 1
              ? summaryPL?.sort(
                  (a: any, b: any) =>
                    summaryTableDescriptions[a.flashDesc] -
                    summaryTableDescriptions[b.flashDesc]
                )
              : summaryPL;
          //Hightlight rows
          const highlightSummaryPL = sortedSummaryPL?.filter((obj: any) =>
            [
              "Net Inc% of Revenue",
              "Net Income% of GP",
              "Net Exp% of Net Inc",
            ].includes(obj.flashDesc)
          );
          //exclude highlight rows
          const filterdSummaryPL = sortedSummaryPL?.filter(
            (obj: any) =>
              obj.flashDesc !== "Net Inc% of Revenue" &&
              obj.flashDesc !== "Net Income% of GP" &&
              obj.flashDesc !== "Net Exp% of Net Inc"
          );

          //Expenses details card
          const expDetailCard = res.data?.data?.DetailCard;
          //sort the table rows
          const sortedExpDetailCard =
            expDetailCard?.length > 1
              ? expDetailCard?.sort(
                  (a: any, b: any) =>
                    expensesTableDescriptions[a.flashDesc] -
                    expensesTableDescriptions[b.flashDesc]
                )
              : expDetailCard;
          //Hightlight rows
          const highlightexpDetailCard = sortedExpDetailCard?.filter(
            (obj: any) =>
              [
                "Gross Expenses % of Rev.",
                "Gross Rec. % of Gross Expenses",
              ].includes(obj.flashDesc)
          );

          //exclude highlight rows
          const filterdExpDetailCard = sortedExpDetailCard?.filter(
            (obj: any) =>
              obj.flashDesc !== "Gross Expenses % of Rev." &&
              obj.flashDesc !== "Gross Rec. % of Gross Expenses"
          );

          //Sub BU Net Income vs Revenue Variance
          const netIncVSRevenueCard = res.data?.data?.IncVSRevenueCard;

          // //Initialize array to store chart data
          const netIncVSRevenueCardNetIncome: number[] = [];
          const netIncVSRevenueCardRevenue: number[] = [];
          //iterate over subUnits
          netIncVSRevenueCard?.forEach((item: any) => {
            if (item.description === "Net Income") {
              const varAmount = item.varAmt?.toFixed(0);
              netIncVSRevenueCardNetIncome.push(varAmount);
            } else if (item.description === "Revenue") {
              const varAmount = item.varAmt?.toFixed(0);
              netIncVSRevenueCardRevenue.push(varAmount);
            } else {
              console.log("Others", item.varAmt);
            }
          });
          //Chart data configuration
          //extract labels
          const incomeVSRevenueubunitLabels = Array.from(
            new Set(netIncVSRevenueCard?.map((item: any) => item.subUnit))
          );

          const netIncomeVSRevenueCardChartData = {
            labels: incomeVSRevenueubunitLabels,
            datasets: [
              {
                label: "NI",
                data: netIncVSRevenueCardNetIncome,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.red : graphColors.green;
                },
                borderColor: "#fff",
              },
              {
                label: "Rev",
                data: netIncVSRevenueCardRevenue,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.red : graphColors.green;
                },
                borderColor: "#fff",
              },
            ],
          };

          //Sub BU Net Income vs GP Variance
          const netIncVSGPCard = res.data?.data?.IncVSGPCard;

          //Initialize array to store chart data
          const netIncVSGPCardNetIncome: number[] = [];
          const netIncVSGPCardGP: number[] = [];

          netIncVSGPCard?.forEach((item: any) => {
            if (item.description === "Net Income") {
              const varAmount = item.varAmt?.toFixed(0);
              netIncVSGPCardNetIncome.push(varAmount);
            } else if (item.description === "Gross Profit") {
              const varAmount = item.varAmt?.toFixed(0);
              netIncVSGPCardGP.push(varAmount);
            } else {
              console.log("Others", item.varAmt);
            }
          });
          //Chart data configuration
          //extract labels
          const incomeVSGPbunitLabels = Array.from(
            new Set(netIncVSGPCard?.map((item: any) => item.subUnit))
          );

          const netIncomeVSGPCardChartData = {
            labels: incomeVSGPbunitLabels,
            datasets: [
              {
                label: "NI",
                data: netIncVSGPCardNetIncome,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.red : graphColors.green;
                },
                borderColor: "#fff",
              },
              {
                label: "GP",
                data: netIncVSGPCardGP,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.red : graphColors.green;
                },
                borderColor: "#fff",
              },
            ],
          };

          //Sub BU Net Expenses vs Income Variance
          const netExpVSIncomeCard = res.data?.data?.ExpVSIncomeCard;

          // //Initialize array to store chart data
          const expVSIncomeCardNetExp: number[] = [];
          const expVSIncomeCardIncome: number[] = [];

          netExpVSIncomeCard?.forEach((item: any) => {
            if (item.description === "Net Expenses") {
              const varAmount = item.varAmt?.toFixed(0);
              expVSIncomeCardNetExp.push(varAmount);
            } else if (item.description === "Net Income") {
              const varAmount = item.varAmt?.toFixed(0);
              expVSIncomeCardIncome.push(varAmount);
            } else {
              console.log("Others", item.varAmt);
            }
          });
          //Chart data configuration
          //extract labels
          const netExpVSIncomebunitLabels = Array.from(
            new Set(netExpVSIncomeCard?.map((item: any) => item.subUnit))
          );

          const netExpVSIncomeCardChartData = {
            labels: netExpVSIncomebunitLabels,
            datasets: [
              {
                label: "NE",
                data: expVSIncomeCardNetExp,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.green : graphColors.red;
                },
                borderColor: "#fff",
              },
              {
                label: "Inc",
                data: expVSIncomeCardIncome,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.red : graphColors.green;
                },
                borderColor: "#fff",
              },
            ],
          };
          //Sub BU Net Income vs Operating Income Variance
          const netIncVSOpIncCard = res.data?.data?.NetIncVSOpIncCard;

          //Initialize array to store chart data
          const netIncVSOpIncCardNetIncome: number[] = [];
          const netIncVSOpIncCardCardOpIncome: number[] = [];

          netIncVSOpIncCard?.forEach((item: any) => {
            if (item.description === "Net Income") {
              const varAmount = item.varAmt?.toFixed(0);
              netIncVSOpIncCardNetIncome.push(varAmount);
            } else if (item.description === "Operating Income") {
              const varAmount = item.varAmt?.toFixed(0);
              netIncVSOpIncCardCardOpIncome.push(varAmount);
            } else {
              console.log("Others", item.varAmt);
            }
          });
          //Chart data configuration
          //extract labels
          const netIncVSOpIncbunitLabels = Array.from(
            new Set(netIncVSOpIncCard?.map((item: any) => item.subUnit))
          );

          const netIncVSOpIncCardChartData = {
            labels: netIncVSOpIncbunitLabels,
            datasets: [
              {
                label: "NI",
                data: netIncVSOpIncCardNetIncome,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.red : graphColors.green;
                },
                borderColor: "#fff",
              },
              {
                label: "OI",
                data: netIncVSOpIncCardCardOpIncome,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.red : graphColors.green;
                },
                borderColor: "#fff",
              },
            ],
          };
          //Sub BU Operating Income
          const subUnitOpInc = res.data?.data?.SubUnitOpIncomeCard;

          const subUnitOpIncLabels = Array.from(
            new Set(subUnitOpInc?.map((item: any) => item.subUnit))
          );
          //Initialize array to store chart data
          const subUnitOpIncData: any[] = [];
          //iterate over subUnits
          subUnitOpIncLabels.forEach((subUnit) => {
            const subUnitOpIncFilteredData = subUnitOpInc?.filter(
              (obj: any) => obj.subUnit === subUnit
            );
            //Extract amounts
            const actAmount = subUnitOpIncFilteredData?.map((obj: any) =>
              obj.actAmount.toFixed(0)
            );
            const planAmount = subUnitOpIncFilteredData?.map((obj: any) =>
              obj.planAmount.toFixed(0)
            );
            const varAmount = subUnitOpIncFilteredData?.map((obj: any) =>
              obj.varAmount.toFixed(0)
            );
            //Create datasets=
            const subUnitOpIncChartData: ChartData<"bar"> = {
              labels: [""],
              datasets: [
                {
                  label: "Actual",
                  data: actAmount,
                  backgroundColor: [graphColors.yellow],
                  borderColor: "#fff",
                },
                {
                  label: "Planned",
                  data: planAmount,
                  backgroundColor: [graphColors.blue],
                  borderColor: "#fff",
                },
                {
                  label: "Variance",
                  data: varAmount,
                  backgroundColor: [
                    varAmount < 0 ? graphColors.red : graphColors.green,
                  ],
                  borderColor: "#fff",
                },
              ],
            };
            //push chart data into array
            subUnitOpIncData.push(subUnitOpIncChartData);
          });

          //Sub BU Net Income
          const subUnitNetInc = res.data?.data?.SubUnitIncomeCard;
          const subUnitNetIncLabels = Array.from(
            new Set(subUnitNetInc?.map((item: any) => item.subUnit))
          );
          //Initialize array to store chart data
          const subUnitNetIncData: any[] = [];
          //iterate over subUnits
          subUnitNetIncLabels.forEach((subUnit) => {
            const subUnitNetIncFilteredData = subUnitNetInc?.filter(
              (obj: any) => obj.subUnit === subUnit
            );
            //Extract amounts
            const actAmount = subUnitNetIncFilteredData?.map((obj: any) =>
              obj.actAmount?.toFixed(0)
            );
            const planAmount = subUnitNetIncFilteredData?.map((obj: any) =>
              obj.planAmount?.toFixed(0)
            );
            const varAmount = subUnitNetIncFilteredData?.map((obj: any) =>
              obj.varAmount?.toFixed(0)
            );
            //Create datasets=
            const subUnitNetIncChartData = {
              labels: [""],
              datasets: [
                {
                  label: "Actual",
                  data: actAmount,
                  backgroundColor: [graphColors.yellow],
                  borderColor: "#fff",
                },
                {
                  label: "Planned",
                  data: planAmount,
                  backgroundColor: [graphColors.blue],
                  borderColor: "#fff",
                },
                {
                  label: "Variance",
                  data: varAmount,
                  backgroundColor: [
                    varAmount < 0 ? graphColors.red : graphColors.green,
                  ],
                  borderColor: "#fff",
                },
              ],
            };
            //push chart data into array
            subUnitNetIncData.push(subUnitNetIncChartData);
          });
          setSubUnitsNetInc(subUnitNetIncLabels);
          //Net Income by Month
          const netIncomeByMonth = res.data?.data?.MonthWiseNetIncomeCard;
          let actAmounts: any[] = [];
          let planAmounts: any[] = [];
          let varAmounts: any[] = [];
          let labels: any[] = [];
          const sortedObjectsWithMonth = netIncomeByMonth?.sort(
            dateType === "M"
              ? (a: any, b: any) => monthsMap[a.month] - monthsMap[b.month]
              : (a: any, b: any) => a.year - b.year
          );
          sortedObjectsWithMonth?.forEach((obj: any, index: number) => {
            actAmounts.push(obj.actAmount.toFixed(0));
            planAmounts.push(obj.planAmount.toFixed(0));
            varAmounts.push(obj.varAmount.toFixed(0));
            let label: any;
            if (obj.month !== null) {
              label = obj.month;
            } else {
              label = obj.year;
            }
            if (!labels?.includes(label)) {
              labels?.push(label);
            }
          });

          //Create datasets
          const netIncomeByMonthChartData: ChartData<"bar"> = {
            labels: labels,
            datasets: [
              {
                label: "Actual",
                data: actAmounts,
                backgroundColor: [graphColors.yellow],
                borderColor: "#fff",
              },
              {
                label: "Planned",
                data: planAmounts,
                backgroundColor: [graphColors.blue],
                borderColor: "#fff",
              },
              {
                label: "Variance",
                data: varAmounts,
                backgroundColor: varAmounts?.map((value: any) =>
                  value > 0 ? graphColors.green : graphColors.red
                ),
                borderColor: "#fff",
              },
            ],
          };
          //Sub BU Operating Income child card data
          const childCardsOpInc: ChildCard[] = subUnitOpIncLabels?.map(
            (heading, index) => ({
              chartType: "bar",
              heading: heading,
              chartOptions: "verticleBaroptions",
              chartData: subUnitOpIncData[index],
              autoAdjustWidth: true,
              dataAvailable: subUnitOpInc,
              axisText1: "Op Income Amount",
              pluginsAvailable: true,
            })
          );
          //Sub BU Net Income child card data
          const childCardsNetInc: ChildCard[] = subUnitNetIncLabels?.map(
            (heading, index) => ({
              chartType: "bar",
              heading: heading,
              chartOptions: "verticleBaroptions",
              chartData: subUnitNetIncData[index],
              autoAdjustWidth: true,
              dataAvailable: subUnitNetInc,
              axisText1: "Net Income Amount",
              pluginsAvailable: true,
            })
          );
          //cards array to iterate in drag and drop
          const initialCards: CardItem[] = [
            {
              id: "card1",
              heading: "Summary Profit & Loss",
              dataAvailable: summaryPL ? true : false,
              data: filterdSummaryPL,
              data2: highlightSummaryPL,
              colSize: "col-xl-6",
              contentType: "table",
              expandAvailable: true,
            },
            {
              id: "card2",
              heading: "Expense Details",
              dataAvailable: expDetailCard ? true : false,
              data: filterdExpDetailCard,
              data2: highlightexpDetailCard,
              colSize: "col-xl-6",
              contentType: "table",
              expandAvailable: true,
            },
            {
              id: "card3",
              heading: "Sub BU Net Income vs Revenue Variance",
              dataAvailable: netIncVSRevenueCard,
              data: netIncomeVSRevenueCardChartData,
              chartOptions: "horizontalStackedBaroptions",
              colSize: "col-md-6",
              chartType: "bar",
              contentType: "chart",
              expandAvailable: true,
              pluginsAvailable: true,
            },
            {
              id: "card4",
              heading: "Sub BU Net Income vs GP Variance",
              dataAvailable: netIncVSGPCard,
              data: netIncomeVSGPCardChartData,
              chartOptions: "horizontalStackedBaroptions",
              colSize: "col-md-6",
              chartType: "bar",
              contentType: "chart",
              expandAvailable: true,
              pluginsAvailable: true,
            },
            {
              id: "card5",
              heading: "Sub BU Net Income vs Expense Variance",
              dataAvailable: netExpVSIncomeCard,
              data: netExpVSIncomeCardChartData,
              chartOptions: "horizontalStackedBaroptions",
              colSize: "col-md-6",
              chartType: "bar",
              contentType: "chart",
              expandAvailable: true,
              pluginsAvailable: true,
            },
            {
              id: "card6",
              heading: "Sub BU Net Income vs Op Income Variance",
              dataAvailable: netIncVSOpIncCard,
              data: netIncVSOpIncCardChartData,
              chartOptions: "horizontalStackedBaroptions",
              colSize: "col-md-6",
              chartType: "bar",
              contentType: "chart",
              expandAvailable: true,
              pluginsAvailable: true,
            },
            {
              id: "card7",
              heading: "Sub BU Operating Income",
              dataAvailable: subUnitOpInc,
              childCardData: childCardsOpInc,
              colSize: "",
              chartType: "bar",
              contentType: "chart",
              expandAvailable: false,
              axisText1: "Op Income Amount",
            },
            {
              id: "card8",
              heading: "Sub BU Net Income",
              dataAvailable: subUnitNetInc,
              childCardData: childCardsNetInc,
              colSize: "",
              chartType: "bar",
              contentType: "chart",
              expandAvailable: false,
              axisText1: "Net Income Amount",
            },
            {
              id: "card9",
              heading: `Net Income by 
              ${
                dateType === "M"
                  ? "Month"
                  : dateType === "Q"
                  ? "Quarter"
                  : "Year"
              }`,
              dataAvailable: netIncomeByMonth,
              data: netIncomeByMonthChartData,
              chartOptions: "verticleBaroptions",
              colSize: "col-md-6",
              chartType: "bar",
              contentType: "chart",
              expandAvailable: true,
              axisText1: "Net Income Amount",
              pluginsAvailable: true,
            },
          ];
          setCards(initialCards);
          setInitialCards(initialCards);
          setProgress(100);
          setDispalyProgress(true);
        });
    } catch (error: any) {
      console.error("Error fetching net income details data", error);
      setLoading(false);
      setNIStatus(error.response?.data?.status);
      setDispalyProgress(true);
    }
  };
  //fetch saved card positions
  const fetchCardPosition = async () => {
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/fetchUserPersonalizationData`,
        fetchCardPositionFilters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const cardIds = res.data.data?.Details?.cardPosition?.split(",");
        const initialCardIDs = initialCards?.map((card) => card.id);
        const defaultORUpdatedCardIds = cardIds
          ? cardIds?.length === initialCardIDs?.length
            ? cardIds
            : initialCardIDs
          : initialCardIDs;
        const updatedCards = defaultORUpdatedCardIds?.map(
          (id: any, index: any) => {
            const matchingCard = cards?.find((card) => card?.id === id);
            return matchingCard ? { ...matchingCard } : null;
          }
        );
        setCards([...updatedCards]);
      });
  };
  //modal funcionality
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [componentType, setComponentType] = useState("");

  //show modal
  const handleShowModal = (
    content: React.ReactNode,
    title: string,
    type: string
  ) => {
    setModalContent(content);
    setModalTitle(title);
    setComponentType(type);
    setShowModal(true);
  };

  //close modal
  const handleCloseModal = () => setShowModal(false);

  //update progress value
  const handleFilterUpdateProgress = () => {
    setProgress(0);
    setDispalyProgress(false);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 10;

        if (newProgress >= 100) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 100);
  };
  if (loading) {
    return (
      <div className="global-loading d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  //drag and drop functionality
  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const newCards = Array.from(cards);
    const [reorderedCard] = newCards.splice(source.index, 1);
    newCards.splice(destination.index, 0, reorderedCard);
    setCards(newCards);
    const cardIds = newCards?.map((card) => card?.id);
    savePersonalization(cardIds);
  };

  //save personalized dashboard
  const savePersonalization = async (cardids: any) => {
    const niCardIds = cardids?.map((card: any) => card).join(",");
    const savePersonalizationFilters = {
      email: user?.email,
      kpi: "netIncome",
      position: niCardIds,
    };
    const Response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/saveUserPersonalization`,
        savePersonalizationFilters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSaveCardPositionRes(200);
        setTimeout(() => {
          setSaveCardPositionRes(0);
        }, 1000);
      });
  };

  //reset card position to default
  const resetPersonalization = () => {
    setCards(initialCards);
    const cardIds = initialCards?.map((card) => card?.id);
    savePersonalization(cardIds);
  };

  //check the chart data is valid
  const isValidDataset = (data: any[]): boolean => {
    const allValues = data?.flatMap((dataset) => dataset.data);
    return allValues?.some((item) => item !== undefined && item !== null);
  };
  return (
    <>
      <Row>
        <BreadCrumbComponent item="Net Income Details" />
        <div
          className={`progress-bar-container ${
            dispalyProgress ? "show-progress" : ""
          }`}
        >
          <ProgressBar now={progress} />
        </div>
        {saveCardPositionRes === 200 ? (
          <div className="alert alert-primary col-md-3 move-alert" role="alert">
            <p>Card position has been saved.</p>
          </div>
        ) : null}
        <div className="mb-3"></div>
        <Col xs={12}>
          <Row className="align-items-center gx-3">
            <Col xs={12} md={2} lg={2} xl={2} className="mb-3">
              <h5 className="mb-0 details-heading animate__animated animate__bounce">
                Net Income Margin %
              </h5>
            </Col>
            <Col xs={12} md={4} lg={4} xl={3} className="mb-3">
              {/* BU type pills */}
              <div className="pills">
                <div className="px-2 py-1 nav-fill border-1 nav nav-pills">
                  <div className="nav-item">
                    <a
                      onClick={() => handleMenuItemClick("BU")}
                      className={`nav-link ${
                        selectedPillItem === "BU" ? "active" : ""
                      } ${subBUList && subBUList?.length <= 1 ? "active" : ""}`}
                    >
                      Business Unit
                    </a>
                  </div>
                  <div
                    className={`nav-item ${
                      subBUList && subBUList?.length <= 1 ? "not-allowed" : ""
                    }`}
                  >
                    <a
                      onClick={() => handleMenuItemClick("SU")}
                      role="button"
                      className={`nav-link sub-bu ${
                        subBUList && subBUList?.length <= 1 ? "disabled" : ""
                      } ${
                        selectedPillItem === "SU" &&
                        subBUList &&
                        subBUList?.length > 1
                          ? "active"
                          : ""
                      }`}
                    >
                      Sub Business Unit
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            {selectedPillItem === "SU" && subBUList?.length! > 1 ? (
              <Col xs={6} md={2} lg={2} xl={2} className="mb-3">
                {/* Sub business unit dropdown */}

                <select
                  className="filter form-select width-75p"
                  value={subBUName}
                  onChange={(e) => handleSubBUChange(e.target.value)}
                >
                  {subBUList?.map((su: any) => (
                    <option value={su.subUnitId} key={su.subUnitId}>
                      {su.subUnitDesc}
                    </option>
                  ))}
                </select>
              </Col>
            ) : null}
            <Col
              xs={6}
              md={3}
              lg={3}
              xl={2}
              className={`${
                selectedPillItem === "SU" && subBUList?.length! > 1
                  ? "offset-xl-1"
                  : "offset-xl-3"
              } mb-3 offset-md-0 d-flex align-items-center justify-content-between text-right`}
            >
              {/* Reset card positions to default button */}
              <OverlayTrigger
                overlay={<Tooltip>Reset Personalization</Tooltip>}
                placement="bottom"
              >
                <button
                  className="btn border-0 reset-btn pe-3"
                  onClick={resetPersonalization}
                >
                  <span className="restore-page-icon"></span>
                </button>
              </OverlayTrigger>
              {/* Company multi select dropdown */}
              <div className="multiselect-dropdown">
                <div className="dropdown-header" onClick={toggleDropdown}>
                  {/* display selected company codes */}
                  {renderCompanyCodes()}

                  <span className="material-symbols-outlined">expand_more</span>
                </div>
                <div ref={dropdownRef} className="dropdown-options-parent">
                  {isOpen && (
                    <div className="dropdown-options">
                      {companyList?.map((company: any) => (
                        <label key={company.companyId}>
                          <input
                            type="checkbox"
                            value={company.companyId}
                            checked={selectedCompanies.includes(
                              company.companyId
                            )}
                            onChange={() => toggleCompany(company)}
                          />
                          &nbsp;{company.companyCode}
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col xs={4} md={2} lg={2} xl={1} className="mb-3">
              <select
                className="filter form-select"
                value={dateType}
                onChange={(e) => handleDateTypeChange(e.target.value)}
              >
                <option value="Y">YTD</option>
                <option value="M">MTD</option>
              </select>
            </Col>
            <Col xs={4} md={2} lg={2} xl={1} className="mb-3 ni-datepicker">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MMM/yyyy"
                maxDate={currentMonth}
                showMonthYearPicker
                wrapperClassName="datepicker"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {niStatus === 200 && niResponse ? (
        <Col xs={12}>
          <Row className="mb-3 g-2">
            <Col xs={12}>
              <div className="gp-parent-card-container key-highlights blue">
                <h3 className="semi-bold card-heading heading-uppercase mb-2">
                  Key Highlights
                </h3>
                <div className="gp-key-highlights-card-container">
                  <KeyHighlightsCard
                    actualValue={keyHighlights?.actNetIncomePerOfRev}
                    targetValue={keyHighlights?.tgtNetIncomePerOfRev}
                    actualValue2={keyHighlights?.actNetIncome}
                    actualValue3={keyHighlights?.actRevenue}
                    loading={loading}
                    heading="Net Income % of Revenue"
                    footerActualText1="Actual NI % of Rev"
                    footerActualText2="Target NI % of Rev"
                    footerActualText3="Actual NI"
                    footerActualText4="Actual Rev"
                    footer="type2"
                  />

                  <KeyHighlightsCard
                    actualValue={keyHighlights?.actNetIncomePerOfGp}
                    targetValue={keyHighlights?.tgtNetIncomePerOfGp}
                    actualValue2={keyHighlights?.actNetIncome}
                    actualValue3={keyHighlights?.actGrossProfit}
                    loading={loading}
                    heading="Net Income % of Gross Profit"
                    footerActualText1="Actual NI % of GP"
                    footerActualText2="Target NI % of GP"
                    footerActualText3="Actual NI"
                    footerActualText4="Actual GP"
                    footer="type2"
                  />
                  <KeyHighlightsCard
                    actualValue={keyHighlights?.actOpIncomePerOfNet}
                    targetValue={keyHighlights?.tgtOpIncomePerOfNet}
                    actualValue2={keyHighlights?.actNetIncome}
                    actualValue3={keyHighlights?.actOperatingIncome}
                    loading={loading}
                    heading="Net Income % of Operating Income"
                    footerActualText1="Actual NI % of Op Inc"
                    footerActualText2="Target NI % of Op Inc"
                    footerActualText3="Actual NI"
                    footerActualText4="Actual Op Inc"
                    footer="type2"
                  />
                  <KeyHighlightsCard
                    actualValue={keyHighlights?.actNetIncome}
                    targetValue={keyHighlights?.planNetIncome}
                    actualValue2={keyHighlights?.currentNetIncomeOfAnnualNet}
                    loading={loading}
                    heading="Current Net Income % of Annual Net Income"
                    footerActualText1="Current NI"
                    footerActualText2="Annual NI"
                    footerActualText3="Current NI % of Annual NI"
                    percentageOrRevenue="revenue"
                    footer="type2"
                  />
                </div>
              </div>
            </Col>
          </Row>
          {/* Drag and drop */}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="row gx-3"
                >
                  {cards?.length > 0
                    ? cards?.map((card, index) => (
                        <Draggable
                          key={card?.id}
                          draggableId={card?.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className={`col-xs-12 ${card?.colSize} mb-3 ${
                                card?.childCardData
                                  ? card?.childCardData?.length <= 2
                                    ? "col-md-6"
                                    : "col-md-12"
                                  : ""
                              }`}
                            >
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="details-chart-container">
                                  <div className="chart-header">
                                    <div className="left-content d-xl-flex align-items-center">
                                      <h5 className="semi-bold mb-0 details-heading heading-uppercase me-2">
                                        {card?.heading}
                                      </h5>
                                      <p className="body-text info-text">
                                        All the values are in thousands
                                      </p>
                                    </div>
                                    <div className="card-icons">
                                      {card?.expandAvailable ? (
                                        <OverlayTrigger
                                          overlay={<Tooltip>Expand</Tooltip>}
                                          placement="top"
                                        >
                                          <span
                                            className="expand-icon"
                                            onClick={() =>
                                              handleShowModal(
                                                card?.contentType ===
                                                  "table" ? (
                                                  <ComparisionTable
                                                    tableData={card?.data}
                                                    tableDataHighlights={
                                                      card?.data2
                                                    }
                                                  />
                                                ) : (
                                                  <BarChart
                                                    chartData={card?.data}
                                                    pluginsAvailable={
                                                      card?.pluginsAvailable
                                                    }
                                                    chartOptions={
                                                      card?.chartOptions
                                                    }
                                                    axisText1={card?.axisText1}
                                                  />
                                                ),
                                                `${card?.heading}`,
                                                card?.contentType === "chart"
                                                  ? "chart"
                                                  : "table"
                                              )
                                            }
                                          ></span>
                                        </OverlayTrigger>
                                      ) : null}

                                      <OverlayTrigger
                                        overlay={<Tooltip>Move Cards</Tooltip>}
                                        placement="top"
                                      >
                                        <span className="move-icon"></span>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div
                                    className={`chart-body ${
                                      card?.contentType === "chart" &&
                                      !card?.childCardData
                                        ? "height-300 p-3"
                                        : ""
                                    }`}
                                  >
                                    {card?.childCardData &&
                                    card?.dataAvailable ? (
                                      <div className="p-3 pb-2 pb-xl-3">
                                        <Row className="gx-10px">
                                          {card?.childCardData?.map(
                                            (child: any, index: any) =>
                                              child?.chartData &&
                                              child?.chartType === "bar" ? (
                                                <Col
                                                  key={index}
                                                  className={`mb-2 mb-xl-0 ${
                                                    child?.autoAdjustWidth &&
                                                    subUnitsNetInc?.length === 1
                                                      ? "col-12 col-md-12 col-xl-12"
                                                      : child?.autoAdjustWidth &&
                                                        subUnitsNetInc?.length ===
                                                          2
                                                      ? "col-12 col-md-6 col-xl-6"
                                                      : child?.autoAdjustWidth &&
                                                        subUnitsNetInc?.length ===
                                                          3
                                                      ? "col-12 col-md-6 col-xl-4"
                                                      : child?.autoAdjustWidth &&
                                                        subUnitsNetInc?.length ===
                                                          4
                                                      ? "col-12 col-md-6 col-xl-3"
                                                      : "col-12 col-md-6 col-xl-4"
                                                  }`}
                                                >
                                                  <div className="card-details">
                                                    <div className="card-details-header d-flex justify-content-between">
                                                      <p className="regular-text semi-bold">
                                                        {child?.heading}
                                                      </p>
                                                      <span
                                                        className="expand-icon"
                                                        onClick={() =>
                                                          handleShowModal(
                                                            <BarChart
                                                              chartData={
                                                                child?.chartData
                                                              }
                                                              pluginsAvailable={
                                                                child?.pluginsAvailable
                                                              }
                                                              chartOptions={
                                                                child?.chartOptions
                                                              }
                                                              axisText1={
                                                                card?.axisText1
                                                              }
                                                            />,
                                                            `${child?.heading}`,
                                                            "chart"
                                                          )
                                                        }
                                                      ></span>
                                                    </div>
                                                    <div
                                                      className={`card-details-body ${
                                                        (child?.autoAdjustWidth &&
                                                          subUnitsNetInc?.length ===
                                                            1) ||
                                                        (child?.autoAdjustWidth &&
                                                          subUnitsNetInc?.length ===
                                                            2)
                                                          ? "height-221-ni"
                                                          : "height-300"
                                                      }`}
                                                    >
                                                      {isValidDataset(
                                                        child?.chartData
                                                          ?.datasets
                                                      ) ? (
                                                        <BarChart
                                                          chartData={
                                                            child?.chartData
                                                          }
                                                          chartOptions={
                                                            child?.chartOptions
                                                          }
                                                          pluginsAvailable={
                                                            child?.pluginsAvailable
                                                          }
                                                          axisText1={
                                                            card?.axisText1
                                                          }
                                                        />
                                                      ) : (
                                                        <p>No Data</p>
                                                      )}
                                                    </div>
                                                  </div>
                                                </Col>
                                              ) : null
                                          )}
                                        </Row>
                                      </div>
                                    ) : card?.dataAvailable &&
                                      card?.data &&
                                      card?.contentType ? (
                                      <>
                                        {card?.contentType === "table" && (
                                          <div className="table-container pt-0 pb-2">
                                            <ComparisionTable
                                              tableData={card?.data}
                                              tableDataHighlights={card?.data2}
                                            />
                                          </div>
                                        )}
                                        {card?.chartType === "bar" &&
                                          card?.data && (
                                            <BarChart
                                              chartData={card?.data}
                                              chartOptions={card?.chartOptions}
                                              pluginsAvailable={
                                                card?.pluginsAvailable
                                              }
                                              axisText1={card?.axisText1}
                                            />
                                          )}
                                      </>
                                    ) : (
                                      <div className="no-data-alert">
                                        <p className="text">
                                          No Data Available
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : null}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      ) : (
        <Row className="h-100vh">
          <Col xs={12}>
            <Alert variant="warning">
              <Alert.Heading>No Data Available!</Alert.Heading>
              {selectedCompanies.length > 0 ? (
                <p>For the selected filters, Please modify the filters</p>
              ) : (
                <p>Please select at least one company</p>
              )}
            </Alert>
          </Col>
        </Row>
      )}
      {/* Generic Modal Component will send the data onClick */}
      <ModalComponent
        show={showModal}
        onHide={handleCloseModal}
        modalTitle={modalTitle}
        bodyContent={modalContent}
        contentType={componentType}
        thousandsText={true}
      />
    </>
  );
};
