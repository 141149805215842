import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface cardState{
    activeCardId: string | null;
    darkTheme: string | null;
}

const initialState:cardState = {
    activeCardId: "grossProfit",
    darkTheme: "light",
}
const cardSlice = createSlice({
    name: 'card',
    initialState,
    reducers:{
        setActiveCard:(state, action: PayloadAction<string|null>)=>{
            state.activeCardId = action.payload;
        },
        setDarkTheme:(state, action: PayloadAction<string|null>)=>{
            state.darkTheme = action.payload;
        }
    }
})

export const {setActiveCard,setDarkTheme} = cardSlice.actions;
export default cardSlice.reducer;