import { Fragment, useEffect, useState } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { KPICards } from "./Content/KPICards";
import { RightCardSection } from "./Content/RightCardSection";
import { DetailsTable } from "./Content/DetailsTable";
// import { LatestUpdate } from "./Content/LatestUpdate";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedMonthYear,
  setSelectedDateType,
  setBUType,
  setSubBUId,
} from "../../redux/userSlice";
import { RootState } from "../../redux/store";
//date picker imports
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./DashboardContainer.scss";
import { useMsal } from "@azure/msal-react";

export const DashboardContainer = () => {
  const dispatch = useDispatch();
  const buType = useSelector((state: RootState) => state.user.buType);
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const activeCard = useSelector(
    (state: RootState) => state.card?.activeCardId
  );
  const subBUList = useSelector((state: RootState) => state.user.subUnitList);

  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const selectedDateType = useSelector(
    (state: RootState) => state.user.selectedDateType
  );
  const selectedMonthYear = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );
  const progress = useSelector((state: RootState) => state.progress.progress);
  const displayProgress = useSelector(
    (state: RootState) => state.progress.displayProgress
  );
  //console.log(progress, displayProgress);
  const [activeCardId, setActiveCardId] = useState<string>("grossProfit");
  const [isCardActive, setIsCardActive] = useState(false);

  const handleActiveCardChange = (cardId: string, isActive: boolean) => {
    setActiveCardId(cardId);
    setIsCardActive(isActive);
  };

  //store date type YTD, QTD and MTD
  const [dateType, setDateType] = useState<string>("Y");
  //Calendar function

  //current month and year
  const currentDate = new Date();

  const selDate = new Date(selectedMonthYear.year, selectedMonthYear.month);
  //store sub BU selected name
  const [subBUName, setSubBUName] = useState<any>();

  const handleDateTypeChange = (value: string) => {
    setDateType(value);
    dispatch(setSelectedDateType(value));
  };
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const month = date.getMonth();
      const year = date!.getFullYear();
      dispatch(
        setSelectedMonthYear({
          month,
          year,
        })
      );
    }
  };
  //handle sub bu name change
  const handleSubBUChange = (subBUId: string) => {
    setSubBUName(subBUId);
    dispatch(setSubBUId(subBUId));
  };

  //State to store attrition card and table data
  // const [attrCardData, setAttrCardData] = useState<any>(null);
  // const [attrTableData, setAttrTableData] = useState<any>(null);
  //state to store attrition chart data
  // const [attritionChartData, setAttritionChartData] = useState<any>({});

  // //State to store attrition card and table data
  // const [aggregateTableData, setAggregateTableData] = useState<any>(null);
  // //state to store voluntary chart data
  // const [aggregateChartData, setAggregateChartData] = useState<any>({});

  useEffect(() => {
    setSelectedPillItem(buType ? buType : selectedPillItem);
    setSubBUName(subBUId);
    setDateType(selectedDateType);
  }, [subBUFirstId]);

  //Fetch Attrition Data
  // const sendAttrRequest = async () => {
  //   const attritionResponse = await axios
  //     .post(`${process.env.REACT_APP_API_URL}/attrition`, filters, {
  //       // headers:{
  //       //   'Authorization':`Bearer ${accessToken}`,
  //       // },
  //     })
  //     .then((res) => {
  //       //setAttrCardData(res.data.data.Card);
  //       setAttrTableData(res.data.data.Table);
  //       //console.log("attr", res);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data", error);
  //       throw error;
  //     });
  // };

  //Fetch Aggregate Data
  // interface AggregateChartObject {
  //   scoreCategory: string;
  //   surveyOverallScore: number;
  // }
  // const sendAggregateRequest = async () => {
  //   const Response = await axios
  //     .post(`${process.env.REACT_APP_API_URL}/aggregate`, filters, {
  //       // headers:{
  //       //   'Authorization':`Bearer ${accessToken}`,
  //       // },
  //     })
  //     .then((res) => {
  //       //setAggregateCardData(res.data.data.Card);
  //       setAggregateTableData(res.data.data.Table);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data", error);
  //       throw error;
  //     });
  // };
  const [selectedPillItem, setSelectedPillItem] = useState("BU");
  const handleMenuItemClick = (bu: string) => {
    setSelectedPillItem(bu);
    dispatch(setBUType(bu));
  };
  return (
    <Fragment>
      {/* Dashboard Content */}

      <Row>
        <div
          className={`progress-bar-container ${
            displayProgress ? "show-progress" : ""
          }`}
        >
          <ProgressBar now={progress} />
        </div>
        <Col xs={12}>
          <Row className="align-items-center mt-sm-4 mt-5">
            <Col xs={12} md={2} lg={2} xl={2} className="mb-3">
              <h5 className="mb-0 semi-bold">Dashboard</h5>
            </Col>
            <Col xs={12} md={4} lg={4} xl={3} className="mb-3">
              <div className="pills">
                <div className="px-2 py-1 nav-fill border-1 nav nav-pills">
                  <div className="nav-item">
                    <a
                      onClick={() => handleMenuItemClick("BU")}
                      className={`nav-link ${
                        selectedPillItem === "BU" ? "active" : ""
                      } ${subBUList && subBUList?.length <= 1 ? "active" : ""}`}
                    >
                      Business Unit
                    </a>
                  </div>
                  <div
                    className={`nav-item ${
                      subBUList && subBUList?.length <= 1 ? "not-allowed" : ""
                    }`}
                  >
                    <a
                      onClick={() => handleMenuItemClick("SU")}
                      role="button"
                      className={`nav-link sub-bu ${
                        subBUList && subBUList?.length <= 1 ? "disabled" : ""
                      } ${
                        selectedPillItem === "SU" &&
                        subBUList &&
                        subBUList?.length > 1
                          ? "active"
                          : ""
                      }`}
                    >
                      Sub Business Unit
                    </a>
                  </div>
                </div>
              </div>
            </Col>

            {/* Sub business unit list dropdown */}
            {selectedPillItem === "SU" && subBUList?.length! > 1 ? (
              <Col xs={6} md={2} lg={2} xl={2} className="mb-3">
                <select
                  className="filter form-select width-75p"
                  value={subBUName}
                  onChange={(e) => handleSubBUChange(e.target.value)}
                >
                  {subBUList?.map((su: any) => (
                    <option value={su.subUnitId} key={su.subUnitId}>
                      {su.subUnitDesc}
                    </option>
                  ))}
                </select>
              </Col>
            ) : null}

            {/* Date type dropdown */}
            <Col
              xs={6}
              md={2}
              lg={2}
              xl={2}
              className={`mb-3 ${
                selectedPillItem === "BU" || subBUList?.length! <= 1
                  ? "offset-md-2"
                  : ""
              }`}
            >
              <div className="float-md-end">
                <select
                  className="filter form-select"
                  value={dateType}
                  onChange={(e) => handleDateTypeChange(e.target.value)}
                >
                  <option value="Y">YTD</option>
                  <option value="Q">QTD</option>
                  <option value="M">MTD</option>
                </select>
              </div>
            </Col>

            <Col
              xs={6}
              md={2}
              lg={2}
              xl={1}
              className="mb-3 px-xl-1 dashboard-datepicker"
            >
              <DatePicker
                selected={selDate}
                onChange={handleDateChange}
                dateFormat="MMM/yyyy"
                maxDate={currentDate}
                showMonthYearPicker
                wrapperClassName="datepicker"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {/* Left side cards */}
        <Col md={12} xl={12} className="left9cards">
          <KPICards handleActiveCardChange={handleActiveCardChange} />
          
          {/* <RightCardSection handleActiveCardChange={handleActiveCardChange} /> */}
        </Col>
        {/* Right side cards */}
        {/*<Col md={12} xl={3} className="right2cards">
          <RightCardSection handleActiveCardChange={handleActiveCardChange} />
          {/* <LatestUpdate /> */}
        {/*</Col>*/}
        {/* Details table */}
        <Col xs={12}>
          <DetailsTable cardId={activeCard ? activeCard : "grossProfit"} />
        </Col>
        <Col xs={12} className="mb-5"></Col>
      </Row>
    </Fragment>
  );
};
