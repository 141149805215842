import { Container, Row, Col } from "react-bootstrap";
import { Filter } from "./Filter";
//import { AttritionRate } from "./KPIcards/RightCards/AttritionRate";
export const ComingSoon = () => {
  return (
    <Row>
      <Col xs={12} className="mt-5">
        <div className="uc icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="#ee9400" d="M173.7 128L216 20.3C220.8 8.1 232.7 0 245.8 0l20.4 0c13.2 0 25 8.1 29.8 20.3L338.3 128l-164.6 0zm-25.1 64l214.9 0 37.7 96-290.3 0 37.7-96zM48 448l37.7-96 340.6 0L464 448l16 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 512c-17.7 0-32-14.3-32-32s14.3-32 32-32l16 0z"/>
        </svg>
        </div>
        <h4 className="uc">This is a planned feature that is not yet implemented.</h4>
      </Col>
    </Row>
  );
};
