import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { create } from 'domain';

interface AuthState{
    token: string | null;
    email: string | null;
    expiryTime:number | null;
}

const initialState: AuthState={
    token: null,
    email:null,
    expiryTime:null,
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers:{
        setToken(state, action: PayloadAction<string>){
            state.token = action.payload;
        },
        setEmail(state, action:PayloadAction<string>){
            state.email=action.payload;
        },
        setExpiryTime(state, action:PayloadAction<number>){
            state.expiryTime=action.payload;
        },
        clearToken(state){
            state.token = null;
        }
    }
})

export const {setToken,setEmail,setExpiryTime,clearToken}=authSlice.actions;
export default authSlice.reducer;
