import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Col, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import "./KeyHighlightsCard.scss";
import { useEffect, useState } from "react";
import { formatNumberWithCommasDecimals } from "../../../utils/numberFormatter";
const doughnutOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutoutPercentage: 65,
  rotation: -90,
  circumference: 180,
  cutout: "80%",
  plugins: {
    legend: {
      display: false,
    },
  },
};
interface Props {
  actualValue: number;
  targetValue: number;
  actualValue2: any;
  actualValue3?: any;
  loading: boolean;
  heading: string;
  percentageOrRevenue?: string;
  footerActualText1?: string;
  footerActualText2?: string;
  footerActualText3?: string;
  footerActualText4?: string;
  dataType?: string;
  footer?: string;
}
export const KeyHighlightsCard: React.FC<Props> = ({
  actualValue,
  targetValue,
  loading,
  heading,
  actualValue2,
  actualValue3,
  percentageOrRevenue,
  footerActualText1,
  footerActualText2,
  footerActualText3,
  footerActualText4,
  dataType,
  footer,
}) => {
  const theme = useSelector((state: RootState) => state.card.darkTheme);

  //store actual and target positions
  const [actualNeedlePosition, setActualNeedlePosition] = useState(0);
  const [targetPointerPosition, setTargetPointerPosition] = useState(0);
  const [needlePath, setNeedlePath] = useState<any>(null);
  //store chart Data
  const [gaugeChartData, setGaugeChartData] = useState<any>(null);

  //calculate actual and target needle positions
  //max value = target * 1.25 times
  useEffect(() => {
    let maxValue = targetValue * 1.25;
    const maxAngle = 180;
    const angleIncrement = maxAngle / maxValue;
    let actualPosition = actualValue * angleIncrement + 270;
    let targetPosition = targetValue * angleIncrement + 90;
    if (actualValue >= maxValue) {
      actualPosition = 90;
      maxValue = actualValue;
    }
    if (actualValue < 0) {
      actualPosition = 270;
    }
    //needle path
    const needleBaseWidth = 8;
    const needleTipWidth = 1;
    const needleLength = 35;
    const nPath = `M ${75 - needleBaseWidth / 2} 75
    L ${75 + needleBaseWidth / 2} 75
    L ${75 + needleTipWidth / 2} ${75 - needleLength} 
    L ${75 - needleTipWidth / 2} ${75 - needleLength} 
    Z`;
    setNeedlePath(nPath);
    //datasets
    setActualNeedlePosition(actualPosition);
    setTargetPointerPosition(targetPosition);

    const formattedGExpensesR = {
      labels: ["Actual", "Maximum"],
      datasets: [
        {
          data: [
            actualValue < 0 ? 0 : actualValue,
            maxValue < 0 && actualValue < 0
              ? actualValue
              : maxValue - actualValue,
          ],
          backgroundColor: [
            dataType === "expense"
              ? actualValue < targetValue
                ? "#549e65"
                : "#7f2529"
              : actualValue > targetValue
              ? "#549e65"
              : "rgb(84 158 101 / 70%)",
            "#ebeeef",
          ],
          borderWidth: 0,
          borderColor: "#fff",
          fill: false,
        },
      ],
    };

    setGaugeChartData(formattedGExpensesR);
  }, [actualValue, targetValue]);
  const formatNumberWithCommas = (value: any): string => {
    if (typeof value !== "number" || isNaN(value)) {
      return value;
    }
    return new Intl.NumberFormat("en-US").format(value);
  };
  return (
    <>
      {!loading && actualValue && targetValue ? (
        <div className="card-details">
          <div className="card-details-header">
            <h6 className="regular-text semi-bold mb-0">{heading}</h6>
          </div>
          <div className="card-details-body d-flex justify-content-center align-items-center">
            <div className="card-details-chart-gp">
              <div className="gauge-min-value">
                <span
                  style={{
                    color: theme === "light" ? "#24282b" : "#fff",
                  }}
                >
                  {percentageOrRevenue === "revenue" ? "0" : "0%"}
                </span>
              </div>
              <div className="gauge-max-value">
                <span
                  style={{
                    color: theme === "light" ? "#24282b" : "#fff",
                  }}
                >
                  {percentageOrRevenue === "revenue"
                    ? formatNumberWithCommasDecimals(
                        Math.round(targetValue * 1.25)
                      )
                    : `${formatNumberWithCommasDecimals(
                        targetValue * 1.25,
                        2,
                        true
                      )}`}
                </span>
              </div>
              <svg
                width="150"
                height="100"
                viewBox="0 0 150 100"
                className="target-pointer"
              >
                <line
                  x1="75"
                  y1="75"
                  x2="75"
                  y2="155"
                  stroke={theme === "light" ? "#59656e" : "#fff"}
                  strokeWidth="3"
                  transform={`rotate(${
                    targetPointerPosition ? targetPointerPosition : 0
                  } 75 75)`}
                />
                <line
                  x1="75"
                  y1="75"
                  x2="75"
                  y2="125"
                  stroke={theme === "light" ? "#fff" : "#24282b"}
                  strokeWidth="5"
                  transform={`rotate(${
                    targetPointerPosition ? targetPointerPosition : 0
                  } 75 75)`}
                />
              </svg>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Actual:{" "}
                    {percentageOrRevenue === "revenue"
                      ? actualValue < 0
                        ? `(${formatNumberWithCommas(actualValue * -1)})`
                        : formatNumberWithCommas(actualValue)
                      : actualValue < 0
                      ? `(${(actualValue * -1)?.toFixed(2)}%)`
                      : `${actualValue?.toFixed(2)}%`}
                    <br />
                    Target:{" "}
                    {percentageOrRevenue === "revenue"
                      ? targetValue < 0
                        ? `(${formatNumberWithCommas(targetValue * -1)})`
                        : formatNumberWithCommas(targetValue)
                      : targetValue < 0
                      ? `(${(targetValue * -1)?.toFixed(2)}%)`
                      : `${targetValue?.toFixed(2)}%`}
                  </Tooltip>
                }
                placement="top"
              >
                <svg
                  width="150"
                  height="100"
                  viewBox="0 0 150 100"
                  className="target-pointer"
                >
                  {/* <line
                    x1="75"
                    y1="75"
                    x2="75"
                    y2="120"
                    stroke={theme === "light" ? "#24282b" : "#fff"}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    transform={`rotate(${
                      actualNeedlePosition ? actualNeedlePosition : 0
                    } 75 75)`}
                  /> */}

                  <path
                    d={needlePath}
                    fill={theme === "light" ? "#59656e" : "#fff"}
                    transform={`rotate(${
                      actualNeedlePosition ? actualNeedlePosition : 0
                    } 75 75)`}
                  ></path>

                  <circle
                    cx="75"
                    cy="75"
                    r="6"
                    fill={theme === "light" ? "#59656e" : "#fff"}
                  />
                </svg>
              </OverlayTrigger>
              {gaugeChartData ? (
                <Doughnut
                  data={gaugeChartData}
                  options={doughnutOptions}
                  width="150"
                  height="100"
                />
              ) : null}
            </div>
          </div>
          {footer === "type2" ? (
            <div className="card-details-footer type-2">
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <strong>{footerActualText2}:&nbsp;</strong>{" "}
                      {percentageOrRevenue === "revenue"
                        ? targetValue < 0
                          ? `(${formatNumberWithCommas(targetValue * -1)})`
                          : formatNumberWithCommas(targetValue)
                        : targetValue < 0
                        ? `(${(targetValue * -1)?.toFixed(2)}%)`
                        : `${targetValue?.toFixed(2)}%`}
                    </td>
                    <td rowSpan={2}>
                      <span
                        className={`${
                          dataType === "expense"
                            ? actualValue > targetValue
                              ? "red-arrow-circle-icon"
                              : "green-arrow-circle-icon down"
                            : actualValue > targetValue
                            ? "green-arrow-circle-icon"
                            : "red-arrow-circle-icon down"
                        }`}
                      ></span>
                    </td>
                    <td>
                      <strong>{footerActualText1}:&nbsp;</strong>{" "}
                      {percentageOrRevenue === "revenue"
                        ? actualValue < 0
                          ? `(${formatNumberWithCommas(actualValue * -1)})`
                          : formatNumberWithCommas(actualValue)
                        : actualValue < 0
                        ? `(${(actualValue * -1)?.toFixed(2)}%)`
                        : `${actualValue?.toFixed(2)}%`}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{footerActualText3}:&nbsp;</strong>
                      {percentageOrRevenue === "revenue"
                        ? actualValue2 < 0
                          ? `(${(actualValue2 * -1)?.toFixed(2)}%)`
                          : `${actualValue2?.toFixed(2)}%`
                        : actualValue2 < 0
                        ? `(${formatNumberWithCommas(actualValue2 * -1)})`
                        : formatNumberWithCommas(actualValue2)}
                    </td>

                    <td>
                      {footerActualText4 ? (
                        <strong>{footerActualText4}:&nbsp;</strong>
                      ) : null}

                      {actualValue3 < 0
                        ? `(${formatNumberWithCommas(actualValue3 * -1)})`
                        : formatNumberWithCommas(actualValue3)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="card-details-footer">
              <Table>
                <thead>
                  <tr>
                    <th>Target</th>

                    <th>{footerActualText1}</th>

                    <th>{footerActualText2}</th>
                    <th>{footerActualText3}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {percentageOrRevenue === "revenue"
                        ? targetValue < 0
                          ? `(${formatNumberWithCommas(targetValue * -1)})`
                          : formatNumberWithCommas(targetValue)
                        : targetValue < 0
                        ? `(${(targetValue * -1)?.toFixed(2)}%)`
                        : `${targetValue?.toFixed(2)}%`}
                    </td>

                    <td>
                      <span
                        className={`${
                          dataType === "expense"
                            ? actualValue > targetValue
                              ? "red-arrow-circle-icon"
                              : "green-arrow-circle-icon down"
                            : actualValue > targetValue
                            ? "green-arrow-circle-icon"
                            : "red-arrow-circle-icon down"
                        }`}
                      ></span>
                      {percentageOrRevenue === "revenue"
                        ? actualValue < 0
                          ? `(${formatNumberWithCommas(actualValue * -1)})`
                          : formatNumberWithCommas(actualValue)
                        : actualValue < 0
                        ? `(${(actualValue * -1)?.toFixed(2)}%)`
                        : `${actualValue?.toFixed(2)}%`}
                    </td>
                    <td>
                      {percentageOrRevenue === "revenue"
                        ? actualValue2 < 0
                          ? `(${(actualValue2 * -1)?.toFixed(2)}%)`
                          : `${actualValue2?.toFixed(2)}%`
                        : actualValue2 < 0
                        ? `(${formatNumberWithCommas(actualValue2 * -1)})`
                        : formatNumberWithCommas(actualValue2)}
                    </td>
                    <td>
                      {actualValue3 < 0
                        ? `(${formatNumberWithCommas(actualValue3 * -1)})`
                        : formatNumberWithCommas(actualValue3)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
      ) : (
        <div className="card-details">
          <div className="card-details-header">
            <h6 className="regular-text semi-bold mb-0">{heading}</h6>
            <div className="no-data-alert">
              <p className="text">No Data Available</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
