import { useState, createContext, useContext, PropsWithChildren } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./LeftSidebar.scss";
import menuItems from "../../data/menuItems.json";
interface MenuItem {
  id: string;
  link: string;
  icon: string;
  text: string;
}
//Types for context value
interface SidebarContextType{
  isSidebarOpen:boolean;
  toggleSidebar:()=>void;
}
//Create context for mapping sidebar
const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

//Custom hook to consume the SidebarContext
export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if(!context){
    throw new Error("usesidebar must be used within SidebarProvider")
  }
  return context;
}

//SidebarProvider component to manage sidebar state
export const SidebarProvider = ({ children }: PropsWithChildren) =>{
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () =>{
    setIsSidebarOpen(prevState =>!prevState)
  };
  return(
    <SidebarContext.Provider value={{isSidebarOpen, toggleSidebar}}>
      {children}
    </SidebarContext.Provider>
  )
}

export const LeftSidebar = () => {
  const {isSidebarOpen, toggleSidebar} = useSidebar();
  const [activeMenuItem, setActiveMenuItem] = useState("dashboard");



  //add active class to sidebar menu items
  const handleMenuItemClick = (menuItem: string) => {
    setActiveMenuItem(menuItem);
  };
  return (
    <>
      <div className={`sidebar ${isSidebarOpen ? "expanded" : ""}`}>
        
        <div className="toggle-button" onClick={toggleSidebar}>
          {/* based on expanded value, toggle the menu and arrow icons */}
          {isSidebarOpen ? (
            <span className="left-arrow-icon"></span>
          ) : (
            <span className="left-menu-icon"></span>
          )}
        </div>
        <div className={`menu-items ${isSidebarOpen ? "expanded" : ""}`}>
          {menuItems.map((menuItem) => (
            <Link
              key={menuItem.id}
              to={`/${menuItem.link}`}
              className={`menu-item ${
                activeMenuItem === menuItem.id ? "active" : ""
              }`}
              onClick={() => handleMenuItemClick(menuItem.id)}
            >
              <span className={`sidebar-icon ${menuItem.icon}`}></span>
              {/* bottom small menu item text for Default sidebar view, it will hide in Expanded view */}
              <p className="text-bottom">{menuItem.text}</p>
              {/* right menu item text for Expanded sidebar view, it will hide in Default view */}
              <span className="text-right">{menuItem.text}</span>
            </Link>
          ))}
        </div>
      </div>
      
        {/* If left sidebar is expanded, overlay will appear */}
      <div className={`overlay ${isSidebarOpen ? "enabled" : ""}`}></div>
    </>
  );
};
