import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { formatNumberWithCommasDecimals } from "../../../../utils/numberFormatter";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

//formatNumberWithCommas
const formatNumberWithCommas = (value: any): string => {
  if (typeof value !== "number" || isNaN(value)) {
    return value > 0 ? value : "0";
  }
  return new Intl.NumberFormat("en-US").format(value);
};
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom" as const,
      labels: {
        boxWidth: 8,
        boxHeight: 8,
        padding: 5,
        font: {
          size: 11,
        },
      },
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      Axes: [{ stacked: true }],

      grid: {
        tickLength: 2,
      },
      ticks: {
        font: {
          size: 11,
        },
      },
    },
  },
};

interface Props<T> {
  chartData: any;
  chartOptions?: string;
  pluginsAvailable?: boolean;
  axisText1?: string;
  axisText2?: string;
  decimals?: number;
  gridRequired?: boolean;
  callBackData?: any;
  callBackData2?: any;
  callBackData3?: any;
  callBackData4?: any;
  callBackData5?: any;
  callBackData6?: any;
  barValuePosition?: string;
  legendRequired?: boolean;
  axisTextRequired?: boolean;
  labelFontSize?: number;
}
export const BarChart = <T,>({
  chartData,
  chartOptions,
  pluginsAvailable,
  axisText1,
  axisText2,
  decimals,
  gridRequired,
  callBackData,
  callBackData2,
  callBackData3,
  callBackData4,
  callBackData5,
  callBackData6,
  barValuePosition,
  legendRequired,
  axisTextRequired,
  labelFontSize,
}: Props<T>) => {
  //check the chart data is valid
  const isValidDataset = (data: any[]): boolean => {
    const allValues = data?.flatMap((dataset) => dataset.data);
    return allValues?.some((item) => item !== 0 && item !== null);
  };
  const theme: any = useSelector((state: RootState) => state.card.darkTheme);

  //voluntary multi type chart options
  const multiTypeChartOptionsVol = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: theme === "light" ? "#000" : "#fff",
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return `${value > 0 ? value : "0"}`;
        },
        align: "end" as const,
      },
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        suggestedMax: (context: any) => {
          const data = context?.chart?.data?.datasets?.flatMap(
            (dataset: any) => dataset.data
          );
          return Math.max(...data.map(Math.abs)) * 1.25;
        },
      },
    },
  };

  //horizontal stacked bar options
  const horizontalStackedBaroptions = {
    indexAxis: "y" as const,
    plugins: {
      datalabels: {
        color: theme === "light" ? "#000" : "#fff",
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return `${label} : ${formatNumberWithCommasDecimals(value * 1)}`;
        },
        align: (context: any) => {
          const value = context.dataset.data[context.dataIndex];
          return value >= 0 ? "start" : "end";
        },
        anchor: (context: any) => {
          const value = context.dataset.data[context.dataIndex];
          return value >= 0 ? "start" : "end";
        },
        font: {
          size: 9,
        },

        padding: {
          left: 5,
          right: 5,
          top: 2,
          bottom: 2,
        },
      },

      legend: {
        position: "bottom" as const,
        display: false,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = context.dataset.label || "";
            const value = context.raw as number;

            return `${label}: ${formatNumberWithCommasDecimals(value * 1)}`;
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        suggestedMax: (context: any) => {
          const data = context?.chart?.data?.datasets?.flatMap(
            (dataset: any) => dataset.data
          );
          return Math.max(...data.map(Math.abs)) * 1.25;
        },
        suggestedMin: (context: any) => {
          const data = context?.chart?.data?.datasets?.flatMap(
            (dataset: any) => dataset.data
          );
          return Math.max(...data.map(Math.abs)) * -1.25;
        },
        Axes: [{ stacked: true }],
        grid: {
          display: false,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
        ticks: {
          callback: (value: any, index: any, values: any) => {
            return formatNumberWithCommasDecimals(value * 1);
          },
          font: {
            size: 11,
          },
        },
      },
      y: {
        Axes: [{ stacked: false }],

        grid: {
          display: gridRequired,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
        ticks: {
          font: {
            size: 11,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  //verticle stacked bar options
  const verticleStackedBaroptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return `${label} : ${value > 0 ? value : "0"}`;
        },
        color: theme === "light" ? "#000" : "#fff",
        align: "end" as const,
      },
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  //horizontal stacked bar options
  const horizontalStackedBaroptionsSingle = {
    indexAxis: "y" as const,
    plugins: {
      datalabels: {
        color: theme === "light" ? "#000" : "#fff",

        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return `${formatNumberWithCommasDecimals(value * 1)}${
            callBackData
              ? " / " +
                formatNumberWithCommasDecimals(
                  callBackData[context.dataIndex]
                ) +
                "%"
              : ""
          }`;
        },
        align: "end" as const,
        anchor: "end" as const,
        // align: (context: any) => {
        //   const value = context.dataset.data[context.dataIndex];
        //   return value <= 0 ? "start" : "end";
        // },
        // anchor: (context: any) => {
        //   const value = context.dataset.data[context.dataIndex];
        //   return value <= 0 ? "start" : "end";
        // },
        font: {
          size: labelFontSize ? labelFontSize : 9,
        },

        padding: {
          left: 5,
          right: 5,
          top: 2,
          bottom: 2,
        },
      },

      legend: {
        position: "bottom" as const,
        display: false,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = context.dataset.label || "";
            const value = context.raw as number;

            return `${label}: ${formatNumberWithCommasDecimals(
              value * 1
            )} / ${formatNumberWithCommasDecimals(
              callBackData[context.dataIndex]
            )}%`;
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: axisText1,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        suggestedMax: (context: any) => {
          const data = context?.chart?.data?.datasets?.flatMap(
            (dataset: any) => dataset.data
          );

          return (
            Math.max(...data.map(Math.abs)) > 0 &&
            Math.max(...data.map(Math.abs)) * 1.5
          );
        },
        suggestedMin: (context: any) => {
          const data = context?.chart?.data?.datasets?.flatMap(
            (dataset: any) => dataset.data
          );
          return (
            Math.max(...data.map(Math.abs)) < 0 &&
            Math.max(...data.map(Math.abs)) * -1
          );
        },
        Axes: [{ stacked: true }],
        grid: {
          display: true,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
        ticks: {
          callback: (value: any, index: any, values: any) => {
            return formatNumberWithCommasDecimals(value * 1);
          },
          font: {
            size: 11,
          },
        },
      },
      y: {
        Axes: [{ stacked: false }],
        grid: {
          display: true,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
        ticks: {
          autoSkipPadding: 3,
          font: {
            size: 11,
          },
          color: theme === "light" ? "#59656e" : "#bdc5c9",
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  //verticle bar chart options
  const verticleBaroptions = {
    plugins: {
      datalabels: {
        display: true,
        //anchor: "end" as const,
        align: "center" as const,
        color: theme === "light" ? "#000" : "#fff",
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return `${formatNumberWithCommasDecimals(value * 1)}${
            callBackData ? " / " + callBackData[context.dataIndex] + "%" : ""
          }`;
        },
      },
      legend: {
        display: legendRequired,
        position: "bottom" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = "";
            if (context.parsed.y) {
              label = formatNumberWithCommasDecimals(context.parsed.y * 1);
            }
            return label;
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: gridRequired,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
      },
      y: {
        grid: {
          display: gridRequired,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
        title: {
          display: true,
          text: axisText1,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        suggestedMax: (context: any) => {
          const data = context?.chart?.data?.datasets?.flatMap(
            (dataset: any) => dataset.data
          );
          return Math.max(...data.map(Math.abs)) * 1.15;
        },
        ticks: {
          beginAtZero: true,
          fontStyle: "bold" as "bold",
          font: {
            size: 11,
          },

          callback: (value: any, index: any, values: any) => {
            return formatNumberWithCommasDecimals(value * 1, decimals);
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  //display active and left count values in vertical bar options in volutnary
  const verticleBaroptionsVol = {
    plugins: {
      datalabels: {
        display: true,
        align: "end" as const,
        color: theme === "light" ? "#000" : "#fff",
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return formatNumberWithCommasDecimals(value * 1);
        },
      },
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (data: any) => {
            const index = data.dataIndex;
            let callBackItem = callBackData[index];
            let callBackItem2 = callBackData2[index];
            let callBackItem3 = callBackData3[index];

            if (data.datasetIndex === 1) {
              let callBackItem4 = callBackData4[index];
              let callBackItem5 = callBackData5[index];
              let callBackItem6 = callBackData6[index];
              return `Active Count:${
                callBackItem4 ? callBackItem4 : 0
              }, Left Count:${
                callBackItem5 ? callBackItem5 : 0
              }, Turnover Rate:${callBackItem6 ? callBackItem6 : 0}%`;
            } else {
              return `Active Count:${
                callBackItem ? callBackItem : 0
              }, Left Count:${
                callBackItem2 ? callBackItem2 : 0
              }, Turnover Rate:${callBackItem3 ? callBackItem3 : 0}%`;
            }
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
      },
      y: {
        title: {
          display: true,
          text: axisText1,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        suggestedMax: (context: any) => {
          const data = context?.chart?.data?.datasets?.flatMap(
            (dataset: any) => dataset.data
          );
          return Math.max(...data.map(Math.abs)) * 1.15;
        },
        ticks: {
          beginAtZero: true,
          fontStyle: "bold" as "bold",
          font: {
            size: 11,
          },

          callback: (value: any, index: any, values: any) => {
            return formatNumberWithCommasDecimals(value * 1, decimals);
          },
        },
        grid: {
          display: true,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  const multiTypeChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: theme === "light" ? "#000" : "#fff",
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;

          return `${
            value > 0
              ? context.datasetIndex === 0
                ? formatNumberWithCommasDecimals(value * 1000000, 0)
                : value
              : "0"
          }`;
        },
        align: "end" as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = "";
            if (context.datasetIndex === 0 && context.parsed.y) {
              label =
                formatNumberWithCommasDecimals(context.parsed.y * 1000000, 0) +
                "";
            } else {
              label = context.parsed.y;
            }
            return label;
          },
        },
      },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: axisText1,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        ticks: {
          font: {
            size: 11,
          },
        },
        grid: {
          display: true,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
      },
    },
  };
  const multiBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: theme === "light" ? "#000" : "#fff",
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return `${value > 0 ? value : "0"}`;
        },
        align: "end" as const,
      },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,
          font: {
            size: 11,
          },
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: gridRequired,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
      },
      y: {
        Axes: [{ stacked: true }],
        title: {
          display: true,
          text: axisText1,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        ticks: {
          font: {
            size: 11,
          },
          callback: (value: any, index: any, values: any) => {
            return formatNumberWithCommasDecimals(value * 1, decimals);
          },
        },
        grid: {
          display: true,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
      },
    },
  };
  //multiply the value * million in tooltip
  const multiBarOptionsTooltip = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: theme === "light" ? "#000" : "#fff",
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return `${
            value > 0 ? formatNumberWithCommasDecimals(value * 1000000, 0) : "0"
          }`;
        },
        //align: "end" as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = "";
            if (context.parsed.y) {
              label =
                formatNumberWithCommasDecimals(context.parsed.y * 1000000, 0) +
                "";
            }
            return label;
          },
        },
      },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        Axes: [{ stacked: true }],
        grid: {
          display: true,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
        title: {
          display: true,
          text: axisText1,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        ticks: {
          font: {
            size: 11,
          },
        },
      },
    },
  };
  //multi type chart options with Multi axis
  const multiTypeChartOptiosMultiAxis = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: theme === "light" ? "#000" : "#fff",
        formatter: (value: any, context: any) => {
          const dataValue = value ? value : 0;
          return formatNumberWithCommasDecimals(dataValue * 1);
        },
        align: "start" as const,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = context.dataset.label || "";
            const value = context.raw as number;

            return `${label}: ${formatNumberWithCommasDecimals(value * 1)}`;
          },
        },
      },
      legend: {
        position: "bottom" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: gridRequired,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
      },
      y: {
        type: "linear" as const,
        display: true,
        position: "left" as const,
        ticks: {
          font: {
            size: 11,
          },
          callback: (value: any) => {
            return formatNumberWithCommasDecimals(value * 1, 0);
          },
        },
        beginAtZero: true,
        title: {
          display: true,
          text: axisText1,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        grid: {
          display: gridRequired,
          color: theme === "light" ? "#bdc5c9" : "rgba(89, 101, 110, 0.3)",
        },
        // suggestedMax: (context: any) => {
        //   const data = context?.chart?.data?.datasets?.flatMap(
        //     (dataset: any) => dataset.data
        //   );
        //   return Math.max(...data.map(Math.abs)) * 1.25;
        // },
      },
      y1: {
        type: "linear" as const,
        display: true,
        position: "right" as const,
        title: {
          display: true,
          text: axisText2,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
        },
        ticks: {
          font: {
            size: 11,
          },
          callback: (value: any) => {
            return formatNumberWithCommasDecimals(value * 1);
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };
  //Total booked backlog
  const multiTypeChartOptiosMultiAxisTBB = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: -5,
    },
    plugins: {
      datalabels: {
        color: theme === "light" ? "#000" : "#fff",
        formatter: (value: any, context: any) => {
          const label = context.dataset.label;
          return `${value > 0 ? value : "0"}`;
        },
        align: "end" as const,
      },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          padding: 5,

          font: {
            size: 11,
          },
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
          font: {
            size: 10,
          },
        },
      },
      y: {
        type: "linear" as const,
        display: true,
        position: "left" as const,
        ticks: {
          font: {
            size: 10,
          },
        },
        beginAtZero: true,
        title: {
          display: axisText1 ? true : false,
          text: axisText1,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
          font: {
            size: 10,
          },
        },
        suggestedMax: (context: any) => {
          const data = context?.chart?.data?.datasets?.flatMap(
            (dataset: any) => dataset.data
          );
          return Math.max(...data.map(Math.abs)) * 1.25;
        },
      },
      y1: {
        type: "linear" as const,
        display: true,
        position: "right" as const,
        ticks: {
          font: {
            size: 10,
          },
        },
        title: {
          display: axisText2 ? axisText2 : false,
          text: axisText2,
          color: theme === "light" ? "#bdc5c9" : "#bdc5c9bf",
          font: {
            size: 10,
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };
  return isValidDataset(chartData?.datasets) ? (
    <Bar
      data={chartData}
      options={
        chartOptions === "multiTypeChartOptions"
          ? multiTypeChartOptions
          : chartOptions === "multiTypeChartOptionsVol"
          ? multiTypeChartOptionsVol
          : chartOptions === "multiBarOptionsTooltip"
          ? multiBarOptionsTooltip
          : chartOptions === "multiTypeChartOptiosMultiAxis"
          ? multiTypeChartOptiosMultiAxis
          : chartOptions === "multiTypeChartOptiosMultiAxisTBB"
          ? multiTypeChartOptiosMultiAxisTBB
          : chartOptions === "horizontalStackedBaroptions"
          ? horizontalStackedBaroptions
          : chartOptions === "horizontalStackedBaroptionsSingle"
          ? horizontalStackedBaroptionsSingle
          : chartOptions === "verticleBaroptions"
          ? verticleBaroptions
          : chartOptions === "verticleBaroptionsVol"
          ? verticleBaroptionsVol
          : chartOptions === "verticleStackedBaroptions"
          ? verticleStackedBaroptions
          : chartOptions === "multiBarOptions"
          ? multiBarOptions
          : options
      }
      plugins={pluginsAvailable ? [ChartDataLabels] : []}
    />
  ) : (
    <div className="no-data-alert">
      <p className="text">No Data Available</p>
    </div>
  );
};
