import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface marketState{
    marketList: [] | null;    
}

const initialState:marketState = {
    marketList: null,    
}

const marketSlice = createSlice({
    name: 'marketList',
    initialState,
    reducers:{
        setMarketList:(state, action: PayloadAction<[]>)=>{
            state.marketList = action.payload;
        },
      
    }
})

export const {setMarketList} = marketSlice.actions;
export default marketSlice.reducer;