import "./KPICards.scss";
import { Row, Col, Badge } from "react-bootstrap";
// import { AggregateClientSatisfactionStore } from "./KPIcards/AggregateClientSatisfactionStore";
// import { Fatalities } from "./KPIcards/Fatalities";
// import { GrossProfitMargin } from "./KPIcards/GrossProfitMargin";
import { NetExpense } from "./KPIcards/NetExpensesofGP";

import { KPICard } from "./KPIcards/CommonKPICard";
import { useState, useEffect } from "react";
import cardData from "../../../data/cardData.json";
import rightCardData from "../../../data/rightCardData.json";
import { OtherGrossProfit } from "./KPIcards/OtherGrossProfit";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from "chart.js";
import { Link } from "react-router-dom";
import { AggregateClientSatisfactionStore } from "./KPIcards/AggregateClientSatisfactionStore";

//redux imports
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { setActiveCard } from "../../../redux/cardSlice";
import { VoluntaryTurnover } from "./KPIcards/VoluntaryTurnover";
import { GrossProfitMargin } from "./KPIcards/GrossProfitMargin";
import { Trir } from "./KPIcards/Trir";
import { NetIncome } from "./KPIcards/NetIncome";
import { TotalBookedBacklog } from "./KPIcards/TotalBookedBacklog";

ChartJS.register(ArcElement, Tooltip, Legend);

//aggregate chart interface
interface AggregateChartObject {
  scoreCategory: string;
  surveyOverallScore: number;
}
//redux state interface
interface KPICardParentProps {
  handleActiveCardChange: (cardId: string, isActive: boolean) => void;
}

//line chart options
const optionsBarChart = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        font: {
          size: 8,
        },
      },
    },
    x: {
      display: true,
      ticks: {
        font: {
          size: 8,
        },
        gridLines: {
          display: false,
        },
      },
    },
  },
  plugins: {
    tooltip: {
      bodyFont: { size: 10 },
      titleFont: { size: 10 },
    },
    legend: {
      display: false,
      labels: {
        font: {
          size: 6,
        },
      },
    },
  },
};

const optionsPieChart = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      display: false,
      beginAtZero: true,
      ticks: {
        font: {
          size: 8,
        },
      },
    },
    x: {
      display: false,
      ticks: {
        font: {
          size: 8,
        },
        gridLines: {
          display: false,
        },
      },
    },
  },
  plugins: {
    tooltip: {
      bodyFont: { size: 10 },
      titleFont: { size: 10 },
    },

    legend: {
      display: true,
      labels: {
        font: {
          size: 6,
        },
      },
    },
  },
};



export const KPICards: React.FC<KPICardParentProps> = ({
  handleActiveCardChange,
}) => {
  const activeCardId = useSelector(
    (state: RootState) => state.card.activeCardId
  );
  const dispatch: AppDispatch = useDispatch();
  const handleCardClick = (id: string) => {
    dispatch(setActiveCard(id));
    handleActiveCardChange(id, true);
  };
  //format and store chart data
  // const [arrResUQ, setarrResUQ] = useState([]);
  // const [arrResQ1, setarrResQ1] = useState([]);
  // const [aggregateQ1ChartData, setAggregateQ1ChartData] = useState<any>(null);
  // const [aggregateUQChartData, setAggregateUQChartData] = useState<any>(null);
  // useEffect(() => {
  //   const arrResUQ = aggregateCardData?.filter((i: any) => {
  //     return i.qaGroup === "UQ";
  //   });
  //   setarrResUQ(arrResUQ);
  //   //console.log(arrResUQ);
  //   const arrResQ1 = aggregateCardData?.filter((i: any) => {
  //     return i.qaGroup === "Q1";
  //   });
  //   setarrResQ1(arrResQ1);
  //   //console.log(arrResQ1);
  //   formattedAggChartDataQ1(arrResQ1);
  //   formattedAggChartDataUQ(arrResUQ);
  // }, [aggregateCardData]);
  // const formattedAggChartDataQ1 = (Q1Data: any) => {
  //   //Q1 labels
  //   const label = Q1Data;
  //   const aggregateChartLabels = label?.map(
  //     (obj: AggregateChartObject) => obj.scoreCategory
  //   );
  //   //console.log("aggregateChartLabels", aggregateChartLabels);
  //   //Q1 total count
  //   const count = Q1Data;
  //   const aggregateTotalScore = count?.map(
  //     (obj: AggregateChartObject) => obj.surveyOverallScore
  //   );
  //   const formattedAggregateChartQ1Data = {
  //     labels: aggregateChartLabels,
  //     datasets: [
  //       {
  //         data: aggregateTotalScore,
  //         backgroundColor: [
  //           "#7f2529",
  //           "#549e65",
  //           "#4596b2",
  //           "#ffd578",
  //           "#59656e",
  //         ],
  //         borderColor: "#fff",
  //         fill: false,
  //       },
  //     ],
  //   };
  //   setAggregateQ1ChartData(formattedAggregateChartQ1Data);
  // };

  // //foramtting UQ data
  // const formattedAggChartDataUQ = (uqData: any) => {
  //   //Q1 labels
  //   const label = uqData;
  //   const aggregateChartLabels = label?.map(
  //     (obj: AggregateChartObject) => obj.scoreCategory
  //   );
  //   //console.log("aggregateChartLabels", aggregateChartLabels);
  //   //Q1 total count
  //   const count = uqData;
  //   const aggregateTotalScore = count?.map(
  //     (obj: AggregateChartObject) => obj.surveyOverallScore
  //   );
  //   const formattedAggregateChartUQData = {
  //     labels: aggregateChartLabels,
  //     datasets: [
  //       {
  //         data: aggregateTotalScore,
  //         backgroundColor: [
  //           "#7f2529",
  //           "#549e65",
  //           "#4596b2",
  //           "#ffd578",
  //           "#59656e",
  //         ],
  //         borderColor: "#fff",
  //         fill: false,
  //       },
  //     ],
  //   };
  //   setAggregateUQChartData(formattedAggregateChartUQData);
  // };

  return (
    <Row className="px-5px">
      <GrossProfitMargin
        id="grossProfit"
        handleClick={handleCardClick}
        isActive={activeCardId === "grossProfit"}
      />
      <NetExpense
        id="netExpense"
        handleClick={handleCardClick}
        isActive={activeCardId === "netExpense"}
      />
      <NetIncome
        id="netIncome"
        handleClick={handleCardClick}
        isActive={activeCardId === "netIncome"}
      />
      <Trir
        id="trir"
        handleClick={handleCardClick}
        isActive={activeCardId === "trir"}
      />
      <TotalBookedBacklog
        id="bookedBacklog"
        handleClick={handleCardClick}
        isActive={activeCardId === "bookedBacklog"}
      />
      <VoluntaryTurnover
        id="voluntary"
        handleClick={handleCardClick}
        isActive={activeCardId === "voluntary"}
      />
      {rightCardData.map((card) => (
        <Col xs={12} sm={6} md={6} lg={6} xl={3} className="kpi-card-column">
          <KPICard
            cardData={card}
            isActive={false}
            onClick={() => handleCardClick(card.id)}
          />
        </Col>
      ))}
      {/*{cardData.map((card) => (
        <Col
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={4}
          className="kpi-card-column"
          key={card.id}
        >
          <KPICard
            cardData={card}
            isActive={false}
            onClick={() => handleCardClick(card.id)}
          />
        </Col>
      ))} */}
    </Row>
  );
};
