//redux imorts
import { useSelector, useDispatch } from "react-redux";
import {
  setUserName,
  setUserDetails,
  setUserStatus,
  setUserResponseMessage,
  setCompanyList,
  setSubUnitList,
  setBussUnit,
  setSubBUId,
  setBUList,
} from "./redux/userSlice";
import { setMarketList } from "./redux/marketSlice";
import { setEmail, setToken } from "./redux/authSlice";

import axios from "axios";
import { useEffect, useState } from "react";

//MSAL imports
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

export const UserData = () => {
  const [token, setTheToken] = useState<any>();
  const [email, setEmailid] = useState<any>();
  const [userName, setName] = useState<any>();

  const { instance, inProgress, accounts } = useMsal();

  const dispatch = useDispatch();
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(Date.now());
    }, 55 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0],
      forceRefresh: true,
    };

    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          const userEmail = accounts[0]?.username?.toLowerCase();
          const userName: string = accounts[0]?.name ?? "";
          const token = accessTokenResponse?.idToken;
          setTheToken(token);
          setName(userName);
          setEmailid(userEmail);
          //store token, email and name in redux
          dispatch(setToken(token));
          dispatch(setEmail(userEmail));
          dispatch(setUserName(userName));
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                let token = accessTokenResponse?.idToken;
                const userName: string = accounts[0]?.name ?? "";
                const userEmail = accounts[0]?.username?.toLowerCase();
                dispatch(setToken(token));
                dispatch(setEmail(userEmail));
                dispatch(setUserName(userName));
                setTheToken(token);
                setName(userName);
                setEmailid(userEmail);
              });
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress, time]);

  useEffect(() => {
    if (token) {
      //fetch user details
      fetchUserDetails(email, userName, token);
      //fetch market list for total booked backlog
      fetchBookedBacklogFilters(token);
    }
  }, [userName, email]);

  async function fetchUserDetails(
    emailId: any,
    userNameProp: any,
    tokenProp: any
  ) {
    const id = emailId;
    const name = userNameProp;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user`,
        { id, name },
        {
          headers: {
            Authorization: `Bearer ${tokenProp}`,
          },
        }
      );

      const userData = response?.data?.data?.User;
      //Business Unit list
      const buList = response.data?.data?.BusUnitList;
      dispatch(setBUList(buList));
      //First Business Unit from the list
      const bussUnit = buList ? buList[0].busUnitId : undefined;
      dispatch(setBussUnit(bussUnit));

      const userStatus = response?.status;
      const userMessage = response.data?.message;
      //Company list
      const companyList = response?.data?.data?.CompanyList;
      dispatch(setCompanyList(companyList));
      //Sub Business Unit list
      const subBUs = response?.data?.data?.SubUnitList;
      const subBUListFirstId = subBUs[0]?.subUnitId;
      dispatch(setSubBUId(subBUListFirstId));
      dispatch(setSubUnitList(subBUs));

      dispatch(setUserDetails(userData));
      dispatch(setUserStatus(userStatus));
      dispatch(setUserResponseMessage(userMessage));
    } catch (error: any) {
      console.error("Error fetching user data", error);
      const userStatus = error?.response?.data?.status
        ? error?.response?.data?.status
        : error?.response?.status;
      const userMessage = error?.response?.data?.message;
      dispatch(setUserStatus(userStatus));
      dispatch(setUserResponseMessage(userMessage));
    }
  }
  //Market list
  const fetchBookedBacklogFilters = async (token: any) => {
    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}/bookedBacklogFilters`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(setMarketList(res?.data?.data?.MarketDescList));
      });
  };
  return null;
};
