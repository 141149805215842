import axios from "axios";
import "./GrossProfitDetails.scss";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { monthsMap } from "../Dashboard/Content/KPIcards/MonthNames";
import {
  setBUType,
  setSubBUId,
  setSelectedDateType,
  setSelectedMonthYear,
} from "../../redux/userSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//table descriptions to sort the rows
import {
  summaryTableDescriptions,
  expensesTableDescriptions,
} from "./GenericComponents/TableDescriptions";
import {
  Alert,
  Col,
  OverlayTrigger,
  ProgressBar,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  LineController,
  BarController,
  ArcElement,
} from "chart.js";
import { Link } from "react-router-dom";
//Generic components
import { ChartData, ChartOptions } from "chart.js/auto";
import { KeyHighlightsCard } from "./GenericComponents/KeyHighlightsCard";
import { ActualvsPlanned } from "./GenericComponents/ActualvsPlanned";
import { ModalComponent } from "./GenericComponents/ModalComponent";
import { ComparisionTable } from "./GenericComponents/ComparisionTable";
import { BarChart } from "./GenericComponents/Charts/BarChart";
import { BreadCrumbComponent } from "./GenericComponents/BreadcrumbComponent";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { graphColors } from "../../utils/graphColors";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  LineController,
  BarController,
  ArcElement
);
//format thousand seperator
const formatNumberWithCommas = (value: any): string => {
  if (typeof value !== "number" || isNaN(value)) {
    return value;
  }
  return new Intl.NumberFormat("en-US").format(value);
};
//interface for draggable Card and graph data
interface ChildCard {
  chartType: string;
  heading: any;
  chartData: any;
  chartOptions?: any;
  noChildCards?: boolean;
  loading?: boolean;
  pluginsAvailable?: boolean;
  dataAvailable?: boolean;
  axisText1?: string;
  gridRequired?: boolean;
}
interface CardItem {
  id: string;
  heading: string;
  data?: any;
  data2?: any;
  chartOptions?: any;
  dataAvailable: boolean;
  colSize: string;
  chartType?: string;
  childCardData?: ChildCard[];
  contentType?: string;
  autoAdjustWidth?: boolean;
  expandAvailable?: boolean;
  axisText1?: string;
  gridRequired?: boolean;
}
export const GrossProfitDetails = () => {
  //redux state
  const user: any = useSelector((state: RootState) => state.user.userDetails);
  const token: any = useSelector((state: RootState) => state.auth.token);
  const bussUnit: any = useSelector((state: RootState) => state.user.bussUnit);
  const buType: any = useSelector((state: RootState) => state.user.buType);
  const selectedMonthYear: any = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );
  const selectedDateType: any = useSelector(
    (state: RootState) => state.user.selectedDateType
  );
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const dispatch = useDispatch();
  const companyList = useSelector((state: RootState) => state.user.companyList);

  let allCompanyIds = companyList?.map((obj: any) => obj.companyId);
  let allCompanyCodes = companyList?.map((obj: any) => obj.companyCode);
  let companyIds = allCompanyIds?.join(",");
  const subBUList = useSelector((state: RootState) => state.user.subUnitList);
  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const [progress, setProgress] = useState<number>(0);
  const [dispalyProgress, setDispalyProgress] = useState<boolean>(true);
  const [subBUName, setSubBUName] = useState<any>();

  const [selectedPillItem, setSelectedPillItem] = useState("BU");
  //BU and sub BU filter change
  const handleMenuItemClick = (buType: string) => {
    handleFilterUpdateProgress();
    setSelectedPillItem(buType);
    dispatch(setBUType(buType));
    const unitID = buType === "SU" ? subBUId : bussUnit;
    fetchGPDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: selectedCompanies,
      dateType: dateType,
      selectedBU: buType,
      selectedbussUnit: unitID,
    });
  };
  //Sub BU dropdown change
  const handleSubBUChange = (subBUId: string) => {
    handleFilterUpdateProgress();
    setSubBUName(subBUId);
    dispatch(setSubBUId(subBUId));
    fetchGPDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: selectedCompanies,
      dateType: dateType,
      selectedBU: buType,
      selectedbussUnit: subBUId,
    });
  };
  //loading
  const [loading, setLoading] = useState(true);
  //store API response and status
  const [gpResponse, setGPResponse] = useState<any>(null);
  const [gpStatus, setGPStatus] = useState<any>(null);

  //store cards data
  const [initialCards, setInitialCards] = useState<CardItem[]>([]);
  const [cards, setCards] = useState<CardItem[]>([]);

  //store Key highlights data
  const [keyHighlights, setKeyHighlights] = useState<any>(null);

  //sub BU key highlits
  const [keyHighSubObj1, setKeyHighSubObj1] = useState<any>(null);
  const [keyHighSubObj2, setKeyHighSubObj2] = useState<any>(null);

  //store expense details table data
  const [highlightExpDetailCard, setHighlightExpDetailCard] =
    useState<any>(null);

  //Store sub BU expenses charts
  const [subUnitBUExpenses, setSubUnitBUExpenses] = useState<any>(null);
  const [subUnits, setSubUnits] = useState<any>(null);

  const [saveCardPositionRes, setSaveCardPositionRes] = useState<any>(null);
  //gross profit details API request
  useEffect(() => {
    handleFilterUpdateProgress();
    //refresh company list
    let allCompanyIds = companyList?.map((obj: any) => obj.companyId);
    let allCompanyCodes = companyList?.map((obj: any) => obj.companyCode);
    fetchGPDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: allCompanyIds,
      dateType: selectedDateType === "Q" ? "Y" : selectedDateType,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
    //update selected bu type on page load
    setSelectedPillItem(buType ? buType : selectedPillItem);
    setSubBUName(subBUId);
    //update selected date type on page load
    setDateType(selectedDateType === "Q" ? "Y" : selectedDateType);
    //update company list on page load
    setSelectedCompanies(allCompanyIds!);
    setSelectedCompanyNames(allCompanyCodes!);
  }, [subBUFirstId]);
  //card position filters
  const fetchCardPositionFilters = {
    email: user?.email,
    kpi: "grossProfit",
  };
  useEffect(() => {
    if (
      fetchCardPositionFilters.email !== null &&
      fetchCardPositionFilters.email !== undefined
    ) {
      fetchCardPosition();
    }
  }, [gpResponse]);
  //payload filters
  const [dateType, setDateType] = useState<string>("Y");
  //current Date
  const currentDate = new Date();

  const selectedDate = new Date(
    selectedMonthYear.year,
    selectedMonthYear.month
  );
  //date type change functionality
  const handleDateTypeChange = (value: string) => {
    handleFilterUpdateProgress();
    setDateType(value);
    dispatch(setSelectedDateType(value));
    fetchGPDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: selectedCompanies,
      dateType: value,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
  };
  //date picker change functionality
  const handleDateChange = (date: Date | null) => {
    handleFilterUpdateProgress();
    if (date) {
      const month = date.getMonth();
      const year = date!.getFullYear();
      dispatch(
        setSelectedMonthYear({
          month,
          year,
        })
      );
    }
    fetchGPDetails({
      month: date?.getMonth(),
      year: date?.getFullYear(),
      company: selectedCompanies,
      dateType: dateType,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
  };

  //multi select dropdown
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>(
    allCompanyIds ? allCompanyIds : []
  );

  const [selectedCompanyNames, setSelectedCompanyNames] = useState<number[]>(
    allCompanyCodes ? allCompanyCodes : []
  );
  const [isOpen, setIsOpen] = useState(false);
  //close multi select drop-down on outside click
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleCloseOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleCloseOutside);
    return () => {
      document.removeEventListener("mousedown", handleCloseOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleCompany = (company: any) => {
    handleFilterUpdateProgress();
    const toggleCompanyIds = [];

    if (selectedCompanies.includes(company.companyId)) {
      setSelectedCompanies(
        selectedCompanies.filter((id) => id !== company.companyId)
      );
      setSelectedCompanyNames(
        selectedCompanyNames.filter((code) => code !== company.companyCode)
      );
      let companyIds = selectedCompanies.filter(
        (id) => id !== company.companyId
      );
      toggleCompanyIds.push(companyIds);
    } else {
      setSelectedCompanies([...selectedCompanies, company.companyId]);
      let companyIds = [...selectedCompanies, company.companyId];
      toggleCompanyIds.push(companyIds);
      setSelectedCompanyNames([...selectedCompanyNames, company.companyCode]);
    }
    fetchGPDetails({
      month: selectedMonthYear.month,
      year: selectedMonthYear.year,
      company: toggleCompanyIds,
      dateType: dateType,
      selectedBU: buType,
      selectedbussUnit: buType === "SU" ? subBUId : bussUnit,
    });
  };

  const renderCompanyCodes = () => {
    if (selectedCompanyNames.length === 0) {
      return <span>Filter by Company</span>;
    } else {
      let displayContent = selectedCompanyNames.slice(0, 2).join(",");
      let badge = "";
      if (selectedCompanyNames.length > 2) {
        badge = `+ <div className="company-count-circle">${
          selectedCompanyNames.length - 2
        }</div>`;
      }

      if (selectedCompanyNames.length === allCompanyIds?.length) {
        displayContent = "All Companies";
        badge = "";
      }
      return (
        <div
          className="filtered-companylist"
          dangerouslySetInnerHTML={{ __html: `${displayContent} ${badge}` }}
        ></div>
      );
    }
  };

  //fetch gp details data on filters change
  const fetchGPDetails = async (payload?: any) => {
    //UserData();

    setProgress(0);
    try {
      const { month, year, company, dateType, selectedBU, selectedbussUnit } =
        payload || {};
      const currentMonth = month !== undefined ? month + 1 : undefined;
      const selectCompIds = company?.join(",");
      const typeofBU = selectedBU ? selectedBU : buType;
      const bussUnitID = selectedbussUnit
        ? selectedbussUnit
        : typeofBU === "SU"
        ? subBUId
        : bussUnit;

      const response = await axios
        .post(
          `${process.env.REACT_APP_API_URL}/grossProfitDetailsPage`,
          {
            dateFilter: dateType || "Y",
            type: typeofBU || "BU",
            unitId: bussUnitID,
            month: currentMonth,
            year: year,
            companies:
              selectCompIds?.length == 0 ? "" : selectCompIds || companyIds,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setGPResponse(res?.data?.data);
          setGPStatus(res?.data?.status);
          setLoading(false);
          //Key Highlights
          const keyHighlights = res?.data?.data?.KeyHigh;
          //Top and Bottom Sub BU
          const keyHighSub = res?.data?.data?.KeyHighSub;
          const sortedkeyHighSub =
            keyHighSub?.length > 1
              ? keyHighSub
                  ?.slice()
                  ?.sort((a: any, b: any) => a.actGpPer - b.actGpPer)
              : keyHighSub;
          const KeyHighSubObj1 =
            sortedkeyHighSub && sortedkeyHighSub?.length > 1
              ? sortedkeyHighSub[sortedkeyHighSub?.length - 1]
              : sortedkeyHighSub?.length === 1
              ? sortedkeyHighSub?.length[0]
              : undefined;
          const KeyHighSubObj2 =
            sortedkeyHighSub && sortedkeyHighSub?.length >= 0
              ? sortedkeyHighSub[0]
              : undefined;

          setKeyHighSubObj1(KeyHighSubObj1);
          setKeyHighSubObj2(KeyHighSubObj2);
          setKeyHighlights(keyHighlights);

          //summary P&L
          const summaryPL = res?.data?.data?.SummaryCard;
          //sort the table rows
          const sortedSummaryPL =
            summaryPL?.length > 1
              ? summaryPL?.sort(
                  (a: any, b: any) =>
                    summaryTableDescriptions[a.flashDesc] -
                    summaryTableDescriptions[b.flashDesc]
                )
              : summaryPL;
          //Hightlight rows
          const highlightSummaryPL = sortedSummaryPL?.filter((obj: any) =>
            [
              "Gross Rec. % of Gross Expenses",
              "Job GP% of Revenue",
              "Gross Expenses % of Rev.",
              "Total GP% of Revenue",
            ].includes(obj.flashDesc)
          );
          //exclude highlight rows
          const filterdSummaryPL = sortedSummaryPL?.filter(
            (obj: any) =>
              obj.flashDesc !== "Gross Rec. % of Gross Expenses" &&
              obj.flashDesc !== "Job GP% of Revenue" &&
              obj.flashDesc !== "Gross Expenses % of Rev." &&
              obj.flashDesc !== "Total GP% of Revenue"
          );

          //Expenses details card
          const expDetailCard = res?.data?.data?.ExpDetailCard;
          //sort the table rows
          const sortedExpDetailCard =
            expDetailCard?.length > 1
              ? expDetailCard?.sort(
                  (a: any, b: any) =>
                    expensesTableDescriptions[a.flashDesc] -
                    expensesTableDescriptions[b.flashDesc]
                )
              : expDetailCard;
          //Hightlight rows
          const highlightexpDetailCard = sortedExpDetailCard?.filter(
            (obj: any) =>
              [
                "Gross Expenses % of Rev.",
                "Gross Rec. % of Gross Expenses",
              ].includes(obj.flashDesc)
          );

          //exclude highlight rows
          const filterdExpDetailCard = sortedExpDetailCard?.filter(
            (obj: any) =>
              obj.flashDesc !== "Gross Expenses % of Rev." &&
              obj.flashDesc !== "Gross Rec. % of Gross Expenses"
          );
          setHighlightExpDetailCard(highlightexpDetailCard);

          //SubUnitExpenseCard
          const subUnitExpenseCard = res?.data?.data?.SubUnitExpenseCard;

          const subUnitExpenseCardLabels = Array.from(
            new Set(subUnitExpenseCard?.map((item: any) => item.subUnit))
          );
          //Initialize array to store chart data
          const subUnitBUExpenses: any[] = [];
          //iterate over subUnits
          subUnitExpenseCardLabels?.forEach((subUnit) => {
            const filteredData = subUnitExpenseCard?.filter(
              (obj: any) => obj.subUnit === subUnit
            );
            //Extract amounts
            const actAmount = filteredData.map((obj: any) =>
              obj.actAmount.toFixed(0)
            );
            const planAmount = filteredData.map((obj: any) =>
              obj.planAmount.toFixed(0)
            );
            const varAmount = filteredData.map((obj: any) =>
              obj.varAmount.toFixed(0)
            );
            //Create datasets=
            const data: ChartData<"bar"> = {
              labels: [""],
              datasets: [
                {
                  label: "Actual",
                  data: actAmount,
                  backgroundColor: [graphColors.yellow],
                  borderColor: "#fff",
                },
                {
                  label: "Planned",
                  data: planAmount,
                  backgroundColor: [graphColors.blue],
                  borderColor: "#fff",
                },
                {
                  label: "Variance",
                  data: varAmount,
                  backgroundColor: [
                    varAmount > 0 ? graphColors.red : graphColors.green,
                  ],
                  borderColor: "#fff",
                },
              ],
            };
            //push chart data into array
            subUnitBUExpenses.push(data);
          });
          //store formatted Sub BU Expenses data
          setSubUnitBUExpenses(subUnitBUExpenses);
          setSubUnits(subUnitExpenseCardLabels);

          //Gross Exp vs Rec Variance by Sub BU
          const grossExpVSRecCard = res?.data?.data?.ExpVSRecCard;

          // //Initialize array to store chart data
          const grossExpVSRecRecoveries: number[] = [];
          const grossExpVSRecExpenses: number[] = [];

          grossExpVSRecCard?.forEach((item: any) => {
            if (item.description === "Gross Recoveries") {
              const varAmount = item.varAmt?.toFixed(0);
              grossExpVSRecRecoveries.push(varAmount);
            } else if (item.description === "Gross Expenses") {
              const varAmount = item.varAmt?.toFixed(0);
              grossExpVSRecExpenses.push(varAmount);
            } else {
              console.log("Others", item.varAmt);
            }
          });
          //Chart data configuration
          //extract labels
          const expvsRecSubunitLabels = Array.from(
            new Set(grossExpVSRecCard?.map((item: any) => item.subUnit))
          );

          const grossExpVSRecCardChartData: ChartData<"bar"> = {
            labels: expvsRecSubunitLabels,
            datasets: [
              {
                label: "GR",
                data: grossExpVSRecRecoveries,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.red : graphColors.green;
                },
                borderColor: "#fff",
              },
              {
                label: "GE",
                data: grossExpVSRecExpenses,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.green : graphColors.red;
                },
                borderColor: "#fff",
              },
            ],
          };

          //Gross Profit vs Net Ex Variance by Sub BU
          const profitVSNetExp = res?.data?.data?.ProfitVSNetExp;
          //has 0 or null values
          const hasZeroValuesGPvsExp = profitVSNetExp?.some(
            (obj: any) => obj.varAmt !== null && obj.varAmt !== 0
          );

          //Initialize array to store chart data
          const profitVSNetExpTotalGP: number[] = [];
          const profitVSNetExpNetExpenses: number[] = [];
          //iterate over subUnits
          profitVSNetExp?.forEach((item: any) => {
            if (item.description === "Total Gross Profit") {
              const varAmount = item.varAmt?.toFixed(0);
              profitVSNetExpTotalGP.push(varAmount);
            } else if (item.description === "Net Expenses") {
              const varAmount = item.varAmt?.toFixed(0);
              profitVSNetExpNetExpenses.push(varAmount);
            } else {
              console.log("Others", item.varAmt);
            }
          });
          //Chart data configuration
          //extract labels
          const profitVSExpbunitLabels = Array.from(
            new Set(profitVSNetExp?.map((item: any) => item.subUnit))
          );
          const profitVSNetExpCardChartData: ChartData<"bar"> = {
            labels: profitVSExpbunitLabels,
            datasets: [
              {
                label: "GP",
                data: profitVSNetExpTotalGP,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.red : graphColors.green;
                },
                borderColor: "#fff",
              },
              {
                label: "NE",
                data: profitVSNetExpNetExpenses,
                backgroundColor: (context: any) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value < 0 ? graphColors.green : graphColors.red;
                },
                borderColor: "#fff",
              },
            ],
          };

          //Month wise Gross Profit
          const monthWiseGPCard = res?.data?.data?.MonthWiseGPCard;
          let actAmounts: any[] = [];
          let planAmounts: any[] = [];
          let varAmounts: any[] = [];
          let labels: any[] = [];
          const sortedObjectsWithMonth = monthWiseGPCard?.sort(
            dateType === "M"
              ? (a: any, b: any) => monthsMap[a.month] - monthsMap[b.month]
              : (a: any, b: any) => a.year - b.year
          );
          sortedObjectsWithMonth?.forEach((obj: any, index: number) => {
            actAmounts.push(obj.actAmount.toFixed(0));
            planAmounts.push(obj.planAmount.toFixed(0));
            varAmounts.push(obj.varAmount.toFixed(0));
            let label: any;
            if (obj.month !== null) {
              label = obj.month;
            } else {
              label = obj.year;
            }
            if (!labels?.includes(label)) {
              labels?.push(label);
            }
          });

          //Create datasets
          const monthWiseGPChartData: ChartData<"bar"> = {
            labels: labels,
            datasets: [
              {
                label: "Actual",
                data: actAmounts,
                backgroundColor: [graphColors.yellow],
                borderColor: "#fff",
              },
              {
                label: "Planned",
                data: planAmounts,
                backgroundColor: [graphColors.blue],
                borderColor: "#fff",
              },
              {
                label: "Variance",
                data: varAmounts,
                backgroundColor: varAmounts?.map((value: any) =>
                  value > 0 ? graphColors.green : graphColors.red
                ),
                borderColor: "#fff",
              },
            ],
          };

          //child cards data
          const childCardsActualPlanned: ChildCard[] = [
            {
              chartType: "gaugeTarget",
              heading: "Actual v/s Planned Revenue",
              chartData: {
                actualVal: keyHighlights?.actRevenue,
                plannedVal: keyHighlights?.planRevenue,
                varianceVal: keyHighlights?.variableRevenue,
              },
              loading: loading,
            },
            {
              chartType: "gaugeTarget",
              heading: "Actual v/s Planned Gross Profit",
              chartData: {
                actualVal: keyHighlights?.actGp,
                plannedVal: keyHighlights?.plannedGp,
                varianceVal: keyHighlights?.variableGp,
              },
              loading: loading,
            },
            {
              chartType: "gaugeTarget",
              heading: "Actual v/s Planned Gross Expense",
              chartData: {
                actualVal: keyHighlights?.actGrossExp,
                plannedVal: keyHighlights?.planGrossExp,
                varianceVal: keyHighlights?.variableGrossExp,
              },
              loading: loading,
            },
            {
              chartType: "gaugeTarget",
              heading: "Actual v/s Planned Recovery",
              chartData: {
                actualVal: keyHighlights?.actGrossRecov,
                plannedVal: keyHighlights?.planGrossRecov,
                varianceVal: keyHighlights?.variableGrossRecov,
              },
              loading: loading,
            },
          ];
          const childCardsComparison: ChildCard[] = [
            {
              chartType: "bar",
              chartOptions: "verticleBaroptions",
              dataAvailable: monthWiseGPCard,
              heading: `Gross Profit by 
              ${
                dateType === "M"
                  ? "Month"
                  : dateType === "Q"
                  ? "Quarter"
                  : "Year"
              }`,
              chartData: monthWiseGPChartData,
              pluginsAvailable: true,
              axisText1: "Gross Profit Amount",
              gridRequired: true,
            },
            {
              chartType: "bar",
              heading: "Gross Profit vs Net Expenses Variance by Sub BU",
              dataAvailable: profitVSNetExp,
              chartData: profitVSNetExpCardChartData,
              chartOptions: "horizontalStackedBaroptions",
              pluginsAvailable: true,
            },
            {
              chartType: "bar",
              heading: "Gross Expenses vs Recoveries Variance by Sub BU",
              dataAvailable: grossExpVSRecCard,
              chartData: grossExpVSRecCardChartData,
              chartOptions: "horizontalStackedBaroptions",
              pluginsAvailable: true,
            },
          ];
          const childCardsSubBUExp: ChildCard[] = subUnitExpenseCardLabels?.map(
            (heading, index) => ({
              chartType: "bar",
              heading: heading,
              chartOptions: "verticleBaroptions",
              chartData: subUnitBUExpenses[index],
              axisText1: "Expense Amount",
              pluginsAvailable: true,
            })
          );
          const initialCards: CardItem[] = [
            {
              id: "card1",
              heading: "Summary Profit & Loss",
              dataAvailable: res?.data?.data?.SummaryCard ? true : false,
              data: filterdSummaryPL,
              data2: highlightSummaryPL,
              colSize: "col-xl-6",
              contentType: "table",
              expandAvailable: true,
            },
            {
              id: "card2",
              heading: "Expense Details",
              dataAvailable: res?.data?.data?.ExpDetailCard ? true : false,
              data: filterdExpDetailCard,
              data2: highlightExpDetailCard,
              colSize: "col-xl-6",
              contentType: "table",
              expandAvailable: true,
            },
            {
              id: "card3",
              heading: "Actual v/s Planned",
              dataAvailable: keyHighlights ? true : false,
              childCardData: childCardsActualPlanned,
              colSize: "mt-0",
              chartType: "chart",
              expandAvailable: false,
            },
            {
              id: "card4",
              heading: "Comparison",
              dataAvailable:
                monthWiseGPCard || profitVSNetExp || grossExpVSRecCard,
              childCardData: childCardsComparison,
              colSize: "",
              contentType: "chart",
              expandAvailable: false,
            },
            {
              id: "card5",
              heading: "Sub BU Expenses",
              dataAvailable: subUnitExpenseCard,
              childCardData: childCardsSubBUExp,
              colSize: "col-md-6",
              contentType: "chart",
              expandAvailable: false,
              autoAdjustWidth: true,
            },
          ];
          setCards(initialCards);
          setInitialCards(initialCards);
          //update progress state
          setProgress(100);
          setDispalyProgress(true);
        });
    } catch (error: any) {
      console.error("Error fetching gp details data", error);
      setLoading(false);
      //update status
      setGPStatus(error.response?.data?.status);
      //update progress state
      setDispalyProgress(true);
    }
  };
  const fetchCardPosition = async () => {
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/fetchUserPersonalizationData`,
        fetchCardPositionFilters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const cardIds = res.data.data?.Details?.cardPosition?.split(",");
        const initialCardIDs = initialCards?.map((card) => card.id);
        const defaultORUpdatedCardIds = cardIds
          ? cardIds?.length === initialCardIDs?.length
            ? cardIds
            : initialCardIDs
          : initialCardIDs;
        const updatedCards = defaultORUpdatedCardIds?.map(
          (id: any, index: any) => {
            const matchingCard = cards?.find((card) => card?.id === id);
            return matchingCard ? { ...matchingCard } : null;
          }
        );
        setCards([...updatedCards]);
      });
  };
  //modal funcionality
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [componentType, setComponentType] = useState("");

  //show modal
  const handleShowModal = (
    content: React.ReactNode,
    title: string,
    type: string
  ) => {
    setModalContent(content);
    setModalTitle(title);
    setComponentType(type);
    setShowModal(true);
  };

  //close modal
  const handleCloseModal = () => setShowModal(false);

  //update progress value
  const handleFilterUpdateProgress = () => {
    setProgress(0);
    setDispalyProgress(false);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 10;

        if (newProgress >= 100) {
          clearInterval(interval);
        }
        return newProgress;
      });
    }, 100);
  };
  if (loading) {
    return (
      <div className="global-loading d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  //drag and drop functionality
  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const newCards = Array.from(cards);
    const [reorderedCard] = newCards.splice(source.index, 1);
    newCards.splice(destination.index, 0, reorderedCard);
    setCards(newCards);

    const cardIds = newCards?.map((card) => card?.id);
    savePersonalization(cardIds);
  };

  //save personalized dashboard
  const savePersonalization = async (cardids: any) => {
    const vountaryCardIds = cardids?.map((card: any) => card).join(",");
    const savePersonalizationFilters = {
      email: user?.email,
      kpi: "grossProfit",
      position: vountaryCardIds,
    };
    const Response = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/saveUserPersonalization`,
        savePersonalizationFilters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSaveCardPositionRes(200);
        setTimeout(() => {
          setSaveCardPositionRes(0);
        }, 1000);
      });
  };

  // //reset card position to default
  const resetPersonalization = () => {
    setCards(initialCards);
    const cardIds = initialCards?.map((card) => card?.id);

    savePersonalization(cardIds);
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <BreadCrumbComponent item="Gross Profit Details" />
          {/* Progress bar */}
          <div
            className={`progress-bar-container ${
              dispalyProgress ? "show-progress" : ""
            }`}
          >
            <ProgressBar now={progress} />
          </div>
          {saveCardPositionRes === 200 ? (
            <div
              className="alert alert-primary col-md-3 move-alert"
              role="alert"
            >
              <p>Card position has been saved.</p>
            </div>
          ) : null}
          <div className="mb-3"></div>
        </Col>
        <Col xs={12}>
          <Row className="align-items-center gx-3">
            <Col xs={12} md={2} lg={2} xl={2} className="mb-3">
              <h5 className="mb-0 details-heading animate__animated animate__bounce">
                Gross Profit Margin
              </h5>
            </Col>
            <Col xs={12} md={4} lg={4} xl={3} className="mb-3">
              <div className="pills">
                <div className="px-2 py-1 nav-fill border-1 nav nav-pills">
                  <div className="nav-item">
                    <a
                      onClick={() => handleMenuItemClick("BU")}
                      className={`nav-link ${
                        selectedPillItem === "BU" ? "active" : ""
                      } ${subBUList && subBUList?.length <= 1 ? "active" : ""}`}
                    >
                      Business Unit
                    </a>
                  </div>
                  <div
                    className={`nav-item ${
                      subBUList && subBUList?.length <= 1 ? "not-allowed" : ""
                    }`}
                  >
                    <a
                      onClick={() => handleMenuItemClick("SU")}
                      role="button"
                      className={`nav-link sub-bu ${
                        subBUList && subBUList?.length <= 1 ? "disabled" : ""
                      } ${
                        selectedPillItem === "SU" &&
                        subBUList &&
                        subBUList?.length > 1
                          ? "active"
                          : ""
                      }`}
                    >
                      Sub Business Unit
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            {selectedPillItem === "SU" && subBUList?.length! > 1 ? (
              <Col xs={6} md={2} lg={2} xl={2} className="mb-3">
                <select
                  className="filter form-select width-75p"
                  value={subBUName}
                  onChange={(e) => handleSubBUChange(e.target.value)}
                >
                  {subBUList?.map((su: any) => (
                    <option value={su.subUnitId} key={su.subUnitId}>
                      {su.subUnitDesc}
                    </option>
                  ))}
                </select>
              </Col>
            ) : null}

            <Col
              xs={6}
              md={3}
              lg={3}
              xl={2}
              className={`${
                selectedPillItem === "SU" && subBUList?.length! > 1
                  ? "offset-xl-1"
                  : "offset-xl-3"
              } mb-3 d-flex align-items-center justify-content-between text-right`}
            >
              <OverlayTrigger
                overlay={<Tooltip>Reset Personalization</Tooltip>}
                placement="bottom"
              >
                <button
                  className="btn border-0 reset-btn pe-3"
                  onClick={resetPersonalization}
                >
                  <span className="restore-page-icon"></span>
                </button>
              </OverlayTrigger>
              <div className="multiselect-dropdown vt">
                <div className="dropdown-header" onClick={toggleDropdown}>
                  {renderCompanyCodes()}

                  <span className="material-symbols-outlined">expand_more</span>
                </div>
                <div ref={dropdownRef} className="dropdown-options-parent">
                  {isOpen && (
                    <div className="dropdown-options">
                      {companyList?.map((company: any) => (
                        <label key={company.companyId}>
                          <input
                            type="checkbox"
                            value={company.companyId}
                            checked={selectedCompanies.includes(
                              company.companyId
                            )}
                            onChange={() => toggleCompany(company)}
                          />
                          &nbsp;{company.companyCode}
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col xs={4} md={2} lg={2} xl={1} className="mb-3">
              <select
                className="filter form-select"
                value={dateType}
                onChange={(e) => handleDateTypeChange(e.target.value)}
              >
                <option value="Y">YTD</option>
                <option value="M">MTD</option>
              </select>
            </Col>
            <Col xs={4} md={2} lg={2} xl={1} className="mb-3 gp-datepicker">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MMM/yyyy"
                maxDate={currentDate}
                showMonthYearPicker
                wrapperClassName="datepicker"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {gpStatus === 200 && gpResponse ? (
        <Col xs={12}>
          <Row className="mb-3 g-2">
            <Col xs={12}>
              <div className="gp-parent-card-container key-highlights blue">
                <h3 className="semi-bold card-heading heading-uppercase mb-2">
                  Key Highlights
                </h3>
                <div className="gp-key-highlights-card-container">
                  <KeyHighlightsCard
                    actualValue={keyHighlights?.actGpPer}
                    targetValue={keyHighlights?.tgtGpPer}
                    actualValue2={keyHighlights?.actRevenue}
                    actualValue3={keyHighlights?.actGp}
                    loading={loading}
                    heading="Gross Profit Margin"
                    footerActualText1="Actual"
                    footerActualText2="Revenue"
                    footerActualText3="GP"
                  />

                  <KeyHighlightsCard
                    actualValue={keyHighlights?.actGrossRecovExPer}
                    targetValue={keyHighlights?.tgtGrossRecovExPer}
                    actualValue2={keyHighlights?.actGrossRecov}
                    actualValue3={keyHighlights?.actGrossExp}
                    loading={loading}
                    heading="Gross Recoveries % of Gross Exp"
                    footerActualText1="Actual"
                    footerActualText2="Recovery"
                    footerActualText3="Exp"
                  />

                  <KeyHighlightsCard
                    actualValue={keyHighlights?.actGrossExRevPer}
                    targetValue={keyHighlights?.tgtGrossExRevPer}
                    actualValue2={keyHighlights?.actRevenue}
                    actualValue3={keyHighlights?.actGrossExp}
                    loading={loading}
                    heading="Gross Expenses % of Revenue"
                    footerActualText1="Actual"
                    footerActualText2="Revenue"
                    footerActualText3="Exp"
                    dataType="expense"
                  />

                  <KeyHighlightsCard
                    actualValue={keyHighlights?.actGp}
                    percentageOrRevenue="revenue"
                    targetValue={keyHighlights?.annualGp}
                    actualValue2={keyHighlights?.gpAnnualPer}
                    actualValue3={null}
                    loading={loading}
                    heading="Current GP of Annual GP"
                    footerActualText1="Current"
                    footerActualText2="Annual %"
                  />

                  {/* display sub BU expenses, when there is only one sub BU */}
                  {subUnitBUExpenses?.length == 1
                    ? subUnits?.map((subUnit: any, index: any) => (
                        <div className="card-details" key={index}>
                          <div className="card-details-header">
                            <h6 className="body-text semi-bold mb-0">
                              Sub BU Expenses - {subUnit}
                            </h6>
                          </div>
                          <div className="card-details-body d-flex justify-content-center align-items-center">
                            <BarChart
                              chartData={subUnitBUExpenses[index]}
                              chartOptions="verticleBaroptions"
                              axisText1="Expense Amount"
                              gridRequired={true}
                              pluginsAvailable={true}
                            />
                          </div>
                        </div>
                      ))
                    : null}
                  {/* top and bottom sub bu*/}
                  {keyHighSubObj1?.unitDesc && keyHighSubObj2?.unitDesc ? (
                    <div className="card-details">
                      <div className="top-bottom-sub-bu-card">
                        <Row className="gx-0">
                          <Col
                            xs={4}
                            className="light-green d-flex align-items-center"
                          >
                            <div className="heading-container">
                              <p className="regular-text">Top Sub BU</p>
                            </div>
                          </Col>

                          <Col xs={8} className="dark-green">
                            <div className="body-container">
                              <ul className="body-list-items">
                                <li>Name : {keyHighSubObj1?.unitDesc}</li>
                                <li>
                                  Actual GP :{" "}
                                  {keyHighSubObj1?.actGp < 0
                                    ? `(${formatNumberWithCommas(
                                        keyHighSubObj1?.actGp * -1
                                      )})`
                                    : formatNumberWithCommas(
                                        keyHighSubObj1?.actGp
                                      )}
                                </li>
                                <li>
                                  Actual GP Percentage :
                                  {keyHighSubObj1?.actGpPer < 0
                                    ? `(${(
                                        keyHighSubObj1?.actGpPer * -1
                                      ).toFixed(2)})`
                                    : keyHighSubObj1?.actGpPer?.toFixed(2)}
                                </li>
                                <li>
                                  Actual Revenue :{" "}
                                  {keyHighSubObj1?.actRevenue < 0
                                    ? `(${formatNumberWithCommas(
                                        keyHighSubObj1?.actRevenue * -1
                                      )})`
                                    : formatNumberWithCommas(
                                        keyHighSubObj1?.actRevenue
                                      )}
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Row>

                        <Row className="gx-0">
                          <Col
                            xs={4}
                            className="light-red d-flex align-items-center"
                          >
                            <div className="heading-container">
                              <p className="regular-text">Bottom Sub BU</p>
                            </div>
                          </Col>

                          <Col xs={8} className="dark-red">
                            <div className="body-container">
                              <ul className="body-list-items">
                                <li>Name : {keyHighSubObj2?.unitDesc}</li>
                                <li>
                                  Actual GP :{" "}
                                  {keyHighSubObj2?.actGp < 0
                                    ? `(${formatNumberWithCommas(
                                        keyHighSubObj2?.actGp * -1
                                      )})`
                                    : formatNumberWithCommas(
                                        keyHighSubObj2?.actGp
                                      )}
                                </li>
                                <li>
                                  Actual GP Percentage :
                                  {keyHighSubObj2?.actGpPer < 0
                                    ? `(${(
                                        keyHighSubObj2?.actGpPer * -1
                                      ).toFixed(2)})`
                                    : keyHighSubObj2?.actGpPer?.toFixed(2)}
                                </li>
                                <li>
                                  Actual Revenue :{" "}
                                  {keyHighSubObj2?.actRevenue < 0
                                    ? `(${formatNumberWithCommas(
                                        keyHighSubObj2?.actRevenue * -1
                                      )})`
                                    : formatNumberWithCommas(
                                        keyHighSubObj2?.actRevenue
                                      )}
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="row gx-3"
                >
                  {cards?.length > 0
                    ? cards?.map((card, index) => (
                        <Draggable
                          key={card?.id}
                          draggableId={card?.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className={`col-xs-12 ${card?.colSize} mb-3 ${
                                card?.childCardData
                                  ? card?.childCardData?.length <= 1
                                    ? "d-none"
                                    : card?.childCardData?.length <= 2
                                    ? "col-md-6"
                                    : "col-md-12"
                                  : ""
                              }`}
                            >
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="details-chart-container">
                                  <div className="chart-header">
                                    <div className="left-content d-flex align-items-center">
                                      <h5 className="semi-bold mb-0 details-heading heading-uppercase me-2">
                                        {card?.heading}
                                      </h5>
                                      <p className="body-text info-text">
                                        All the values are in thousands
                                      </p>
                                    </div>
                                    <div className="card-icons">
                                      {card?.expandAvailable ? (
                                        <OverlayTrigger
                                          overlay={<Tooltip>Expand</Tooltip>}
                                          placement="top"
                                        >
                                          <span
                                            className="expand-icon"
                                            onClick={() =>
                                              handleShowModal(
                                                <ComparisionTable
                                                  tableData={card?.data}
                                                  tableDataHighlights={
                                                    card?.data2
                                                  }
                                                />,
                                                `${card?.heading}`,
                                                card?.contentType === "chart"
                                                  ? "chart"
                                                  : "table"
                                              )
                                            }
                                          ></span>
                                        </OverlayTrigger>
                                      ) : null}

                                      <OverlayTrigger
                                        overlay={<Tooltip>Move Cards</Tooltip>}
                                        placement="top"
                                      >
                                        <span className="move-icon"></span>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div className="chart-body">
                                    {card?.childCardData &&
                                    card?.dataAvailable ? (
                                      <div className="p-3 pb-2 pb-xl-3">
                                        <Row className="gx-10px">
                                          {card?.childCardData?.map(
                                            (child: any, index: any) =>
                                              child?.chartData &&
                                              child?.chartType ===
                                                "gaugeTarget" ? (
                                                <ActualvsPlanned
                                                  heading={child?.heading}
                                                  actualVal={
                                                    child?.chartData?.actualVal
                                                  }
                                                  plannedVal={
                                                    child?.chartData?.plannedVal
                                                  }
                                                  varianceVal={
                                                    child?.chartData
                                                      ?.varianceVal
                                                  }
                                                  loading={loading}
                                                  key={index}
                                                />
                                              ) : child?.chartType === "bar" ? (
                                                <Col
                                                  key={index}
                                                  className={`comparison-cards mb-2 mb-xl-0 ${
                                                    card?.autoAdjustWidth &&
                                                    subUnitBUExpenses?.length ===
                                                      1
                                                      ? "col-12 col-md-12 col-xl-12"
                                                      : card?.autoAdjustWidth &&
                                                        subUnitBUExpenses?.length ===
                                                          2
                                                      ? "col-12 col-md-6 col-xl-6"
                                                      : card?.autoAdjustWidth &&
                                                        subUnitBUExpenses?.length ===
                                                          3
                                                      ? "col-12 col-md-4 col-xl-4"
                                                      : card?.autoAdjustWidth &&
                                                        subUnitBUExpenses?.length ===
                                                          4
                                                      ? "col-12 col-md-6 col-xl-3"
                                                      : "col-12 col-md-6 col-xl-4"
                                                  }`}
                                                >
                                                  <div className="card-details">
                                                    <div className="card-details-header d-flex justify-content-between">
                                                      <p className="regular-text semi-bold">
                                                        {child?.heading}
                                                      </p>
                                                      <span
                                                        className="expand-icon"
                                                        onClick={() =>
                                                          handleShowModal(
                                                            <BarChart
                                                              chartData={
                                                                child?.chartData
                                                              }
                                                              pluginsAvailable={
                                                                child?.pluginsAvailable
                                                              }
                                                              chartOptions={
                                                                child?.chartOptions
                                                              }
                                                              axisText1={
                                                                child?.axisText1
                                                              }
                                                              gridRequired={
                                                                child?.gridRequired
                                                              }
                                                            />,
                                                            `${child?.heading}`,
                                                            "chart"
                                                          )
                                                        }
                                                      ></span>
                                                    </div>
                                                    <div className="card-details-body">
                                                      <BarChart
                                                        chartData={
                                                          child?.chartData
                                                        }
                                                        chartOptions={
                                                          child?.chartOptions
                                                        }
                                                        pluginsAvailable={
                                                          child?.pluginsAvailable
                                                        }
                                                        axisText1={
                                                          child?.axisText1
                                                        }
                                                        gridRequired={
                                                          child?.gridRequired
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </Col>
                                              ) : null
                                          )}
                                        </Row>
                                      </div>
                                    ) : card?.dataAvailable &&
                                      card?.data &&
                                      card?.contentType ? (
                                      <>
                                        {card?.contentType === "table" && (
                                          <div className="table-container pt-0 pb-2">
                                            <ComparisionTable
                                              tableData={card?.data}
                                              tableDataHighlights={card?.data2}
                                            />
                                          </div>
                                        )}
                                        {card?.chartType === "bar" &&
                                          card?.data && (
                                            <BarChart
                                              chartData={card?.data}
                                              chartOptions={card?.chartOptions}
                                              axisText1={card?.axisText1}
                                              gridRequired={card?.gridRequired}
                                            />
                                          )}
                                      </>
                                    ) : (
                                      <div className="no-data-alert">
                                        <p className="text">
                                          No Data Available
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))
                    : null}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      ) : (
        <Row className="h-100vh">
          <Col xs={12}>
            <Alert variant="warning">
              <Alert.Heading>No Data Available!</Alert.Heading>
              {selectedCompanies.length > 0 ? (
                <p>For the selected filters, Please modify the filters</p>
              ) : (
                <p>Please select at least one company</p>
              )}
            </Alert>
          </Col>
        </Row>
      )}
      {/* Generic Modal Component will send the data onClick */}
      <ModalComponent
        show={showModal}
        onHide={handleCloseModal}
        modalTitle={modalTitle}
        bodyContent={modalContent}
        contentType={componentType}
        thousandsText={true}
      />
    </>
  );
};
