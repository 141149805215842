import { useEffect, useState } from "react";
import axios from "axios";
import {
  Badge,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";

//redux imports
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

import { getMonthText, monthsMap } from "./MonthNames";

//Generic components
import { LineChart } from "../../../Details/GenericComponents/Charts/LineChart";
import { ModalComponent } from "../../../Details/GenericComponents/ModalComponent";
interface CardProps {
  id: string;
  isActive: boolean;
  handleClick: (id: string) => void;
}

export const Trir: React.FC<CardProps> = ({ id, isActive, handleClick }) => {
  const token: any = useSelector((state: RootState) => state.auth.token);
  const bussUnit = useSelector((state: RootState) => state.user.bussUnit);
  const buType = useSelector((state: RootState) => state.user.buType);
  const subBUId = useSelector((state: RootState) => state.user.subBUId);
  const subBUFirstId = useSelector(
    (state: RootState) => state.user.subBUFirstId
  );
  const selectedMonthYear = useSelector(
    (state: RootState) => state.user.selectedMonthYear
  );
  let dateType = useSelector((state: RootState) => state.user.selectedDateType);

  //store data
  const [trirCardData, setTRIRCardData] = useState<any>(null);
  const [topPerformer, setTopPerformer] = useState<any>(null);
  const [trirChartData, setTRIRChartData] = useState<any>(null);

  useEffect(() => {
    fetchTRIR();
    setLoading(true);
  }, [dateType, selectedMonthYear, buType, subBUFirstId, subBUId]);

  const fetchTRIR = async () => {
    //UserData();
    const month = selectedMonthYear.month;
    const currentMonth = month + 1;
    const year = selectedMonthYear.year;
    const trirResponse = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/trir`,
        {
          dateFilter: dateType || "Y",
          type: buType || "BU",
          unitId: buType === "SU" ? subBUId : bussUnit,
          month: currentMonth,
          year: year,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        //top perfomer value
        const topPerformer = res.data.data?.TopPerformer;
        const dateDescription = getMonthText(
          topPerformer?.topPerformerDesc % 100
        );
        const getYear = topPerformer?.topPerformerDesc?.slice(0, 4);
        const topPerformerValue = topPerformer?.topPerformerValue;
        setTopPerformer({
          topPerformerDesc: dateDescription + " - " + getYear,
          topPerformerValue: topPerformerValue,
        });

        //format chart data
        const chartData = res.data.data?.Table;
        //get last object for card data
        const getLastObject =
          chartData?.length > 0 ? chartData[chartData?.length - 1] : chartData;
        setTRIRCardData(getLastObject);
        const sortedObjectsWithMonth = chartData?.sort(
          dateType === "M"
            ? (a: any, b: any) => monthsMap[a.month] - monthsMap[b.month]
            : (a: any, b: any) => a.year - b.year
        );
        let chartFilteredLabels: any = [];
        const chartFilteredData: any = [];

        sortedObjectsWithMonth?.forEach((item: any) => {
          let label: any;
          if (item.month !== null) {
            label = item.month;
          } else if (item.quarter !== null) {
            label = item.year + " Q" + item.quarter;
          } else {
            label = item.year;
          }
          if (!chartFilteredLabels?.includes(label)) {
            chartFilteredLabels?.push(label);
          }
          chartFilteredData.push(item.trirRate?.toFixed(2));
        });

        const formattedChartData = {
          labels: chartFilteredLabels,
          datasets: [
            {
              data: chartFilteredData,
              backgroundColor: "#24282b",
              borderColor: "#59656e",
              borderWidth: 1.5,
              pointBorderColor: "#fff",
              pointRadius: 3,
              pointSize: 2,
              fill: false,
            },
          ],
        };
        setTRIRChartData(formattedChartData);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data", error);
      });
  };
  //Flip card
  const [loading, setLoading] = useState(true);
  const [isFlipped, setIsFlipped] = useState(false);

  const isValidDataset = (data: any[]): boolean => {
    const allValues = data?.flatMap((dataset) => dataset.data);
    return allValues?.some((item) => item !== undefined && item !== null);
  };
  const handleFlipClick = () => {
    //setIsFlipped(!isFlipped);
  };
  //modal funcionality
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalTitle, setModalTitle] = useState("");
  const [componentType, setComponentType] = useState("");
  const [modalSize, setModalSize] = useState<string>();
  //show modal
  const handleShowModal = (
    content: React.ReactNode,
    title: string,
    type: string,
    modalSize?: string
  ) => {
    setModalContent(content);
    setModalTitle(title);
    setComponentType(type);
    setShowModal(true);
    setModalSize(modalSize);
  };

  //close modal
  const handleCloseModal = () => setShowModal(false);
  return (
    <Col xs={12} sm={6} md={6} lg={6} xl={3} className="kpi-card-column">
      <div
        className={`card ${isActive ? "active" : ""} ${
          isFlipped ? "flipped" : ""
        }`}
        onClick={() => handleClick("trir")}
      >
        {loading ? (
          <div className="global-loading-card d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="danger" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="front">
              <Row className="g-0">
                <Col xs={11}>
                  <h4 className="heading semi-bold">
                    <Link
                      to={`/dashboard/trir-details`}
                      className="heading-anchor"
                    >
                      Total Recordable Incident Rate
                    </Link>
                  </h4>
                </Col>
                <Col xs={1}>
                  {/* <span
                    className="bulb-icon float-end"
                    onClick={handleFlipClick}
                  ></span> */}
                  <span
                    className="expand-icon"
                    onClick={() =>
                      handleShowModal(
                        <LineChart
                          chartData={trirChartData}
                          chartOptions="dashboardOptions"
                          axisText1="TRIR Rate"
                        />,
                        "Total Recordable Incident Rate",
                        "chart",
                        "small"
                      )
                    }
                  ></span>
                </Col>
              </Row>
              {isValidDataset(trirChartData?.datasets) ? (
                <Row className="g-0">
                  <Col
                    className={`${
                      topPerformer?.topPerformerValue ? "col-6" : "col-3"
                    }`}
                  >
                    <h3 className="revenue">
                      {trirCardData?.recordableCount
                        ? trirCardData?.recordableCount
                        : 0}
                    </h3>
                    <Row className="g-0">
                      <Col
                        className={`${
                          topPerformer?.topPerformerValue ? "col-6" : "col-12"
                        }`}
                      >
                        <p className="current">Current</p>

                        {/* <OverlayTrigger placement="bottom" overlay={tooltip}> */}
                        <Badge
                          bg="danger"
                          className={`${
                            trirCardData?.trirRate < 0.75
                              ? "badge-green"
                              : "badge-red"
                          } semi-bold`}
                        >
                          <span className="material-symbols-outlined">
                            {trirCardData?.trirRate
                              ? trirCardData?.trirRate < 0.75
                                ? "arrow_downward"
                                : "arrow_upward"
                              : ""}
                          </span>
                          {trirCardData?.trirRate
                            ? trirCardData?.trirRate?.toFixed(2)
                            : 0}
                        </Badge>
                        {/* </OverlayTrigger> */}
                        <p className="aspirational">Aspirational</p>
                        <p className="subtitle-percentage">&lt;0.75</p>
                      </Col>
                      <Col
                        className={`${
                          topPerformer?.topPerformerValue ? "col-6" : ""
                        }`}
                      >
                        {topPerformer?.topPerformerValue &&
                        topPerformer?.topPerformerDesc ? (
                          <>
                            <p className="top-performer">More TRIR Rate</p>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {topPerformer?.topPerformerDesc}
                                  <br />
                                  {topPerformer?.topPerformerValue}
                                </Tooltip>
                              }
                              placement="bottom"
                            >
                              <Badge
                                bg="danger"
                                className="badge-blue semi-bold"
                              >
                                {topPerformer?.topPerformerValue}
                              </Badge>
                            </OverlayTrigger>
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className={`${
                      topPerformer?.topPerformerValue ? "col-6" : "col-9"
                    }`}
                  >
                    <div className="chart-container">
                      {trirChartData ? (
                        <LineChart
                          chartData={trirChartData}
                          chartOptions="dashboardOptions"
                          axisText1="TRIR Rate"
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              ) : (
                <p className="body-text">No Data Available</p>
              )}
            </div>
            
          </>
        )}
      </div>
      <ModalComponent
        show={showModal}
        onHide={handleCloseModal}
        modalTitle={modalTitle}
        bodyContent={modalContent}
        contentType={componentType}
        modalSize={modalSize}
      />
    </Col>
  );
};
